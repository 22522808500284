import React, { Component } from 'react'
import FormEten from './Eten_volw.-kind,_vlees-vegi'
import axios from 'axios'
import Modal from '../doubleCheckModal/doubleCheckModal'
import './eventForms.css'

export default class main extends Component {
    componentDidMount = () => {
        this.getFormNumber()
    }

    getFormNumber = () => {
        axios.get(`${process.env.REACT_APP_generalServer}api/events/number/${this.props.match.params.event}`,)

            .then((response) => {
                if (response.data !== null) {
                    this.setState({ formTitle: response.data.title, eventFormNumber: response.data.eventForm }, () => this.renderForm())
                } else { this.renderForm() }
            })
            .catch(function (error) {
                console.log(error)
            })


    }
    renderForm = () => {
        let form
        switch (this.state && this.state.eventFormNumber) {
            case 1:
                form = <FormEten form={1} title={this.state.formTitle}
                    match={this.props.match} resMessage={(res) => this.setState({ modalMessage: res })}
                    history={this.props.history}
                    resHeader={(resHead) => this.setState({ modalHeader: resHead })} />
                break;

            default:
                form = <h1>404</h1>
        }
        this.setState({ form })

    }
    render() {
        return (
            <div className='container'>
                <Modal message={this.state && this.state.modalMessage ? this.state.modalMessage : ''}
                    header={this.state && this.state.modalHeader ? this.state.modalHeader : ''}
                    closeAction={() => this.props.history.push(`/`)}
                    agreeAction={() => this.props.history.push(`/`)}
                    onlyButtonTrue
                    buttonTrue="oke"
                />

                {this.state && this.state.form}
            </div>
        )
    }
}
