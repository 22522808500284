import React, { Component } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'

import axios from 'axios'
import Modal from '../../components/doubleCheckModal/doubleCheckModal'


import '../../components/css/login.css'

// const [showError, setShowError] = useState(false)

export default class login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: {
        email: '',
        password: '',
        KeepLoggedIn: false,
      },
      showError: false,
      modalMessage:false,
      errorResponse:"",
      waiting:false,
    }
  }


  renderModal = () => {
    if (this.state.modalMessage){
    return(
        "Ben je je wachtwoord vergeten? Een E-mail zal worden verzonden om je wachtwoord te resetten"
    )} 
    else{return ""}   
  }


  changeHandler = (e) => {
    const target = e.target.name
    const inputs = { ...this.state.inputs }
    if (target === 'KeepLoggedIn') {
      inputs[target] = !inputs[target]
    } else {
      inputs[target] = e.target.value
    }
    this.setState({ inputs, showError: false })
  }

  authFacebook = () => { }

  login = () => {
    let self = this
    axios
      .post(
        `${process.env.REACT_APP_authServer}api/auth/login/Mylogin`,
        this.state.inputs,
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.tempPassword===0) {
            this.props.parentCallback(true);
            this.props.history.push('/')
          } else {
            this.props.history.push(`/ResetPassword/${response.data.id}`)
          }
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          (error.response.status === 400 || error.response.status === 429)
        ) {
          self.setState(
            { showError: true, errorResponse: error.response.data }
          )
        }
        
        // self.setState({ showError: true, errorResponse: 'Email of wachtwoord in niet juist!'})
        console.log(error)
      })
  }

  resetPassword  = () => {
    let self = this
    axios
      .post(
        `${process.env.REACT_APP_authServer}api/resetLoginPassword/`,
        this.state.inputs,
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({modalMessage:false, waiting:false})
        }
      })
      .catch(function (error) {
        self.setState({ showError: true, errorResponse: 'Email of wachtwoord in niet juist!'})
        console.log(error)
      })
  }


  errorMessage = () => {
    return (
      <Alert
        variant="danger"
        onClose={() => this.setState({ showError: false })}
        dismissible
      >
        <p>{this.state.errorResponse}</p> 
      </Alert>
    )
  }

  logout = () => {
    axios
      .get(`${process.env.REACT_APP_authServer}api/auth/logout`, {
        withCredentials: true,
      })
      .then((response) => {
        this.props.history.push('/login')
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }


  checkEmailExists =() =>{
   
    if (!this.validateEmail(this.state.inputs.email)) {
      this.setState(
        {
          showError: true,
          errorResponse: 'Je hebt geen geldig email adres opgegeven!',
        },
        () => {
          this.errorMessage()
        }
      )

   }
   else this.setState({showError: false,modalMessage:true})
  }

  render() {
    if (this.props.logout === true) { this.logout() }
    return (
      <div>
        <Modal   message= {this.renderModal()} 
                        agreeAction={()=>this.setState({ waiting:true},()=>this.resetPassword())}
                        closeAction={()=>this.setState({modalMessage:false})}
                        // onlyButtonTrue
                        waiting={this.state.waiting}
                        header="Wachtwoord vergeten?"
                        buttonTrue= "Verzenden..."
                        buttonFalse= "Annuleer"/>
        <div className="login-container justify-content-md-center">
          <Form>
            {' '}
            <div className="login-title">
              <b>Login</b>
            </div>
            {this.state.showError ? this.errorMessage() : <div />}
            <Form.Group className="title-margin" controlId="formBasicEmail">
              <Form.Control
                name="email"
                value={this.state.inputs.email}
                type="email"
                placeholder="Enter email"
                onChange={this.changeHandler.bind(this)}
              />
            </Form.Group>
            <Form.Group
              controlId="formBasicPassword"
              style={{ marginBottom: 5 }}
            >
              <Form.Control
                name="password"
                value={this.state.inputs.password}
                type="password"
                placeholder="Password"
                onChange={this.changeHandler.bind(this)}
              />
            </Form.Group>
            <div className="align-right auto">
              <Button size="sm" variant="link" onClick={()=>this.checkEmailExists()}>
                wachtwoord vergeten?
              </Button>
            </div>
            <div className="align-center">
              <Button size="sm" onClick={() => this.login()}>
                login
              </Button>
            </div>
          </Form>
        </div>
        {/* <div className="login-social-media">
          <div className="login-title-small">
            <b>Login</b>
            <p>met je sociale media account</p>
          </div>
          <div className="align-center"> */}
            {/* color stourse: https://brandpalettes.com/all-brands/ */}
            {/* <style type="text/css">
              {`
                .btn-facebook {
                  background-color: rgb(66,103,178);
                  color: white;
                }
                .btn-twitter {
                  background-color: rgb(29,161,242);
                  color: white;
                }
                .btn-google-red {
                  background-color: rgb(219,68,55);
                  color: white;
                }
                .btn-snap-chat {
                  background-color: rgb(255,252,0);
                  color: white;
                }    `}
            </style>
            <Button
              variant="facebook"
              size="sm"
              onClick={() => this.authFacebook()}
            >
              <FontAwesomeIcon className="fa" icon={faFacebook} />
              Facebook
            </Button>{' '}
            <Button variant="twitter" size="sm">
              <FontAwesomeIcon className="fa" icon={faTwitter} />
              Twitter
            </Button>{' '}
            <Button variant="google-red" size="sm">
              <FontAwesomeIcon className="fa" icon={faGooglePlus} />
              Google+
            </Button>
            <hr
              style={{
                width: '80%',
                // color: color,
                // backgroundColor: color,
                height: 7,
              }}
            />
            <div className="login-title-small">
              Nog geen acount?,{' '}
              <text className="register-now"> Registreer nu!</text>
            </div>
            <div className="align-center">
              <Button
                size="sm"
                onClick={() => {
                  this.props.history.push('/register')
                }}
              >
                Registreer
              </Button>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
