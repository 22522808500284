import React, { Component } from 'react'
import axios from 'axios'
import { Button, Card, Row, Col, Modal, Alert, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faTrashAlt,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'

export default class calendar_locations_overview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            resData: [],
            showModal: false,
            modalError: '',
            newLocation: '',

        }
    }

    componentDidMount = () => {
        this.getCalendarLocations()
    }

    deleteLocation = (id) => {

        let self = this
        axios
            .delete(`${process.env.REACT_APP_generalServer}api/locations/delete/${id}`, { withCredentials: true, })
            .then((response) => {

                this.getCalendarLocations()

            })
            .catch(function (error) {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 409
                ) {
                    self.setState({ errorResponse: error.response.data })
                } else {
                    console.log(error)
                }
            })
    }

    editLocation = (data) => {
        this.setState(({ showModal: true, newLocation: data.location, image: data.image, currentLocation: data._id }))

    }

    getCalendarLocations = () => {
        let self = this
        axios
            .get(`${process.env.REACT_APP_generalServer}api/locations/`, { withCredentials: true, })
            .then((response) => {

                if (response.data) {
                    self.setState({ resData: response.data })
                }
            })
            .catch(function (error) {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 409
                ) {
                    self.setState({ errorResponse: error.response.data })
                } else {
                    console.log(error)
                }
            })
    }

    renderLocation = (data, i) => {
        return <Col style={{ marginBottom: "15px" }}
            key={i}
            xs={6} sm={4} md={6} lg={3}>
            <Card style={{ width: '100%', margin: '5px' }}>
                <Card.Img style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover"

                }} variant="top" src={`${process.env.REACT_APP_generalServer}${data.image}`} />
                <Card.Body>
                    <Card.Title>{data.location}</Card.Title>

                    <Button size='sm'
                        variant="primary"
                        onClick={() => { this.editLocation(data) }}>
                        <FontAwesomeIcon className="fa" icon={faEdit} />Edit
                    </Button>
                    <Button size='sm'
                        variant="danger" className="pull-right"
                        onClick={() => { this.deleteLocation(data._id) }}>
                        <FontAwesomeIcon className="fa" icon={faTrashAlt} />Delete
                    </Button>

                </Card.Body>
            </Card>
        </Col>
    }
    newLocationChangeHandler = (e) => {
        this.setState({ newLocation: e.target.value, modalError: '' })

    }



    renderModal = () => {

        return <Modal
            show={this.state.showModal}
            onHide={() => { this.setState({ showModal: false, }) }}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Nieuwe locatie</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="danger" show={this.state.modalError !== ''} onClose={() => this.setState({ modalError: '' })} dismissible>

                    <p>
                        {this.state.modalError}
                    </p>
                </Alert>
                <Form>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="2">
                            Locatie
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text"
                                placeholder="bv: Lokaal"
                                value={this.state.newLocation}
                                onChange={this.newLocationChangeHandler} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            Afbeelding
                        </Form.Label>
                        <Col sm="10">
                            <Form.File
                                id="custom-file-translate-html"
                                label="Voeg je document toe"
                                data-browse="Bestand kiezen"
                                onChange={this.changeFileHandeler.bind(this)}
                                custom />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="modalPreviewImage">
                        <Form.Label column sm="2">

                        </Form.Label>
                        <Col sm="10">
                            {this.ImgPreview()}
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ showModal: false, newLocation: '', currentFigure: '', currentFigureURL: '' })}>
                    Annuleer
                </Button>
                <Button variant="primary" onClick={() => this.checkModal()}>Voeg toe</Button>
            </Modal.Footer>
        </Modal>
    }

    updateNewLocation = () => {
        let self = this
        let fd = new FormData()
        fd.append('location', this.state.newLocation)
        // fd.append(`image`, this.state.currentFigure)
        if (this.state.currentFigure) {
            fd.append(`image`, this.state.currentFigure)
        }
        else {

            fd.append(`image`, this.state.image)
        }



        axios
            .post(`${process.env.REACT_APP_authServer}api/locations/update/${this.state.currentLocation}`, fd, {
                withCredentials: true,
            })
            .then((response) => {
                this.setState({ showModal: false, newLocation: '', currentFigure: '', currentFigureURL: '' }, () => this.getCalendarLocations())
                // this.props.history.push('/admin/overview/leden')
            })
            .catch(function (error) {
                self.setState({ modalError: (error.response && error.response.data.message) })
                console.log(error, error.response && error.response.data.message)
            })




    }

    checkModal = () => {
        if (this.state.newLocation === '') {
            this.setState({ modalError: 'Vul een locatie in!' })
        }
        else if (!this.state.currentFigure && this.state.image === null) {
            this.setState({ modalError: 'Selecteer een afbeelding!' })
        }
        else {
            this.updateNewLocation()
        }


    }

    changeFileHandeler = (e) => {
        var file = e.target.files
        if (e.target.files.length > 0) {
            var url = window.URL.createObjectURL(file[0])
            this.setState({ currentFigure: file[0], currentFigureURL: url, modalError: '' })
        }
        //this wil reset the current value of the form file. 
        // meaning that one image can be added twice ore more after eatch other
        e.target.value = null
    }

    ImgPreview = () => {
        return this.state && (this.state.currentFigureURL || this.state.image) ?
            <div id='imgPreview'>
                <div className='header'>
                    <p>Preview</p>
                    <div onClick={() => { this.setState({ image: null, currentFigure: null, currentFigureURL: null }) }}>
                        <FontAwesomeIcon icon={faTimes} className='fa' color='white' /></div>
                </div>
                <div className='body'>
                    {this.state.currentFigureURL ?
                        <img src={this.state.currentFigureURL} alt='' /> :
                        <img src={`${process.env.REACT_APP_generalServer}${this.state.image}`} alt='' />}
                </div></div> : <></>
    }



    render() {
        const self = this
        return (
            <div className='container'>
                {this.renderModal()}
                {/* <div className='row main-container' > */}
                <Row className="justify-content-center">
                    {this.state.resData ? this.state.resData.map(function (data, i) {
                        return (self.renderLocation(data, i))
                    }) : <div />}
                </Row>
            </div>
        )
    }
}
