import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import Modal from"../../components/doubleCheckModal/doubleCheckModal"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashAlt,faEdit
} from '@fortawesome/free-solid-svg-icons'

import './brieven.css'

export default class brievenList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentDoc:"",
      modalMessage:false,
      radioValue:'1',
      showAll:false,
      authenticated: false,
      headersDutch: [
        'Geüpdatet',
        'Titel',
        
      ],
      data:[]
    }
  }


  componentDidMount= ()=>{
    this.checkAuthorization()
    this.getData()
  }

  checkAuthorization = () => {
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/letters/check-editDocuments`,
        { body: false },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.setState({
          authenticated: response.data.authenticated,
        })
      })
      .catch(function (error) {
        if (error.response.status !== 401) {
          console.log(error)
        }
      })
  }

  deleteDocument = (_id)=>{
    axios.delete(`${process.env.REACT_APP_generalServer}api/letters/delete/${_id}`,
    { withCredentials: true }
    )
    .then((response) => {
      this.getData()

      // if (response.data) {
      //   this.setState({ data: response.data })
      //   return response.data
      // }
    })
    .catch(function (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 409
      ) {
        this.setState({ errorResponse: error.response.data })
      } else {
        console.log(error)
      }
    })

  }

  getData= async ()=>{
    await axios
      .get(
        `${process.env.REACT_APP_generalServer}api/letters?showall=${this.state.showAll}`,
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data) {
          this.setState({ data: response.data })
          return response.data
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          this.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
    // this.checkRequiredHeaders(resData)

  }




  createHeaders = () => {
    // let columnwidths = this.state.columnwidths
    return this.state.headersDutch.map(function (item, i) {
      // let currentwidth = columnwidths ? columnwidths[i] : "100%"

      return <th key={i} className={i===1?"expand":''}>{item}</th>
    })
  }

  createRow = () => {
    let self = this
    // let columnwidths = this.state.columnwidths
    return this.state.data.map(function (item, i) {

 

      return         <tr >
          {/* <div className="customTableLink" key = {`row-${i}`}> */}
         
              <td  >
              <div className={item.publicState===1?"customTableLink":"internal"} key = {`row-${i}`}> 
              {item.updateDate}
              </div></td>
             
              <td  className="expand">
              <div className={item.publicState===1?"customTableLink":"internal"} key = {`row-${i}`}> 
              <a href={`${process.env.REACT_APP_generalServer}${item.document}`} target='_blank' rel='noopener noreferrer'>{item.title}</a>
              </div>
              </td>
              {self.state.authenticated ? (
              <td className="expand3">
                {self.buttonGroup(item._id)}
                </td>):<></>}
               
             </tr>
             

    })
  }

  buttonGroup=(_id)=>{
    return <div style={{ flexDirection: 'row', textAlign: "center" }}>
    <div style={{ display: 'inline-block' }}>
      <Button
        variant="primary"
        onClick={() => {
          this.props.history.push(
            `brieven/edit/${_id}`
          )
        }}
      >
        <FontAwesomeIcon className="fa" icon={faEdit} />
        edit
      </Button></div>{' '}

    <div style={{ display: 'inline-block' }}>
      <Button
        variant="danger"

        onClick={()=>this.setState({modalMessage:true,currentDoc:_id})}
      >
        <FontAwesomeIcon className="fa" icon={faTrashAlt} />
        delete
      </Button>
    </div>
  </div>
  }

  renderModal = () => {

    if (this.state.modalMessage){
    return(
        "ben je zeker dat je deze gebruiker wil verwijderen"
    )} 
    else{return ""}   
  }

  
  

  render() {
    let self = this
    return (
      <div className="container ">
        <Modal   message= {this.renderModal()} 
                        agreeAction={()=>this.setState({modalMessage:false},()=>this.deleteDocument(this.state.currentDoc && this.state.currentDoc))}
                        closeAction={()=>this.setState({modalMessage:false})}
                        // onlyButtonTrue
                        header="Document verwijderen"
                        buttonTrue= "Ja"
                        buttonFalse= "Nee"/>
        <Table responsive >
      <thead>
        <tr>{this.createHeaders()}
        {self.state.authenticated ? (
              <th key={'last-column'} ></th>):<></>}
        {/* <th key={'last-column'} ></th> */}
        </tr>
      </thead>
      <tbody>
       {this.createRow()}</tbody>
    </Table></div>
    )
  }
}
