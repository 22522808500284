
import React, { Component } from 'react'
import { Form, Col, Button, Alert } from 'react-bootstrap'
// import './eventForms.css'
import axios from 'axios'
// import Recaptcha from 'react-google-invisible-recaptcha'
import Recaptcha from '../../components/reCAPTCHA'

const recaptchaRef = React.createRef();
export default class bbq extends Component {
    state = {
        errorMessage: '',
        inputs: {
            memberFirstname: '',
            memberLastname: '',
            firstname: '',
            lastname: '',
            email: '',
            mature: '',
            mature_vegi: '',
            kids: '',
            kids_vegi: '',
            remark: ''
        },
    }
    componentDidMount = () => {
        if (window.grecaptcha){
            window.grecaptcha.reset()
            window.location.reload(false);
          }
        //   this.getReservationData()

        if (this.props.match.params.id) {
            this.getReservationData()
        }
        

    }



    getReservationData = () => {
        console.log(this.props.match.params.id)

        axios.get(`${process.env.REACT_APP_generalServer}api/eventForms/reservation/${this.props.match.params.id}`,
            { withCredentials: true })
            .then((res) => {
                console.log(JSON.parse(res.data.data).data)

                this.setState({ inputs: JSON.parse(res.data.data).data }, () => console.log(this.state.inputs))
            })
            .catch(function (error) {
                console.log(error)
            })


    }

    onResolved = () => {
        this.setState({ messageSent: true }, () => this.register())
    }

    register = () => {
        console.log(this.props.match)
        if (this.props.match.params.id) {
            axios.post(`${process.env.REACT_APP_generalServer}api/eventForms/update/${this.props.match.params.event}/${this.props.form}/${this.props.match.params.id}`,
                { data: this.state.inputs },
                { withCredentials: true })
                .then((res) => {
                    // this.props.history.push('/evenementen/:id', { name: 'John' });
                    this.props.history.goBack()
                    this.props.history.location.reload()

                })
                .catch(function (error) {
                    console.log(error)
                })
        } else {
            axios.post(`${process.env.REACT_APP_generalServer}api/eventForms/register/${this.props.match.params.event}/${this.props.form}`,
                { data: this.state.inputs },
                { withCredentials: true })
                .then((res) => {
                    this.props.resMessage("U ontvangt nog een email ter bevesteging")
                    this.props.resHeader("Wij hebben uw inschrijving goed ontvangen")
                })
                .catch(function (error) {
                    console.log(error)
                })
        }

    }

    checkForm = () => {
        let inputs = this.state.inputs
        let errorMessage = ''
        let { memberFirstname,
            memberLastname,
            firstname,
            lastname,
            email,
            mature,
            mature_vegi,
            kids,
            kids_vegi,
        } = inputs

        //check numbers for Nan
        if (isNaN(parseInt(mature))) {
            mature = 0;

        } else {
            parseInt(mature)
        }

        if (isNaN(parseInt(mature_vegi))) {
            mature_vegi = 0;
        } else {
            parseInt(mature_vegi)
        }
        if (isNaN(parseInt(kids))) {
            kids = 0;
        } else {
            parseInt(kids)
        }
        if (isNaN(parseInt(kids_vegi))) {
            kids_vegi = 0;
        } else {
            parseInt(kids_vegi)
        }
        inputs.mature = mature
        inputs.mature_vegi = mature_vegi
        inputs.kids = kids
        inputs.kids_vegi = kids_vegi

        if (memberFirstname === '') {
            errorMessage = 'U moet de voornaam van een actief lid opgeven'
        }
        else if (memberLastname === '') {
            errorMessage = 'U moet de achternaam van een actief lid opgeven'
        }
        else if (firstname === '') {
            errorMessage = 'U moet uw voornaam (voornaam inschrijver) opgeven'
        }
        else if (lastname === '') {
            errorMessage = 'U moet uw achternaam (achternaam inschrijver) opgeven'
        }
        else if (!this.validateEmail(email)) {
            errorMessage = "U moet een geldig E-mailadres invullen"
          }
        else if ((mature === '' && mature_vegi === '' && kids === '' && kids_vegi === '') ||
            (mature + mature_vegi + kids + kids_vegi <= 0)
        ) {
            errorMessage = 'U moet minstens 1 deelnemer opgeven'
        }
        else {
            this.setState({ inputs }, () =>  this.recaptchaExecute())//recaptchaRef.current.execute())
        }

        if (errorMessage !== '') {
            this.setState({ errorMessage },()=>window.scrollTo(0,0,'smooth'))
        }

    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
      }

    recaptchaExecute=()=>{
        recaptchaRef.current.recaptchaExecute()
      }


    renderAlert = () => {
        return <Alert show={this.state.errorMessage !== ''} variant="danger" onClose={() => this.setState({ errorMessage: '' })} dismissible>
            {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
            <p>
                {this.state.errorMessage}
            </p>
        </Alert>

    }

    changeHandler = (e) => {
        let inputs = this.state && this.state.inputs
        inputs[e.target.name] = e.target.value
        this.setState({ inputs, errorMessage: '' })
    }
    render() {
        console.log(this.state.inputs)
        return (
            <div className='event-container' style={{marginTop:'15px'}}>
                <h4>{`Inschrijvingsformulier ${this.props.title}`}</h4>
                <this.renderAlert />
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridmemberFirstname">
                            <Form.Label>Familie / Kennis van*</Form.Label>
                            <Form.Control type="text" placeholder="Voornaam actief lid"
                                name='memberFirstname' value={this.state.inputs.memberFirstname}
                                onChange={this.changeHandler} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridmemberLastname">
                            <Form.Label>Familie / Kennis van*</Form.Label>
                            <Form.Control type="text" placeholder="Achternaam actief lid"
                                name='memberLastname' value={this.state.inputs.memberLastname}
                                onChange={this.changeHandler} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridFirstname">
                            <Form.Label>Voornaam*</Form.Label>
                            <Form.Control type="text" placeholder="Voornaam inschrijver"
                                name='firstname' value={this.state.inputs.firstname}
                                onChange={this.changeHandler} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridLastname">
                            <Form.Label>Achternaam*</Form.Label>
                            <Form.Control type="text" placeholder="Achternaam inschrijver"
                                name='lastname' value={this.state.inputs.lastname}
                                onChange={this.changeHandler} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridemail">
                            <Form.Label>E-mailadres*</Form.Label>
                            <Form.Control type="text" placeholder="E-mailadres inschrijver"
                                name='email' value={this.state.inputs.email}
                                onChange={this.changeHandler} />
                        </Form.Group>


                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs="12" sm='6' >
                            <Form.Group style={{ padding: "0px" }} as={Col} controlId="formGridmatureparticipants">
                                <Form.Label>Volwassen deelnemers* (27EUR pp)</Form.Label>
                                <Form.Control type="number" placeholder="Aantal volwassenen"
                                    name='mature' value={this.state.inputs.mature}
                                    onChange={this.changeHandler} />
                            </Form.Group>
                            <Form.Group style={{ padding: "0px" }} as={Col} controlId="formGridmatureparticipantsvegi">
                                <Form.Control type="number" placeholder="Aantal vegetariërs"
                                    name='mature_vegi' value={this.state.inputs.mature_vegi}
                                    onChange={this.changeHandler} />
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm='6'>
                            <Form.Group style={{ padding: "0px" }} as={Col} controlId="formGridkidsparticipants">
                                <Form.Label>Kinderen (t/m 12j.)* (18EUR pp)</Form.Label>
                                <Form.Control type="number" placeholder="Aantal kinderen"
                                    name='kids' value={this.state.inputs.kids}
                                    onChange={this.changeHandler} />
                            </Form.Group>
                            <Form.Group style={{ padding: "0px" }} as={Col} controlId="formGridkidsparticipantsvegi">
                                <Form.Control type="number" placeholder="Aantal jonge vegetariërs"
                                    name='kids_vegi' value={this.state.inputs.kids_vegi}
                                    onChange={this.changeHandler} />
                            </Form.Group>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="exampleForm.Controlcomments">
                        <Form.Label>Opmerkingen</Form.Label>
                        <Form.Control as="textarea" rows={3}
                            name='remark' value={this.state.inputs.remark}
                            onChange={this.changeHandler} />

                    </Form.Group>

                </Form>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => { this.checkForm() }}>Inschrijven</Button> <small> * = verplicht veld</small>

                </div>
                <Recaptcha
                    ref={recaptchaRef}
                    onResolved={() => this.onResolved()} 
                    />
                {/* <Recaptcha
                    // ref={ref => this.recaptcha = ref}
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_capcha_sitekey}
                    locale='nl'
                    onResolved={this.onResolved} /> */}

            </div>
        )
    }
}
