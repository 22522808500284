import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Dropdown } from 'react-bootstrap'
import KalenderInfo from '../../components/kalenderInfo copy'
import '../../components/css/kalenderInfo.css'
import moment from 'moment'
import axios from 'axios'
// import Button from 'react-bootstrap/Button'
import Modal from '../../components/doubleCheckModal/doubleCheckModal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import DropdownButton from 'react-bootstrap/DropdownButton'
import FormControl from 'react-bootstrap/FormControl'
// import {
//   ,
//   ,
//   ,
//   FormControl,
//   ,

// } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusSquare } from '@fortawesome/free-solid-svg-icons'

export default class main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      functions: [],
      responseMessage:'',
      groups: [
        { groups: 'Zeehonden', active: true },
        { groups: 'Welpen', active: true },
        { groups: 'Wolven', active: false },
        { groups: 'Aspiranten', active: true },
        { groups: 'Juniors', active: true },
        { groups: 'Verkenners', active: false },
        { groups: 'Seniors', active: true },
      ],

      today: moment(),
    }
    this.resData = {
      welpen: {
        tak: 'Welpen',
        image:
          'https://images1.persgroep.net/rcs/XRc0rjUvA2VM0O7_de1L9pS3JOU/diocontent/101054766/_crop/0/0/1571/1044/_fitwidth/763?appId=2dc96dd3f167e919913d808324cbfeb2&quality=0.8',
        location: { begin: 'lokaal', end: 'lokaal' },
        date: { begin: moment(), end: moment() },
        time: { begin: '14:00u', end: '17:00u' },
        price: 12,
        content: ['dit is dummy text om te conrileren of dit allemaal werkt'],
        benodigdheden: [
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
        ],
      },
      aspiranten: {
        tak: 'aspiranten',
        image:
          'https://images1.persgroep.net/rcs/XRc0rjUvA2VM0O7_de1L9pS3JOU/diocontent/101054766/_crop/0/0/1571/1044/_fitwidth/763?appId=2dc96dd3f167e919913d808324cbfeb2&quality=0.8',
        location: { begin: 'lokaal', end: 'lokaal' },
        date: { begin: moment(), end: moment() },
        time: { begin: '14:00u', end: '17:00u' },
        price: 12,
        content: ['dit is dummy text om te conrileren of dit allemaal werkt'],
        benodigdheden: [
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
        ],
      },
      juniors: {
        tak: 'juniors',
        image:
          'https://images1.persgroep.net/rcs/XRc0rjUvA2VM0O7_de1L9pS3JOU/diocontent/101054766/_crop/0/0/1571/1044/_fitwidth/763?appId=2dc96dd3f167e919913d808324cbfeb2&quality=0.8',
        location: { begin: 'lokaal', end: 'lokaal' },
        date: { begin: moment(), end: moment() },
        time: { begin: '14:00u', end: '17:00u' },
        price: 12,
        content: ['dit is dummy text om te conrileren of dit allemaal werkt'],
        benodigdheden: [
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
        ],
      },
      seniors: {
        tak: 'Seniors',
        image:
          'https://images1.persgroep.net/rcs/XRc0rjUvA2VM0O7_de1L9pS3JOU/diocontent/101054766/_crop/0/0/1571/1044/_fitwidth/763?appId=2dc96dd3f167e919913d808324cbfeb2&quality=0.8',
        location: { begin: 'lokaal', end: 'lokaal' },
        date: { begin: moment(), end: moment() },
        time: { begin: '14:00u', end: '17:00u' },
        price: 12,
        content: ['dit is dummy text om te conrileren of dit allemaal werkt'],
        benodigdheden: [
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
          'mes',
          'das',
          'schaal',
        ],
      },
    }
  }


  componentDidMount = () => {
    // axios
    //   .get(`${process.env.REACT_APP_generalServer}api/pages/calendar-overview`)
    //   .then((response) => {
    //     if (response.data) {
    //       this.setState({
    //         groups: response.data.data.branch_groups,
    //       })
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error)
    //   })
    axios
      .get(`${process.env.REACT_APP_authServer}api/auth/user-roles`, {
        withCredentials: true,
      })
      .then((response) => {

        this.setState({ roles: response.data })
      })
      .catch(function (error) {
        if (error.response.status !==401){
       
        }
    
      })

    axios
      .get(
        `${process.env.REACT_APP_generalServer}api/activities/nextActivity/tak/dummy`
      )
      .then((res) => {
        if (res.data) {
          let response = res.data

          this.setState({ response })
        }
      })
      .catch(function (error) {
        console.log(error)
      })

      axios
      .get(
        `${process.env.REACT_APP_generalServer}api/activities/groups`
      )
      .then((res) => {
        if (res.data) {
          let response = res.data

          this.setState({ groups: response})
        }
      })
      .catch(function (error) {
        console.log(error)
      })

    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/pages/calendar/check-editActiveGroups`,
        { body: false },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            editable: response.data.editable,
          })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  individuelTak = () => {
    let takNamen = this.state.groups
    const props = this.props
    var next_event_data = []

    const response = this.state && this.state.response ? this.state.response : []

    return response ? (

      takNamen.map(function (item, i) {

        next_event_data = response.find(obj => {
          return obj.tak === item.groups
        });

        return item.active ? (
          <Col style={{ marginBottom: "15px" }} key={i} xs={12} sm={6} md={6} lg={3}>
            <KalenderInfo tak={item.groups} data={next_event_data} history={props.history} />
          </Col>
          // <div key={i}  className="col-xs-6 col-sm-6 col-md-3 col-lg-3"  >
          // <KalenderInfo  tak={item.name} history={props.history} />
          // </div>
          // <KalenderInfo key={i} tak={item.name} history={props.history} />
        ) : (
          <div key={i} />
        )
      })
    ) : (<div />)

  }

  updateGroupes = (i) => {
    let groups = { ...this.state.groups }
    groups[i].active = !groups[i].active
    groups = JSON.stringify(groups)
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/pages/updatePage/calendar-overview`,
        { groups: groups },
        { withCredentials: true }
      )
      .then((response) => {
      })
      .catch(function (error) {
        console.log(error)
      })
    this.setState({})
  }

  // renderDropdownGroups = () => {
  //   return (
  //     this.state.groups &&
  //     this.state.groups.map(function (group, i) {
  //       return (
  //         <div>
  //           <Dropdown.ItemText
  //             key={`item-${i}`}
  //             style={{
  //               whiteSpace: 'nowrap',
  //               paddingRight: '5px',
  //               paddingLeft: '5px',
  //               paddingTop: '2px',
  //               paddingBottom: '2px',
  //             }}
  //           >
  //             <input
  //               checked={group.active}
  //               className="margin-checkbox"
  //               name="test"
  //               type="checkbox"
  //               key={`group-${i}`}
  //               onChange={() => {
  //                 this.updateGroupes(i)
  //               }}
  //             />
  //             {group.groups}
  //           </Dropdown.ItemText>
  //         </div>
  //       )
  //     }, this)
  //   )
  // }

  updateRoles = (i) => {
    let groups = { ...this.state.groups }
    groups[i].active = !groups[i].active
    this.setState({})
  }

  renderDropdownRoles = () => {
    return (   <div>{ this.state.groups && this.state.groups.map(function (tak, i) {
      return (
        <div key={`divitem-${i}`}>
          <Dropdown.ItemText
            key={`item-${i}`}
            style={{
              whiteSpace: 'nowrap',
              paddingRight: '5px',
              paddingLeft: '5px',
              paddingTop: '2px',
              paddingBottom: '2px',
            }}
          >
            <input
              checked={tak.active}
              className="margin-checkbox"
              name="test"
              type="checkbox"
              key={`tak-${i}`}
              onChange={() => {
                this.updateRoles(i)
              }}
            />
            {tak.groups}
          </Dropdown.ItemText>
        </div>
      )
    }, this)}
    <div style={{display: 'flex', justifyContent: 'center'}} >
     <Button  onClick={()=> this.showModal()} variant="success" size="sm">Toepassen</Button>
     </div>
    </div>
    )
  }
  showModal= ()=>{
    this.setState({responseMessage:'Weet je zeker dat je de active takken wil wijzigen'})

  }

  updateGroupesdB= ()=>{
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/activities/update-groups`,
        {groups:this.state.groups},
        { withCredentials: true }
      )
      .then((response) => {
        // if (response.data) {
        //   console.log(response.status)
          
        //   // this.setState({
        //   //   editable: response.data.editable,
        //   // })
        // }
        this.setState({responseMessage:''})
      })
      .catch(function (error) {
        console.log(error)
      })
  }
    // this.setState({responseMessage:'Weet je zeker dat je de active takken wil wijzigen'})

  // }

  render() {
    return (
      <div className="main-page">
     <Modal message={this.state.responseMessage ? this.state.responseMessage : ''}
              agreeAction={() => this.updateGroupesdB()}
              closeAction={() => this.setState({responseMessage:''})}
              // onlyButtonTrue
              backdrop={false}
              header="Active takken wijzigen?"
              buttonTrue="ja" 
              buttonFalse="nee"/>
       
        <div className='main-container' >
        {this.state.roles &&
                (this.state.roles.includes('Admin')) ? (
        <Form.Group as={Row} controlId="id_functien">
              
              <Col sm={12}>
                <InputGroup className="mb-3">
                  <FormControl
                    name="function"
                    placeholder="Selecteer takken"
                    value={this.state.functions ? this.state.functions.join(', ') : ''}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    readOnly={true}
                  />
                  <InputGroup.Append>
                    <div>
                      <DropdownButton
                        onClick={() => {
                          this.setState({ showErrorMessage: false })
                        }}
                        title={
                          <span >
                            <FontAwesomeIcon
                              className="fa"
                              icon={faPlusSquare}
                            />
                            Selecteer
                          </span>
                        }
                        id="dropdown-menu-align-right"
                      >
                        {this.renderDropdownRoles()}
                      </DropdownButton>
                    </div>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>):<></>}
            <div className='row' >
          {this.individuelTak()}</div></div>
      </div>
    )
  }
}
