import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import '../../components/css/general.css'

const GenText = (props) => <p>{props.text}</p>

export default class rentingReservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createdBy: [],
      headersDutch: [
        '#',
        'Tak',
        'Datum',
        'Activiteit',
        'Locatie',
        'Tijdstip',
        'Prijs',
        'Speciale Benodigdheden',
        'Aangemaakt door',
        'aanmaakDatum',
      ],
      headers: [
        'tak',
        'date',
        'content',
        'location',
        'time',
        'price',
        'benodigdheden',
        'created_by',
      ],
    }
  }

  async componentDidMount() {
    const self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_authServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        self.setState({ authorized: true })
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })

    let resData = await axios
      .get(`${process.env.REACT_APP_generalServer}api/rentingReservations/all`)
      .then((response) => {
        if (response.data) {
          this.setState({ resData: response.data })
          return response.data
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          this.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
    this.setState({ resData })
    // this.get_CreatedBy(resData)
  }

  getData = () => {
    let self = this
    axios
      .get(`${process.env.REACT_APP_generalServer}api/activities/all`)
      .then((response) => {
        if (response.data) {
          self.setState({ resData: response.data })
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          self.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
  }

  text(text) {
    return text.map(function (text, i) {
      return <GenText text={text} key={i} />
    })
  }

  createRow = () => {
    let self = this

    if (this.state.resData) {
      return this.state.resData.map(function (data, i) {

        let period_begin = new Date(data.period.begin)
        let period_end = new Date(data.period.end)
        let reservationDate = new Date(data.reservation_created)
        return (
          <tr key={i}>
            <td>{i}</td>
            <td>{data.groupName}</td>
            <td>{data.firstname}</td>
            <td>{data.lastname}</td>
            <td>{data.email}</td>
            <td>{data.phonenumber && data.phonenumber}</td>
            <td>
              {period_begin.getDate()}/{period_begin.getMonth() + 1}/
              {period_begin.getFullYear()}
            </td>
            <td>
              {period_end.getDate()}/{period_end.getMonth() + 1}/
              {period_end.getFullYear()}
            </td>
            <td>{data.participants}</td>
            <td>
              {reservationDate.getDate()}/{reservationDate.getMonth() + 1}/
              {reservationDate.getFullYear()}
            </td>
            <td>
              <div className="pull-right">
                <Button
                  variant="primary"
                  onClick={() => {
                    self.props.history.push(
                      `/verhuur/reserveer/${data._id && data._id}`
                    )
                  }}
                >
                  <FontAwesomeIcon className="fa" icon={faEdit} />
                  edit
                </Button>{' '}
                <Button
                  variant="danger"
                  onClick={() => self.deleteReservation(data._id && data._id)}
                >
                  <FontAwesomeIcon className="fa" icon={faTrashAlt} />
                  delete
                </Button>
              </div>
            </td>
          </tr>
        )
      })
    }
  }

  deleteReservation = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_generalServer}api/rentingReservations/${id}`
      )
      .then((response) => {
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  createHeaders = () => {
    return (
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th colSpan="4" className="table-center-horizontal-cell">
            Contactpersoon
          </th>
          <th colSpan="2" className="table-center-horizontal-cell">
            Period
          </th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th>#</th>
          <th>Groep</th>
          <th>Voornaam</th>
          <th>Achternaam</th>
          <th>Email</th>
          <th>Telefoonnummer</th>
          <th>Begin</th>
          <th>Einde</th>
          <th>Aantal deelnemers</th>
          <th>Reservatie geplaast op</th>
          <th></th>
        </tr>
      </thead>
    )
  }

  render() {
    if (!this.state.authorized) {
      return <div />
    } else {
      return (
        <div>
          <Table responsive="sm">
            {this.createHeaders()}
            <tbody>{this.createRow()}</tbody>
          </Table>
        </div>
      )
    }
  }
}
