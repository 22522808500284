import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import axios from 'axios'

export default class LeidersPagina extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: '',
      currentTak: '',
    }
  }

  componentDidMount = () => {
    this.getLeiders()
  }
  getLeiders = () => {
    axios
      .get(`${process.env.REACT_APP_authServer}api/members/leiders`, { withCredentials: true })
      .then((response) => {
        this.setState({ leiders: response.data })
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  takken = () => {
    const self = this
    const defaultTakken = ["Zeehonden","Welpen", 'Wolven', 'Aspiranten', 'Juniors', 'Seniors', 'Verkenners', 'Stam', 'Eenheid']
    let takken = []
    let leiders = this.state.leiders ? this.state.leiders.map(takken => takken.tak)
      .filter((value, index, self) => self.indexOf(value) === index) : []

    for (let i in defaultTakken) {
      if (leiders.includes(defaultTakken[i])) {
        takken.push(defaultTakken[i])
      }
    }

    return takken.map(function (tak, i) {
      return <div key={i} id={tak}>
        <h2>{tak}</h2>
        <Row className="d-flex justify-content-center">{self.tak(tak)}</Row>
      </div>
    })
  }

  touchMove = (index, tak) => {
    this.setState({ currentTak: tak, currentIndex: index })
  }

  addDefaultSrc(ev) {
    ev.target.src = process.env.REACT_APP_generalServer + "figures/default/defaultUserImage.png"
  }

  tak = (tak) => {
    const currentTak = this.state.leiders.filter(leider => leider.tak === tak).sort(function (a, b) {
      let t0
      let t1
      if (a.takleider && a.takleider === true) {
        t0 = 1
      }
      else { t0 = 0 }
      if (b.takleider && b.takleider === true) {
        t1 = 1
      }
      else { t1 = 0 }
      return (t1 - t0)
    })
    return currentTak.map((leider, i) => (




      <Col xs={6} md={4} lg={3} key={i} className="leiders-item">

        <div className={this.state.currentIndex === i && this.state.currentTak === tak ? "touchMove" : "hover-bg"}>

          <div style={{
            display: "inline-block",
            position: "relative",
            width: "100%"
          }}>

            <div style={{ marginTop: "100%" }}>
              <div style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}>


                {" "}
                <span
                  onTouchMove={() => this.touchMove(i, tak)}
                  title="Project Title"
                  data-lightbox-gallery="gallery1"
                  style={{marginBottom:0}}
                
                >
                  <div id="leider" className="hover-text-leiding">

                    <h4>{leider.first_name ? leider.first_name : ''} </h4>
                    <p>{`${leider.voortotem ? leider.voortotem : ''} ${leider.totemnaam ? leider.totemnaam : ''}`} </p>
                    <p>{` ${leider.email ? leider.email : ''}`} </p>
                    <p>{` ${leider.phonenumber ? leider.phonenumber.replace(/(\d{4})(\d{2})(\d{2})(\d{2})/, '$1/$2.$3.$4') : ''}`} </p>
                  </div>



                  <img
                    src={`${process.env.REACT_APP_generalServer}${leider.image}`}
                    onError={this.addDefaultSrc}
                    className="img-responsive"
                    alt=""
                  />{" "}
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </Col>
    ))
  }


  render() {
    return (
      <div className="container">
        <div className="section-title text-center">

          <this.takken />
        </div>
      </div>

    )
  }
}
