import React, { Component } from 'react'
import { Button, Carousel, Col,Row,Form } from "react-bootstrap"
import Modal from '../../components/doubleCheckModal/doubleCheckModal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

const imageHeight = "300px"

export default class accommodatieCarousels extends Component {
    constructor() {
        super();
        this.state = {
            currentFigureList: [],
            currentFigureURL: [],
            modalMessage:"",
            cardContent:{
            title:"",
            info:"",},
            elementFocus:null,
       
        }
      }

    
    card = (card,i)=>{

        return   (
        <div id = "card" className= "card-container">
            {this.createCarousel(JSON.parse(card.Images))}
            <h4 className= "title">{card.Title && card.Title}</h4>

            
            <div style={{marginBottom:"25px"}}>  <p>{card.Content}</p></div>
            {/* <div style={{marginLeft:"auto"}}> */}
             <>{this.props.editMode ? 
             
                <div style={{ display: "flex", flexDirection: "row" ,position: "absolute",right:"15px", bottom:"15px"}}>
                    
                    {i>0?
                             <div style={{width:"30px", margin:"auto", cursor:"pointer"}} onClick={() => {
                                this.swapItems(card._id,i,card.position,-1)
                            }}>
                                 <div style={{ textAlign:"center" }}>
                                 <FontAwesomeIcon
                             
                            size="lg"
                            className="fa-marging-5px"
                            icon={faChevronLeft}
                           
                            /></div> </div>:<></>}
                    {i<this.props.accommodatie.length-1?
                             <div style={{width:"30px", margin:"auto",  cursor:"pointer"}}  onClick={() => {
                                this.swapItems(card._id,i,card.position,1)
                            }}>
                             <div style={{ textAlign:"center" }}><FontAwesomeIcon
                            size="lg"
                            className="fa-left-marging-5px"
                            icon={faChevronRight}
                           
                            /></div> </div>:<></>}
            
            <div style={{width:"30px", margin:"auto",cursor:"pointer"}} onClick={() => {
                                this.removeItem(card._id)}}>
                             <div style={{ textAlign:"center" }}><FontAwesomeIcon
                            size="lg"
                            className="fa-left-marging-5px"
                            icon={faTrashAlt}
                            
                          
                            
                            /></div> </div>
            </div>:<></>}</>
            {/* </div> */}
            </div>)
            
 

    }



    renderCards =  () =>{
        let self = this

        return this.props.accommodatie && this.props.accommodatie.map(function(card,i){
            return <Col style={{ marginBottom:"15px"}}
                        key={i}  
                        xs={12} sm={6} md={6} lg={3}> 
            {self.card(card,i)}</Col>
        })

    }

    renderNewCard =  () =>{
       return (
       <Col style={{ marginBottom:"15px"}}      
            xs={12} sm={6} md={6} lg={3}> 
                < div id = "card" className= "card-container">
                <div style={{height:imageHeight,
                             position:"relative",                            
                             width:"100%"}}>
                <div style={{   position:"absolute", bottom:0}}>
                    <Form.File
                        id="custom-file"
                        label={this.state.currentFigureURL.length>0?"Voeg hier nog een afbeelding toe":"Voeg hier een afbeelding toe"}
                        name="fileName"
                        multiple
                        onChange={this.changeFileHandeler.bind(this)}
                        // onClick={value=""}
                        custom
                    />
                </div>
                                      
                {this.createCarousel(this.state.currentFigureURL,true)}
                </div>
                <div onClick={()=>{this.setState({elementFocus:`title`})}}>
             
                        {this.state.elementFocus===`title`?<Form.Control  
                        autoFocus type="text" 
                        value={this.state.cardContent.title}
                        name = {`title`}
                        onChange={this.changeHandler}
                        onBlur = {()=>this.setState({elementFocus:null})}
                        onKeyDown = { this.onEnterClick.bind(this)}
                        placeholder="Titel" />
                    :<h4 className= "title">{this.state.cardContent.title!==""?this.state.cardContent.title:"Titel"}</h4>}
                    </div>

                    <div onClick={()=>{this.setState({elementFocus:`info`})}}>
             
                        {this.state.elementFocus===`info`?<Form.Control  
                        autoFocus type="text" 
                        value={this.state.cardContent.info}
                        name = {`info`}
                        onChange={this.changeHandler}
                        onBlur = {()=>this.setState({elementFocus:null})}
                        onKeyDown = { this.onEnterClick.bind(this)}
                        placeholder="Voeg hier wat meer informatie toe." />
                    :<p className= "title">{this.state.cardContent.info!==""?this.state.cardContent.info:"Voeg hier wat meer informatie toe."}</p>}
                    </div>
                    <div style={{marginTop:"15px"}}>
                    <Button className="pull-right" 
                            variant="success"  
                            onClick={this.saveNewCard}>Voeg toe</Button>
                    </div>
                    
                </div>
        </Col>)
        

    }

    createCarousel =(images,local=false)=>{


        let sourse = local?"":process.env.REACT_APP_generalServer
        let controls = images.length>1
       
        
        return <Carousel controls={controls}
                         indicators={false}>
            {images.map(function(image,i){
              return  <Carousel.Item  key={`Carousel-${i}`}>
                  {/* <div style={{ display: "inline-block",
                                position: "relative",
                                width: "100%"}}>
                  <div style={{marginTop: "100%"}}> */}
                  <div style={{ 
                    //   position: "absolute",
                    //             top: 0,
                    //             bottom: 0,
                    //             left: 0,
                    //             right: 0,  
                                
                                
                                display: "block",
                        height:imageHeight,
                        // maxHeight:cardHeight,//TODO change this parameter if required (for example if there is to much with space between image and title)
                        // paddingLeft:"100%",
                        maxWidth: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        // height: "auto",
                        textAlign: "center"
                      }}>
                <img
                style={{
                    display: "block",
                        maxHeight:"100%",
                        maxWidth:"100%",
                        width: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center"
                    
                  }}
                    
    
                  src={`${sourse}${image}`}
                  alt="First slide"
                />
                </div>
                {/* </div>
                </div>
             */}
              </Carousel.Item>

            })}
        </Carousel>

    }

    changeFileHandeler = (e) => {
        var file = e.target.files
        if (e.target.files.length>0){
        var url = window.URL.createObjectURL(file[0])
    
        this.state.currentFigureList.push(file[0])
        this.state.currentFigureURL.push(url)
        this.setState({})}
        //this wil reset the current value of the form file. 
        // meaning that one image can be added twice ore more after eatch other
        e.target.value=null     
     }
    
    changeHandler = (e)=>{
        let cardContent= {...this.state.cardContent}
        cardContent[e.target.name] = e.target.value
        this.setState({cardContent})
    }

    changeHandlerTitle = (e)=>{
        this.props.updateAction({name:e.target.name,value:e.target.value})
    }

    onEnterClick = (e) => {
        if (e.keyCode === 13||e.keyCode === 27) {
            this.setState({elementFocus:null})
        }
      }
    saveNewCard =() =>{
        if (this.state.currentFigureList.length>=1 && this.state.cardContent.title!==""){
        this.props.addAction({images:this.state.currentFigureList,
                            title: this.state.cardContent.title,
                            info:this.state.cardContent.info})
        this.setState({ currentFigureList: [],
                        currentFigureURL: [],
                        modalMessage:"",
                        cardContent:{
                        title:"",
                        info:"",},
                        elementFocus:null,})}
        else {this.setState({modalMessage:"Je moet minstens 1 afbeelding en een titel gedefinieerd hebben."})}
    }

    removeItem =(e) =>{
        this.props.removeAction(e)
    }
    
    swapItems = (id,RenderPosition,DBposition,direction)=>{
        let id2 =this.props.accommodatie[RenderPosition+direction]._id
        let DBposition2 =this.props.accommodatie[RenderPosition+direction].position
        this.props.swapAction({ids:[id,id2],position:[DBposition,DBposition2]})
    }

    render() {

        return (
            <div >
                <Modal message= {this.state.modalMessage} 
                        agreeAction={()=>this.setState({modalMessage:""})}
                        closeAction={()=>this.setState({modalMessage:""})}
                        header="Oeps iets te snel!"
                        onlyButtonTrue
                        buttonTrue= "oke"
                        />

               
                <div onClick={()=>{if(this.props.editMode){this.setState({elementFocus:`accommodatie-title`})}}}>
                {this.state.elementFocus===`accommodatie-title`?<Form.Control  
                autoFocus type="text" 
                value={this.props.cardTitle}
                name = {`cardTitle`}
                onChange={this.changeHandlerTitle}
                onBlur={()=>{this.setState({elementFocus:null})}}
                onKeyDown = { this.onEnterClick.bind(this)}
                placeholder="Enter email" />
            :<b>{this.props.cardTitle}</b>}</div>



                {/* <div style={{marginBottom:"15px"}}>
                <b>{this.props.cardTitle}</b>
                </div> */}
                <Row className="justify-content-center">
                
                 {this.renderCards()}

                 {this.props.editMode? this.renderNewCard():<></>}
                 </Row>
                                    
            </div>
        )
    }
}
