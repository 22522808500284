import React, { Component } from 'react'
import Routes from './components/routes'

import Navbar from './components/navbar'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/font-awesome/css/font-awesome.min.css'
// import Recaptcha from './components/reCAPTCHA'

// import { loadReCaptcha } from 'react-google-invisible-recaptcha'
// import { loadReCaptcha } from 'react-google-recaptcha'

 
// import './App.css'
// const recaptchaRef = React.createRef();

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false
    }
  }
  componentDidMount() {
  // console.log(this.props.recaptchaRef)
  }

  handleCallback = (childData) => {
    this.setState({ loggedIn: childData })
  }

  render() {
    return (
      <div>
        <Navbar history={this.props.history} loggedIn={this.state.loggedIn} parentCallback={this.handleCallback} />

        <Routes parentCallback={this.handleCallback} />

        {/* <Recaptcha
                    ref={recaptchaRef}
                    onResolved={() => this.onResolved()} 
                    // onResolved={() => console.log('resolved')} 
                    /> */}
       
      </div>
    )
  }
}
