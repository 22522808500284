import React, { Component } from 'react'
import InputActivity from '../../components/InputActivity'

export default class modifyActivity extends Component {
  render() {
    return (
      <div>
        <InputActivity props={this.props} />
      </div>
    )
  }
}
