import React, { Component } from 'react'
import './legend.css'

export default class calenderLegend extends Component {
    
    render() {
        let self=this
        return (
            <div id="legend" className="legend-container" >
                {this.props.legend &&this.props.legend.length>0&& this.props.legend.map(function(icon,i){
                return    <div key={i} className="row">
                    <div  className={` icon-${icon.type}`} style={{width:self.props.iconWidth,
                                 height: self.props.iconHeight  }}></div>
                    <p>{icon.text}</p>
                    </div>

                })}
            </div>
        )
    }
}
