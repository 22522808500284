import React, { Component } from 'react'
import axios from 'axios'
import { Table, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import UnAuthorized from '../../Pages/UnAuthorized'


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export default class eventRegistrations extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = () => {
        const self = this
        //check if user has admin rights
        axios
            .get(`${process.env.REACT_APP_generalServer}api/members/check-roles`, {
                withCredentials: true,
            })
            .then((response) => {
                self.setState({ authorized: true })
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    self.setState({ authorized: false })
                }
                console.log(error)
            })
        this.getReservations()
    }
    getReservations = async () => {
        axios.get(`${process.env.REACT_APP_generalServer}api/eventForms/all-reservations/${this.props.match.params.id}`,
            { withCredentials: true })
            .then((response) => {
                if (response.data.length === 0) {
                    this.setState({ noData: true })
                }
                else {


                    let headers = this.getHeaders(response.data && response.data[0])
                    

                    let data = response.data
                    for (let i = 0; i < response.data.length; i++) {
                        data[i].data = JSON.parse(data[i].data)

                    }
               
            

                    this.setState({ reservations: data, headers })
                }
            })
            .catch(function (error) {
                console.log(error)
            })


    }

    getHeaders = (data) => {
        const headers = Object.keys(JSON.parse(data.data).data)
        // const headers = Object.keys(data.data)
        return headers
    }


    renderHeaders = () => {
        let current_item
        return (<thead>

            <tr>
                <th>#</th>
                {this.state && this.state.headers && this.state.headers.map(function (item, i) {
                    if (item === 'kids') { current_item = 'Kinderen' }
                    else if (item === 'mature') { current_item = 'Volw.' }
                    else if (item === 'mature_vegi') { current_item = 'Volw. vegi.' }
                    else if (item === 'mature_vegi') { current_item = 'Volw. vegi.' }
                    else if (item === 'memberLastname') { current_item = 'Achternaam lid' }
                    else if (item === 'memberFirstname') { current_item = 'Voornaam lid' }
                    else if (item === 'lastname') { current_item = 'Achternaam' }
                    else if (item === 'fisrtname') { current_item = 'Voornaam' }
                    else if (item === 'remark') { current_item = 'Opmerkingen' }
                    else { current_item = capitalize(item) }
                    return <th key={i}>{current_item}</th>
                })}
            </tr>
        </thead>)
    }

    deleteReservation = (id) => {
        axios.delete(`${process.env.REACT_APP_generalServer}api/eventForms/${id}`,
            { withCredentials: true })
            .then((response) => {
                this.getReservations()
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    renderRow = (reservation, i) => {
       
        const data = reservation.data.data
        return <tr key={i}>
            <td key={`row-${i}`}>{i + 1}</td>
            {this.state && this.state.headers && this.state.headers.map(function (item, k) {
            
                return <td key={`row-${i}-item-${k}`}>{data[item]}</td>
            })}
            <td key={`row-${i}-buttons`}>{this.buttons(reservation._id)}</td>
        </tr>
    }

    renderRows = () => {
        let self = this
        return <tbody>{this.state && this.state.reservations && this.state.reservations.map(function (reservation, i) {
            return self.renderRow(reservation, i)
        })}
        </tbody>

    }
    buttons = (id) => {
        return <div style={{ display: 'flex', flexDirection: "row" }}>
            <Button style={{ display: 'flex', flexDirection: "row" }} size='sm'
                onClick={() => {
                    this.props.history.push(
                        `/evenementen/${this.props.match.params.id}/${id}`
                    )
                }}> <FontAwesomeIcon className="fa" icon={faEdit} />Edit</Button>{' '}
            <Button style={{ display: 'flex', flexDirection: "row" }}
                variant='danger' size='sm' onClick={() => this.deleteReservation(id)}><FontAwesomeIcon className="fa" icon={faTrashAlt} /> Delete</Button>
        </div>

    }
    render() {
        if (this.state && !this.state.authorized) {
            return <UnAuthorized />
        } else {
            return (
                <div className='container'>
                    {this.state && this.state.noData === true ?
                        <div style={{ marginTop: '30px', textAlign: 'center', fontSize: 40 }}>Er zijn momenteel nog geen reservaties</div> :
                        <Table striped bordered hover size="sm">
                            {this.renderHeaders()}
                            {this.renderRows()}
                        </Table>
                    }
                </div>
            )
        }
    }
}