import React, { Component } from 'react'
// import moment from 'moment'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import InputGroup from 'react-bootstrap/InputGroup'
// import FormControl from 'react-bootstrap/FormControl'
// import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faEdit } from '@fortawesome/free-solid-svg-icons'
import UnAuthorized from '../../Pages/UnAuthorized'
// import DatePicker from '../../components/calendar/datePicker'

import './brieven.css'

export default class brievenUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
                inputs: {
                  title:'' ,
                  public_contactdetails: false,
                  image:'',
                },
        authComplete:false,
          roles: [],
          showErrorMessage:false,
          filename:'',
          switchLabel: 'Niet zichtbaar voor iedereen',
      

        }
      }



    componentDidMount = () => {
        this.checkAuthentication()
        if (this.props.match.params.type ==='edit') {
          this.getData()
        }
      }
    checkAuthentication = () => {
        const self = this
        //check if user has admin rights
        axios
          .get(`${process.env.REACT_APP_authServer}api/members/check-roles`, {
            withCredentials: true,
          })
          .then((response) => {
            self.setState({ authorized: true }, () => self.getData())
          })
          .catch(function (error) {
            if (error.response&&error.response.status === 401) {
              self.setState({ authorized: false })
            }
            console.log(error)
          })
      }


      getData =()=>{
        axios
          .get(`${process.env.REACT_APP_authServer}api/letters/${this.props.match.params.id }`, {
            withCredentials: true,
          })
          .then((response) => {
            let inputs =  {...this.state.inputs}
            inputs.title = response.data.title
            inputs.public_contactdetails = response.data.publicState===1?true:false
            inputs.image =  response.data.document

              
            this.setState({inputs})
            // console.log(response)
            // this.setState({ authorized: true }, () => this.getData())
          })
          .catch(function (error) {
            if (error.response&&error.response.status === 401) {
              this.setState({ authorized: false })
            }
            console.log(error)
          })
      }
      
      
    submitHandler = () => {
        let errorMessage = ''
        let showErrorMessage = false
        if (this.state.inputs.title === '') {
          errorMessage = 'je moet een titel invullen'
          showErrorMessage = true
        }else if (!this.state.currentFigure &&this.state.inputs.image==='') {
            errorMessage='Je moet een bestand selecteren'
            showErrorMessage = true
          }  
        else if (this.state.currentFigure && !this.state.currentFigure.name.endsWith(".pdf") ) {
          errorMessage='Je hegt een foutive bestandtype geslecteerd. Enkel .pdf is toegetsaan'
          showErrorMessage = true
        } 
        this.setState({ errorMessage, showErrorMessage }, () => {
          if (!showErrorMessage) {
            this.addFileToDb()
          }
        })
    }

    addFileToDb = () => {
        const  self = this
    
        let fd = new FormData()
        fd.append('title', this.state.inputs.title)
        fd.append('publicState', this.state.inputs.public_contactdetails)
        // if (this.state.currentFigure) {
        //   fd.append('image', this.state.currentFigure)
        // }
        // // fd.append('image', this.state.currentFigure)

        if (this.state.currentFigure) {
          fd.append(`image`, this.state.currentFigure)
        }
        else {
    
          fd.append(`image`, this.state.inputs.image)
        }
    
    
        if (this.props.match.params.type==='edit') {
          axios
            .post(`${process.env.REACT_APP_authServer}api/letters/update/${this.props.match.params.id}`, fd, {
              withCredentials: true,
            })
            .then((response) => {
              console.log('alallala')
              this.props.history.push('/brieven')
            })
            .catch(function (error) {
              console.log(error)
              console.log(error.response.status)
              let errorMessage = self.state.errorMessage
              let showErrorMessage = false
              if (error.response.status === 409) {
                showErrorMessage = true
                errorMessage = 'Er bestaat al een lid met dit email adres. wijzig het email adres en probeer opnieuw!'
                  }
                  self.setState({ errorMessage, showErrorMessage })
            })
    
    
        }
        else {
          axios
            .post(`${process.env.REACT_APP_authServer}api/letters/add`, fd, {
              withCredentials: true,
              Headers:{"Consent-Type":'multipart/form-data'}
              
            })
            .then((response) => {
              // console.log(response)
              this.props.history.push('/brieven')
            })
            .catch(function (error) {
              console.log(error)
              console.log(error.response)
              let errorMessage = self.state.errorMessage
              let showErrorMessage = false
              if (error.response.status === 409) {
                showErrorMessage = true
                errorMessage = 'Er bestaat al een lid met dit email adres. wijzig het email adres en probeer opnieuw!'
                  }
              else if (error.response.status === 400) {
                showErrorMessage = true
                errorMessage = error.response.data
                  }
                  self.setState({ errorMessage, showErrorMessage })
            })
          }
    
        // }
        // else {
    
        //   axios
        //     .post(`${process.env.REACT_APP_authServer}api/members/add`, fd, {
        //       withCredentials: true,
        //     })
        //     .then((response) => {
        //       this.props.history.push('/admin/overview/leden')
        //     })
        //     .catch(function (error) {
             
        //       console.log(error)
        //       console.log(error.response.status)
        //       let errorMessage = self.state.errorMessage
        //       let showErrorMessage = false
        //       if (error.response.status === 409) {
        //         showErrorMessage = true
        //         errorMessage = 'Er bestaat al een lid met dit email adres. wijzig het email adres en probeer opnieuw!'
        //           }
        //           self.setState({ errorMessage, showErrorMessage })
        //     })
        // }
      }

changeHandeler = (e) => {
    const target = e.target.name
    const inputs = { ...this.state.inputs }
    inputs[target] = e.target.value

    console.log(inputs)
    this.setState({ inputs, showErrorMessage: false },()=>console.log(this.state.inputs.title))
}

changePrivateSetting = () => {
  let inputs = this.state.inputs
  let switchLabel = 'Niet zichtbaar voor iedereen'
  inputs.public_contactdetails = !inputs.public_contactdetails

  if (inputs.public_contactdetails === true) {
    switchLabel = 'Publiek zichtbaar'
  }
  this.setState({ inputs, switchLabel })

}

// validateFileType = ()=>{

// }

showErrorMessage = () => {
    return this.state.showErrorMessage ? (
      <Alert variant="danger">
        <p>{this.state.errorMessage}</p>
      </Alert>
    ) : (
      <div />
    )
  }

changeFileHandeler = (e) => {
    var file = e.target.files
    console.log(e.target.files.length > 0,file[0])
    if (e.target.files.length > 0) {
        if (!file[0].name.endsWith(".pdf")){
            
              
              this.setState({ errorMessage:'Je hegt een foutive bestandtype geslecteerd. Enkel .pdf is toegetsaan'
                , showErrorMessage:true })
        

        }
      var url = window.URL.createObjectURL(file[0])
      this.setState({ currentFigure: file[0], currentFigureURL: url,showErrorMessage:false, filename: file[0].name })
    }
    //this wil reset the current value of the form file. 
    // meaning that one image can be added twice ore more after eatch other
    e.target.value = null
  }

render() {

    if (typeof this.state.authorized === 'undefined') {
      return <div />
    } else if (!this.state.authorized) {
      return <UnAuthorized />
    } else {
      return (
        <div className='container'>
          <Form>
            <Form.Group as={Row} controlId="id_error">
              <Form.Label column sm={2}></Form.Label>
              <Col sm={10}>{this.showErrorMessage()}</Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_title">
              <Form.Label column sm={2}>
                Titel*
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="title"
                  value={this.state.inputs.title}
                  type="text"
                  placeholder="Titel"
                  onChange={this.changeHandeler.bind(this)}
                />
              </Col>
            </Form.Group>

       

            <Form.Group as={Row}
              className="title-margin selectableInput"
              controlId="formDetails"
              // style={{cursor:"pointer"}}
               >
              <Form.Label column sm={2}>Bestand*</Form.Label>
              <Col sm={10}  s>
                <Form.File
              
                
                  id="custom-file"
                  label={this.state.filename==='' ?((this.props.match.params.type ==='new')||(this.state.inputs.image&&this.state.inputs.image==='')?'Voeg PDF Bestand toe':this.state.inputs.image.slice(8)):this.state.filename}
                  name="fileName"
                  accept=".pdf"
                //   value={12}

                  data-browse="Bestand kiezen"
                  onChange={this.changeFileHandeler.bind(this)}
                  custom
                />

              </Col>

            </Form.Group>

          
            <Form.Group as={Row} controlId="id_switchpublic">
              <Form.Label column sm={2}>
                Weergave
              </Form.Label>
              <Col className='align-self-center' sm={10}>
                <Form.Check
                  // style={{cursor:"pointer"}}
                  checked={this.state.inputs.public_contactdetails}
                  type="switch"
                  id="custom-switch"
                  onChange={this.changePrivateSetting}
                  label={this.state.switchLabel}
                />
              </Col>

            </Form.Group> 

            <Form.Group as={Row} controlId="id_button">
              <Form.Label column sm={2}></Form.Label>
              <Col >



                <Button style={{ whiteSpace: 'nowrap' }} 
                onClick={this.submitHandler.bind(this)}
                >
                  <FontAwesomeIcon className="fa" icon={this.props.match.params.type==='edit'?faEdit:faPlusSquare} />
                  {this.props.match.params.type==='edit'? "Bijwerken" : "Toevoegen"}
                </Button>
              </Col>

            </Form.Group>

          </Form>
        </div>
      )
    }
  }
}