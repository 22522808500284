import React, { Component } from 'react'
import { Row, Button } from 'react-bootstrap'

export class newMeber extends Component {
  render() {
    return (
      // <div id="FosShop" >
      <div id="newMembers" >
        <div className="container">
          <Row className="row">
            <div className="col-xs-12 col-lg-6 text-center"> <img src="img/newMember2.png" className="img-responsive" alt="" /> </div>
            <div className="col-xs-12 col-lg-6">
              <div>{this.props.data ?

                <div className="about-text">
                  <h2>lid worden?</h2>
                  <p>{this.props.data ? this.props.data.paragraph1 : 'loading...'}</p>
                  <p>{this.props.data ? this.props.data.paragraph2 : 'loading...'}</p>
                  <Button size="lg"
                    style={this.props.red_button_style}
                    onClick={() => this.props.history.push('/lidWorden')}> Registreer</Button>
                </div>
                : <div />}</div>
            </div>
          </Row>
        </div>
      </div>
    )
  }
}

export default newMeber
