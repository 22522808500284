import React, { Component } from 'react'
import { Table, Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus, faEdit, faInfoCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import axios from "axios"

export default class events extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount = () => {
    this.getEvents()
    let self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_generalServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        this.setState({ authorized: true })
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })




  }


  getEvents = () => {
    axios.get(`${process.env.REACT_APP_generalServer}api/events/`,
      { withCredentials: true })
      .then((response) => {
        this.setState({ events: response.data })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  deleteEvents = (id) => {
    axios.delete(`${process.env.REACT_APP_generalServer}api/events/delete/${id}`,
      { withCredentials: true })
      .then((response) => {
        this.getEvents()
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  // dateString2Date = (date) => {
  //     var b = date.split(/\D+/)
  //     return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  //   }
  dateString2Date = (date) => {
    return new Date(date * 1000)
  }


  formatDate = (date) => {
    const zeroCheck = (number) => {
      //if the number is less than 9, a zero is added in front
      number = number > 9 ? number : `0${number}`

      return number
    }

    let d = this.dateString2Date(date)

    return `${zeroCheck(d.getDate())}/${zeroCheck(d.getMonth() + 1)}/${d.getFullYear()}  ${zeroCheck(d.getHours())}:${zeroCheck(d.getMinutes())}:${zeroCheck(d.getSeconds())}u`
  }


  renderheader = () => {
    return <thead>
      <tr>
        <th>#</th>
        <th>Titel</th>
        <th>Datum</th>
        <th>Tijd</th>
        <th>Locatie</th>
        <th>Publiek zichtbaar</th>
      </tr>
    </thead>
  }

  renderRow = () => {
    let self = this
    return this.state.events && this.state.events.map(function (event, i) {
      const date = event.beginDate ? self.formatDate(event.beginDate) : "null"

      let { title, beginLocation } = event
      return <tr key={i}>
        <td>{i}</td>
        <td>{title}</td>
        <td>{date}</td>
        <td>{date}</td>
        <td>{beginLocation}</td>
        <td>{event.public.toString()}</td>

        <td>{self.renderButtons(event._id)}</td>
      </tr>
    }
    )
  }



  renderButtons = (id) => {
    return <div style={{ float: "right" }}><Button
      size="sm"
      variant="primary"
      onClick={() => {
        this.props.history.push(
          `/admin/evenement/${id}/`
        )
      }}
    >
      <FontAwesomeIcon className="fa" icon={faEdit} />
      edit
    </Button>{' '}


      <Button
        size="sm"
        variant="success"
        onClick={() => {
          this.props.history.push(
            `/admin/evenement-registraties/${id}/`
          )
        }}
      >
        <FontAwesomeIcon className="fa" icon={faInfoCircle} />
        Inschrijvingen
      </Button>{' '}
      <Button
        size="sm"
        variant="danger"
        onClick={() => this.deleteEvents(id)}
      >
        <FontAwesomeIcon className="fa" icon={faTrashAlt} />
        Delete
      </Button>

    </div>
  }
  render() {
    if (!this.state.authorized) {
      return <div />
    } else {

      return (
        <div className="container">
          <Button className="pull-right"
            variant='success'
            style={{ margin: '5px' }}
            onClick={() => { this.props.history.push('/admin/evenementen/nieuw') }}>
            <FontAwesomeIcon className="fa" icon={faCalendarPlus} />
            Evenement toevoegen</Button>
          <Table striped bordered hover size="sm">
            {this.renderheader()}
            <tbody>
              {this.renderRow()}
            </tbody>
          </Table>
        </div>
      )
    }
  }
}
