import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import '../../components/css/Photo-gallery.css'

export default class albumContent extends Component {

    componentDidMount = () => {
        this.getAlbum()
    }

    getAlbum = () => {
        axios.get(`${process.env.REACT_APP_generalServer}api/photoAlbums/${this.props.match.params.id}`,
            { withCredentials: true })
            .then((response) => {
                this.setState({ album: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    changeFileHandeler = (e) => {


        if (e.target.files.length > 0) {
            let fd = new FormData()
            for (const [, value] of Object.entries(e.target.files)) {
                fd.append('images', value)

            }


            this.addImages2Album(fd)
        }

        //this wil reset the current value of the form file. 
        // meaning that one image can be added twice ore more after eatch other
        e.target.value = null
    }

    addImages2Album = (fd) => {




        axios.post(
            `${process.env.REACT_APP_generalServer}api/photoAlbums/add-albumImages/${this.props.match.params.album}/${this.props.match.params.id}`,
            fd,
            { withCredentials: true }
        )
            .then((response) => {

                this.getAlbum()
                //   this.setState({showModal:false},()=>this.getAlbums())
                // self.props.history.push('/admin/overview/evenementen')
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    deleteImage = (id) => {

        axios.delete(`${process.env.REACT_APP_generalServer}api/photoAlbums/singleImage/${this.props.match.params.id}/${id}`,
            { withCredentials: true })
            .then((response) => {

                this.getAlbum()
                // this.setState({ albums: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })

    }
    getImageData = ({ target: img }) => {

        let album = this.state.album
        let shape = 'square'
        let AR = Math.round(img.naturalWidth / img.naturalHeight * 100) / 100
        if (AR <= 0.75) {
            shape = 'portret'
        }
        else if (AR >= 4) {
            shape = 'panorama'
        }
        else if (AR >= 1.25) {
            shape = "landscape"
        }

        album.images[img.name].shape = shape
        this.setState({ album })
    }

    renderimages = () => {
        const self = this
        if (this.state && this.state.album.images) {
            return this.state.album.images.map(function (image, i) {
                return <div
                    className={image.shape ? image.shape : 'square'}
                    style={{ position: 'relative', backgroundColor: 'pink' }}
                >
                    <img className='alligator-turtle' style={{ maxWidth: '100%', cursor: 'pointer', opacity: 0.5 }}
                        name={i}
                        src={`${process.env.REACT_APP_generalServer}${image.image}`}
                        // onLoad={self.getImageData}
                        alt='' />
                    {/* dimentions {image.shape} {self.state&&self.state.dimentions&&self.state.dimentions.height} */}
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // opacity:0.4,
                            right: '0px',
                            top: '0px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            margin: 'auto',
                            width: '40px',
                            height: '40px'
                        }}
                    >
                        <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => self.deleteImage(image._id)} size='lg' icon={faTrashAlt} /></div>
                </div>

            })
        }
        else return <></>

    }

    render() {
        return (
            <div className='container'>
                <Row>
                    <label className="btn btn-success">
                        Foto's toevoegen <input type="file" multiple hidden onChange={this.changeFileHandeler.bind(this)} />
                    </label>
                </Row>
                <div className='grid-container'>
                    <this.renderimages />
                </div>

            </div>

        )
    }
}
