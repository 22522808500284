import React, { Component } from "react";
import { Row,Col} from 'react-bootstrap'
export class Team extends Component {
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
        
        {this.props.data
              ?<div><Col md={{span:8,offset:2}} className=" section-title">
          
              <h2>{this.props.data.title}</h2>
              <p>
              {this.props.data.paragraph}
              </p>
            </Col>
            <Row id="row">
            {this.props.data.images.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-xs-6 col-sm-6 col-md-3 col-lg-3 team text-center">
                    <div className="thumbnail"
                    style={{cursor:"pointer"}}
                    onClick={()=>this.props.history.push({pathname:'/leiders/',id:'#welpen'})}
                    // href='/leiders/#welpen'
                    >
                      {" "}
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
             }
          </Row></div>: "loading"}
        </div>
      </div>
    );
  }
}

export default Team;
