import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";


// import ScrollToTop from "../ScrollToTop"

import mainPage from '../Pages/mainPage'
import verhuur from '../Pages/verhuur/main'
import verhuurVraag from '../Pages/verhuur/vraag'
import verhuurReserveerIU from '../Pages/verhuur/reserveerIU'
// import verhuurReservaties from '../Pages/verhuur/reservaties'
import eventsOverview from '../Pages/eventsOverview/eventsOverview'

import kalender from '../Pages/kalender/main'
import individuelePlanning from '../Pages/kalender/individuelePlanning'
import addActivity from '../Pages/kalender/addActivity'
import editActivity from '../Pages/kalender/editActivity'

import Login from '../Pages/login/login'
// import register from '../Pages/login/register'
// import verifyUser from '../Pages/login/verifyUser'
import resetPassword from '../Pages/login/resetPassword'
import resetPassword_viaLink from '../Pages/login/resetPassword_viaLink'

import adminNav from '../Pages/admin/nav'
import adminUsers from '../Pages/admin/users'
import adminLeden from '../Pages/admin/members'
import adminPlanningen from '../Pages/admin/planningen'
import adminAddMember from '../Pages/admin/addMember'
import adminQuestions from '../Pages/admin/questions'
import adminEvnts from '../Pages/admin/events'
import adminIndividualQuestion from '../Pages/admin/individualQuestion'
import adminRentingReservations from '../Pages/admin/rentingReservations'
import leidersPagina from '../Pages/LeidersPagina'
import addEvent from './events/event'
import newMembers from '../Pages/admin/newMembers'
import eventRegistrations from '../Pages/admin/eventRegistrations'
import photoAlbumOverview from '../Pages/admin/photoAlbumOverview'
import albumContent from '../Pages/admin/albumContent'
import locationOverview from '../Pages/admin/calendar_locations_overview'

// import mainFosShop from '../Pages/fosshop/mainFosShop'
// import shop from '../Pages/fosshop/shop'
// import bestellingen from '../Pages/fosshop/bestellingen'
// import stok from '../Pages/fosshop/stok'
// import shoppingCart from '../Pages/fosshop/shoppingCart'
// import bevestigen from '../Pages/fosshop/bevesteging'
// import orderDetails from '../Pages/fosshop/orderDetails'
import openingHours from '../Pages/fosshop/openingHours'

// import testpage from '../Pages/testpage'
import newMember from '../Pages/newMember/newMember'

import eventsRegister from '../components/eventForms/main'
import policyPage from '../Pages/Privacy_Policy/main'
import cookiePolicyPage from '../Pages/cookiePolicy/main'


import brievenUpload from '../Pages/brieven/brievenUpload'
import brievenList from '../Pages/brieven/brievenList'



export default class routes extends Component {

  handleCallback = (childData) => {

    this.props.parentCallback(childData);
  }
  render() {

    return (
      <div>
        <Router>
        {/* <ScrollToTop>  */}
          <Route path="/" exact component={mainPage} />   {/*oke  */}
          <Route path="/verhuur" exact component={verhuur} /> {/*oke  */}
          <Route path="/verhuur/vraag" component={verhuurVraag} /> {/*oke  */}
          <Route path="/verhuur/reserveer/:id" component={verhuurReserveerIU} />
          {/* <Route path="/verhuur/reservaties" component={verhuurReservaties} /> */}
          <Route path="/kalender" exact component={kalender} /> {/*oke  */}
          <Route path="/evenementen" exact component={eventsOverview} /> {/*oke  */}
          <Route path="/fotos/:album/:id" exact component={albumContent} />
          <Route
            path="/kalender/planning/:tak"
            exact
            component={individuelePlanning}
          />{/*oke  */}
          <Route path="/kalender/planning/add/:tak" component={addActivity} /> {/*oke  */}
          <Route
            path="/kalender/planning/edit/:tak/:id"
            component={editActivity}
          />{/*oke  */}
          <Route path="/login" exact render={(props) => <Login {...props} parentCallback={this.handleCallback} />} /> {/*oke  */}
          <Route path="/LidWorden" exact component={newMember} />{/*oke  */}
          {/* <Route path="/register" exact component={register} />oke  */}
          {/* <Route path="/verify" exact component={verifyUser} />oke  */}
          <Route path="/resetPassword/:id" exact component={resetPassword} />{/* oke  */}
          <Route path="/password-reset/:id/:token" exact component={resetPassword_viaLink} />
          <Route path="/admin/overview/:tab" component={adminNav} />{/*oke  */}
          <Route path="/admin/lidWorden/edit/:id" component={newMember} />{/*oke  */}
          <Route path="/admin/overview/users" exact component={adminUsers} />{/*oke  */}
          <Route path="/admin/overview/leden" exact component={adminLeden} />{/*oke  */}
          <Route path="/admin/overview/vragen" exact component={adminQuestions} />{/*oke  */}
          <Route path="/admin/overview/evenementen" exact component={adminEvnts} /> {/*oke  */}
          <Route path="/admin/vraag/:id" exact component={adminIndividualQuestion} />{/*oke  */}
          <Route path="/admin/evenementen/nieuw" component={addEvent} /> {/* oke  */}
          <Route path="/admin/overview/foto-albums" component={photoAlbumOverview} /> {/*oke  */}
          <Route path="/admin/evenement/:id" component={addEvent} /> {/*oke  */}
          <Route path="/admin/evenement-registraties/:id" component={eventRegistrations} /> {/*oke  */}
          <Route path="/admin/overview/nieuweLeden" component={newMembers} />  {/*oke  */}
          <Route path="/admin/overview/calendar-locations" component={locationOverview} />  {/*oke  */}
          <Route path="/leiders" component={leidersPagina} />

          {/* <Route path="/admin/nieuwLid/:id" component={addEvent} /> */}

          <Route
            path="/admin/overview/verhuurReservaties"
            exact
            component={adminRentingReservations}
          /> {/*oke  */}
          <Route
            path="/admin/overview/planningen"
            exact
            component={adminPlanningen}
          /> {/*oke  */}
          <Route
            path="/admin/users/edit/:id"
            exact
            component={adminAddMember}
          />
          <Route path="/admin/addMembers" exact component={adminAddMember} /> {/*oke  */}



          {/* <Route path="/fos-shop/shop" exact component={shop} />
          <Route path="/fos-shop/bestellingen" exact component={bestellingen} />
          <Route path="/fos-shop/stock" exact component={stok} /> */}
          <Route path="/fos-shop/openingsuren" exact component={openingHours} />
          {/* <Route path="/fos-shop/bestelling/bevestigen" exact component={bevestigen} />
          <Route path="/fos-shop/besteling/details/:id" exact component={orderDetails} /> */}

          <Route path="/evenementen/:event" exact component={eventsRegister} />
          <Route path="/evenementen/:event/:id" exact component={eventsRegister} />
          <Route path="/privacy-verklaring" exact component={policyPage} />
          <Route path="/cookie-policy" exact component={cookiePolicyPage} />

          <Route path="/brieven" exact component={brievenList} />
          <Route path="/brieven/:type/:id" exact component={brievenUpload} />

      
          {/* <Route
          {/* <Route
            path="/fos-shop/shop/shoppingCart"
            exact
            component={shoppingCart}
          /> */}
          {/* </ScrollToTop>  */}
        </Router>
        <CookieConsent debug={false}
        buttonText="Ik begrijp het"
        buttonStyle={{ background: "#0f52ba", color: "white" }}>
          Om deze website goed te laten functioneren maken wij gebruik van cookies. 
          bekijk ons <a   href="/cookie-policy">cookiebeleid</a> en <a   href="/privacy-verklaring">privacyverklaring</a> 
          </CookieConsent>
      </div>
    )
  }
}
