import React, { Component } from 'react'
import { Table, Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import axios from "axios"


export default class questions extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount = () => {
    const self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_generalServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        self.setState({ authorized: true })
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })
    this.getQuestions()
  }

  getQuestions = () => {
    axios.get(`${process.env.REACT_APP_generalServer}api/questions`,
      { withCredentials: true })
      .then((response) => {
        this.setState({ questions: response.data })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  deleteQuestion = (id) => {
    axios.delete(`${process.env.REACT_APP_generalServer}api/questions/${id}`,
      { withCredentials: true })
      .then((response) => {
        this.getQuestions()
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  dateString2Date = (date) => {
    return new Date(date * 1000)
  }

  // dateString2Date = (date) => {
  //     var b = date.split(/\D+/)
  //     return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  //   }


  formatDate = (date) => {
    const zeroCheck = (number) => {
      //if the number is less than 9, a zero is added in front
      number = number > 9 ? number : `0${number}`

      return number
    }
    if (date) {

      let d = this.dateString2Date(date)

      return `${zeroCheck(d.getDate())}/${zeroCheck(d.getMonth() + 1)}/${d.getFullYear()}  ${zeroCheck(d.getHours())}:${zeroCheck(d.getMinutes())}:${zeroCheck(d.getSeconds())}u`
    }
    else {
      return ''
    }
  }


  renderheader = () => {
    return <thead>
      <tr>
        <th>#</th>
        <th>Voornaam</th>
        <th>Achternaam</th>
        <th>E-mailadress</th>
        <th>Telefooonnummer</th>
        <th>Gesteld op</th>
      </tr>
    </thead>
  }
  renderRow = () => {
    let self = this
    return this.state.questions && this.state.questions.map(function (question, i) {
      const date = self.formatDate(question.date)

      let { firstName, lastName, email, phoneNumber } = question
      return <tr key={i}>
        <td>{i}</td>
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{email}</td>
        <td>{phoneNumber}</td>
        <td>{date}</td>
        <td>{self.renderButtons(question._id)}</td>
      </tr>
    }
    )
  }



  renderButtons = (id) => {
    return <div style={{ float: "right" }}><Button
      size="sm"
      variant="primary"
      onClick={() => {
        this.props.history.push(
          `/admin/vraag/${id}`
        )
      }}
    >
      <FontAwesomeIcon className="fa" icon={faInfoCircle} />
      edit
    </Button>{' '}
      <Button
        size="sm"
        variant="danger"
        onClick={() => this.deleteQuestion(id)}
      >
        <FontAwesomeIcon className="fa" icon={faTrashAlt} />
        Delete
      </Button></div>
  }
  render() {
    if (!this.state.authorized) {
      return <div />
    } else {
      return (

        <div className="container">
          <Table striped bordered hover size="sm">
            {this.renderheader()}
            <tbody>
              {this.renderRow()}
            </tbody>
          </Table>
        </div>
      )
    }
  }
}
