import React, { Component } from 'react'
import {InputGroup,FormControl, Button} from "react-bootstrap";
import moment from 'moment'
import Calendar from './CustomCalenderActiviteiten'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCalendarDay,} from '@fortawesome/free-solid-svg-icons'

export default class datePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCalendar:false
        }
      }

      showCalender = (e) => {
       this.setState(
          { showCalendar:!this.state.showCalendar },
          
        )
      }
      

      updateDates = (date) => {
        let rtnDate=date
          if (!moment.isMoment(date)) {
            rtnDate = ''
          } 
          else{this.setState({showCalendar: false, })}
          this.props.selectedDate(rtnDate)
      }

    render() {
        return (
            <div>
                <InputGroup style={{ marginBottom: '5px' }}>
                    <FormControl
                      placeholder="Selecteer een datum"
                      readOnly
                      name="beginDate"
                      onKeyDown={this.clearDate}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={
                       this.props.date && moment.isMoment(this.props.date)
                          ? this.props.date.format('DD/MM/YYYY')
                          : this.props.date
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        name="showBegin"
                        variant="outline-secondary"
                        onClick={() => {
                          this.showCalender('showBegin')
                        }}
                      >
                        <FontAwesomeIcon className="fa" icon={faCalendarDay} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.state.showCalendar ? (
                    <div
                      style={{
                        position: 'relative',
                        right: '0px',
                        float: 'right',
                      }}
                    >
                      
                      <Calendar
                        classname="ustify-content-md-right"
                        style={{
                          position: 'relative',
                          right: '0px',
                          float: 'right',
                        }}
                        selectDateAllowed={true}
                        birthday={this.props.birthday}
                        showReservations={false}
                        showErrorMesage={true}
                        selectRange={false}
                        width="302px"
                        selectedDates={(date) => {
                          this.updateDates(date)
                        }}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                
            </div>
        )
    }
}
