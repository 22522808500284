
import React, { Component } from 'react'
import { Form, Col, Button, InputGroup, FormControl, Alert, Modal } from "react-bootstrap"
import moment from 'moment'
import DatePicker from '../../components/calendar/datePicker'
import axios from 'axios'
// import Recaptcha from 'react-google-recaptcha'
// import Recaptcha from 'react-google-invisible-recaptcha'
import Recaptcha from '../../components/reCAPTCHA'
import ScrollToTop from'../../ScrollToTop.js'

const recaptchaRef = React.createRef();

const fields = ["firstname", "lastname", "email", "phonenumber", "gender", "birthday", "street", "number",
    "postalcode", "state"]
const fields_dutch = ["voornaam", "achternaam", "E-mailadres", "telefoonnummer", "geslacht", "geboortedatum", "straatnaam",
    "huisnummer", "postcode", "gemeente"]



export default class newMember extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal:false,
            toggle: { 1: true, 2: true },
            // switch2:false,
            'birthday_lid': '',
            alertMessage: null
        }
    }



    componentDidMount=()=>{
            if (window.grecaptcha){
              window.grecaptcha.reset()
              window.location.reload(false);
            }
            // recaptchaRef.current.recaptchaReset()
            
   
    
        if (this.props.match.params.id){
            this.getData(this.props.match.params.id)
        }
        
    }

    // onChange =()=>{
    //     this.recaptcha.reset()
    // }

    

    

    getData = (id)=>{
        axios
            .get(
                `${process.env.REACT_APP_generalServer}api/newMember/singlemember/${id}`,
                {withCredentials:true}

            )
            .then((response) => {
                const data =  response.data
                const birthday_lid = response.data.birthday_lid
                this.setState(data)

                this.setState({toggle: { 1: false, 2: false }, showModal:false,
                    alertMessage: null, birthday_lid: moment.unix(birthday_lid)})
                
                
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    registerNewMember = () => {
        axios
            .post(
                `${process.env.REACT_APP_generalServer}api/newMember/add`,
                this.state,

            )
            .then((response) => {
                this.setState({showModal:true})
                // this.props.history.push('/')
            })
            .catch(function (error) {
                if (
                    error.response &&
                    error.response.status &&
                    error.response.status === 400
                  ) {
                    console.log(error.response.data)
                   
                  }
                console.log(error)
            })

    }

    updateNewMember = (id) => {
        axios
            .post(
                `${process.env.REACT_APP_generalServer}api/newMember/update/${id}`,
                this.state,

            )
            .then((response) => {
                this.props.history.push('/')
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    changeHangdler = (e) => {
        this.setState({ [e.target.name]: e.target.value, alertMessage: null })
    }
    renderAlert = () => {
        return <Alert variant="danger" show={this.state.alertMessage ? true : false}
            onClose={() => this.setState({ alertMessage: null })} dismissible>

            <div>
                {this.state.alertMessage}
            </div>
        </Alert>
    }
    switch = (contactpersoon) => {
        let toggle = this.state.toggle
        toggle[contactpersoon] = !toggle[contactpersoon]
        this.setState({
            toggle,
            [`street_${contactpersoon}`]: null,
            [`number_${contactpersoon}`]: null,
            [`bus'${contactpersoon}`]: null,
            [`postalcode_${contactpersoon}`]: null,
            [`state_${contactpersoon}`]: null,
        })

    }
    setadress1 = () => {
        if (this.state.toggle["1"]) {
            this.setState({
                "street_1": this.state['street_lid'],
                "number_1": this.state['number_lid'],
                "bus_1": this.state['bus_lid'],
                "postalcode_1": this.state['postalcode_lid'],
                "state_1": this.state['state_lid'],
            }, () => this.setadress2())
        }
        else { this.setadress2() }

    }
    setadress2 = () => {
        if (this.state.toggle["2"]) {
            this.setState({
                "street_2": this.state['street_lid'],
                "number_2": this.state['number_lid'],
                "bus_2": this.state['bus_lid'],
                "postalcode_2": this.state['postalcode_lid'],
                "state_2": this.state['state_lid'],
            }, () => { this.checkForm() })
        }
        else {
            this.checkForm()
        }

    }
    validatePostalCode = (postalCode) => {
        const re = /^[1-9]{1}[0-9]{3}$/
        return re.test(String(postalCode))
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    onResolved = () => {
        // this.recaptcha.reset()
        this.setState({ messageSent: true, alertMessage: null }, () => this.registerNewMember())
    }

    checkForm = () => {
        const persons = ["lid", "1", "2"]
        const person_error = ["het nieuwe lid", "contactpersoon 1", "contactpersoon 2"]
        let alertMessage = null
        let error = false
        for (let j in persons) {
            let person = persons[j]
            for (let i in fields) {
                let field = `${fields[i]}_${person}`
                if (field !== 'email_lid' && field !== 'phonenumber_lid' &&
                    field !== 'gender_1' && field !== 'gender_2' &&
                    field !== 'birthday_1' && field !== 'birthday_2') {
                    if ((field === 'email_1' || field === 'email_2') && (!this.state[field] || !this.validateEmail(this.state[field]))) {
                        alertMessage = `U hebt geen geldig E-mailadres voor ${person_error[j]} ingevuld `
                        error = true
                        break
                    }
                    else if ((fields[i] === "postalcode") && (!this.state[field] || !this.validatePostalCode(this.state[field]))) {
                        // let voorvoegsel = fields[i]!== "gender"? 'de':'het'
                        alertMessage = `U hebt geen geldige postcode voor ${person_error[j]} ingevuld `
                        error = true
                        break
                    }
                    else if (moment().startOf('day') < moment(this.state['birthday_lid']).startOf('day')) {
                        alertMessage = `U mag geen datum in de toekomst selecteren`
                        error = true
                        break
                    }
                    else if (!this.state[field] || this.state[field] === '') {
                        let voorvoegsel = fields[i] !== "gender" ? 'de' : 'het'
                        alertMessage = `U hebt ${voorvoegsel} ${fields_dutch[i]} van ${person_error[j]} nog niet ingevuld `
                        error = true
                        break
                    }

                    // if (!this.validateEmail(this.state['email'])){
                    //     // let voorvoegsel = fields[i]!== "gender"? 'de':'het'
                    //     alertMessage=`U hebt de email van ${person_error[j]} nog niet ingevuld `
                    //     error=true
                    //     break
    // }
                }

            }
            if (error) {
                break
            }

        }
        if (alertMessage) {
            this.setState({ alertMessage },()=>window.scrollTo(0,0,'smooth'))
        }
        else {
            // this.recaptcha.execute()
            // recaptchaRef.current.execute()
            this.recaptchaExecute()

        }

        // if (this.state[])
    }

    closeModal = ()=>{
        this.setState({showModal:false},()=>this.props.history.push('/'))
    }

    recaptchaExecute=()=>{
        recaptchaRef.current.recaptchaExecute()
      }

    renderForm = (props) => {

        return <Form>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridFirstName">
                    <Form.Label>Voornaam</Form.Label>
                    <Form.Control type="text"
                        name={`firstname_${props.contactpersoon}`}
                        value={this.state[`firstname_${props.contactpersoon}`]}
                        onChange={this.changeHangdler}
                        placeholder="Vul je voornaam in..." />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                    <Form.Label>Achternaam</Form.Label>
                    <Form.Control type="text"
                        name={`lastname_${props.contactpersoon}`}
                        value={this.state[`lastname_${props.contactpersoon}`]}
                        onChange={this.changeHangdler}
                        placeholder="Vul je achternaam in..." />
                </Form.Group>
            </Form.Row>
            {props.contactpersoon === "lid" ?
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridFirstName">
                        <Form.Label>Geslacht</Form.Label>
                        <Form.Control as="select" name="gender_lid"
                            onChange={(e) => this.setState({ 'gender_lid': e.target.value, alertMessage: null })}
                            value={this.state['gender_lid']}>
                            <option selected disabled hidden>-- geslacht --</option>
                            <option value="M">M</option>
                            <option value="V">V</option>
                            <option value="X">X</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLastName">
                        <Form.Label>Geboortedatum</Form.Label>
                        <DatePicker date={this.state['birthday_lid']}
                            birthday={true}
                            selectedDate={(date) => this.setState({ 'birthday_lid': date, alertMessage: null })} />
                    </Form.Group>
                </Form.Row> : <></>}
            {props.contactpersoon !== "lid" ?
                <Form.Row>
                    <Form.Group as={Col} xs="12" sm="6" controlId="formGridFirstName">
                        <Form.Label>E-mailadres</Form.Label>
                        <InputGroup className="mb_3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic_addon1">@</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Scouts@scoutsdepanne.be"
                                aria-label="Username"
                                aria-describedby="basic_addon1"
                                name={`email_${props.contactpersoon}`}
                                value={this.state[`email_${props.contactpersoon}`]}
                                onChange={this.changeHangdler}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLastName">
                        <Form.Label>Telefoonnummer</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">tel</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="0123456789"
                                aria-label="Username"
                                type='text'
                                aria-describedby="basic_addon1"
                                name={`phonenumber_${props.contactpersoon}`}
                                value={this.state[`phonenumber_${props.contactpersoon}`]}
                                onChange={this.changeHangdler}
                            />
                        </InputGroup>
                    </Form.Group>
                </Form.Row> : <></>}
            { !this.props.match.params.id && props.contactpersoon !== "lid"?
                <div>
                    <div>Is het adres hetzelfde als dat van het nieuwe lid?</div>
                    <Form.Check
                        type="switch"
                        id={`custom_switch_${props.contactpersoon}`}
                        disabled = {this.props.match.params.id?true:false}
                        label={this.state.toggle[props.contactpersoon] ? "ja" : "nee"}
                        checked={this.state.toggle[props.contactpersoon]}
                        onChange={() => { this.setState({ alertMessage: null }, () => this.switch(props.contactpersoon)) }}


                        style={{ marginBottom: "15px" }}

                    /></div> : <></>}

            {props.contactpersoon === "lid" || (props.contactpersoon !== "lid" && !this.state.toggle[props.contactpersoon]) ?
                <>
                    <Form.Row>
                        <Form.Group as={Col} xs="6" sm="8" controlId="formGridNStreet">
                            <Form.Label>Straat</Form.Label>
                            <Form.Control placeholder="Sportlaan"
                                name={`street_${props.contactpersoon}`}
                                value={this.state[`street_${props.contactpersoon}`]}
                                onChange={this.changeHangdler} />

                        </Form.Group>
                        <Form.Group as={Col} xs="3" sm="2" controlId="formGridNumber">
                            <Form.Label>Nummer</Form.Label>
                            <Form.Control placeholder="10"
                                name={`number_${props.contactpersoon}`}
                                value={this.state[`number_${props.contactpersoon}`]}
                                onChange={this.changeHangdler} />
                        </Form.Group>
                        <Form.Group as={Col} xs="3" sm="2" controlId="formGridBus">
                            <Form.Label>Bus</Form.Label>
                            <Form.Control
                                name={`bus_${props.contactpersoon}`}
                                value={this.state[`bus_${props.contactpersoon}`]}
                                onChange={this.changeHangdler} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPostalCode">
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control placeholder="1234"
                                type="number"
                                name={`postalcode_${props.contactpersoon}`}
                                value={this.state[`postalcode_${props.contactpersoon}`]}
                                onChange={this.changeHangdler} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Gemeente</Form.Label>
                            <Form.Control placeholder="Depanne"
                                name={`state_${props.contactpersoon}`}
                                value={this.state[`state_${props.contactpersoon}`]}
                                onChange={this.changeHangdler} />
                        </Form.Group>
                    </Form.Row></> : <></>}
        </Form>
    }
    render() {
        
        
        return (
            <ScrollToTop> 
            
            <div className="container" style={{ marginTop: "10px" }}>
                <this.renderAlert />
                <Modal show={this.state.showModal}  onHide={()=>this.closeModal()}>
                    <Modal.Header closeButton>
                    <Modal.Title>Uw kind is succesvol geregistreerd</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{`${this.state.firstname_lid} mag 3 opeenvolgende zaterdagen kostenloos deelnemen aan de activiteiten.`}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={()=>this.closeModal()} >
                        Oke
                    </Button>
                    
                    </Modal.Footer>
                </Modal>

                {!this.props.match.params.id?<div>
                <h2>
                    Jou zoon  of dochter inschrijven?
                </h2>
                <p>Nieuwe leden kunnen doorheen het hele jaar ingeschreven worden. Doormiddel van dit formulier in te vullen, kan uw kind tot drie opeenvolgende zaterdagen gratis deelnemen aan activiteiten. Heeft hij of zij de smaak daarna te pakken en wenst hij/zij te blijven komen, dan kost dit je slechts €60 voor Zeehonden en Welpen en €70 voor zeilende takken. Dit formulier wordt enkel gebruikt om de verzekering van de proefperiode (eerste 3 opeenvolgende zaterdagen na het invullen van dit formulier) te regelen. Verder gaat u geen enkele verbintenis met ons aan.</p>
                </div>:<div/>}
                <b>Nieuw Lid</b>
                <this.renderForm contactpersoon="lid" />
                <b>Contactpersoon 1</b>
                <this.renderForm contactpersoon="1" />
                <b>Contactpersoon 2</b>
                <this.renderForm contactpersoon="2" />
                {!this.props.match.params.id?
                <Button variant="primary" onClick={() => this.setadress1()}>
                    Inschrijven
                </Button>:

                <Button variant="primary" onClick={() => this.updateNewMember(this.props.match.params.id)}>
                    nieuw lid bijwerken
                </Button>}
            
                {/* <Recaptcha
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_capcha_sitekey}
                    locale='nl'
                    size='invisible'
                    // onChange={this.recaptcha.reset}
                    onResolved={this.onResolved} 
                    onErrored={console.log("recaptcha error")}/>
                  */}
                  <Recaptcha
                    ref={recaptchaRef}
                    onResolved={() => this.onResolved()} 
                    />

            </div>
      
             </ScrollToTop> 
        )
    }
}
