import React, { Component } from 'react'

export class features extends Component {
  render() {

    
    return (
      <div id="polisies" className="text-center">
        <div className="container" style={{padding:0}}>
         <p style={{marginBottom:0}}>Copyright &copy; 2023 't Kraaienest</p> 
         <a href="/privacy-verklaring">Privacy verklaring</a>
        </div>
      </div>
    )
  }
}

export default features
