import React, { Component } from 'react'

import logo from "../logotransparant.png"

import {
  Button,
  Nav,
  NavDropdown,
  Navbar
} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'


// const handleClickScroll = (id) => {
//   const element = document.getElementById(id);
//   if (element) {
//     // 👇 Will scroll smoothly to the top of the next section
//     element.scrollIntoView({ behavior: 'smooth' });
//   }
// };

export default class navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      special_fos_shop_rights: true,
      loggedIn: false,
      roles: [],
    }
  }
  componentDidMount = () => {
    this.checkAuthentication()
  }
  checkAuthentication = () => {
    axios
      .get(`${process.env.REACT_APP_authServer}api/auth/user-roles`, {
        withCredentials: true,
      })
      .then((response) => {

        this.setState({ roles: response.data }, () => this.props.parentCallback(this.state.loggedIn))
      })
      .catch(function (error) {
        
        if (error.response&&error.response.status !==401){
          console.log(error.response.statusText)
        }
    
      })
  }

  

  logout = () => {
    axios
      .get(`${process.env.REACT_APP_authServer}api/auth/logout`, {
        withCredentials: true,
      })
      .then((response) => {


        // this.setState({ roles: response.data },()=>this.props.parentCallback(this.state.loggedIn))
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  render() {
    if (this.props.loggedIn !== this.state.loggedIn) {
      this.checkAuthentication()
    }


    return (

      <div>
        <Navbar collapseOnSelect  fixed="top" bg="light" expand="lg" style={{marginRight:0}}>
          {/* <Container> */}
          <Navbar.Brand href="/">
            <img
              src={logo} //"../build/img/logotransparant.png"
              width="150"
              // height="40"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          {/* </Container> */}
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/* <Nav.Link href="/">Home</Nav.Link> */}
              <Nav.Link  href="/#takken">Takken</Nav.Link>
              <Nav.Link href="/kalender">kalender</Nav.Link>
              <Nav.Link  href="/#newMembers">Inschrijven</Nav.Link>
              <Nav.Link href="/#verhuur">Verhuur</Nav.Link>
              <Nav.Link href="/#team">Team</Nav.Link>
              {this.state.roles &&
                (this.state.roles.includes('Admin') ||
                  this.state.roles.includes('Fos Shop')) ? (
                <NavDropdown title="Fos-shop" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/#FosShop">Fos-shop</NavDropdown.Item>
                  {/* <NavDropdown.Item href="/fos-shop/bestellingen">
                    Bestellingen
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/fos-shop/stock?page=1&pageSize=20">
                    Stock
                  </NavDropdown.Item> */}
                  <NavDropdown.Item href="/fos-shop/openingsuren">
                    Openingsuren
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link href="/#FosShop">Fos-shop</Nav.Link>
              )}
              {this.state.roles &&
                (this.state.roles.includes('Admin') ||
                  this.state.roles.includes('Takleider')) ? (
                <NavDropdown title="Brieven" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/brieven">Brieven</NavDropdown.Item>
                  <NavDropdown.Item href="/brieven/new/0">
                    Uploaden
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link href="/brieven">Brieven</Nav.Link>
              )}
              <Nav.Link href="/#contact" >Contact</Nav.Link>
              
              
              {this.state.roles && this.state.roles.includes('Admin') ? (
                <Nav.Link href="/admin/overview/users">admin</Nav.Link>
              ) : (
                <div />
              )}
              
            </Nav>
            
            {/* <Form inline>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
              />
              <Button variant="outline-success">Search</Button>
            </Form>{' '} */}
            {this.state.roles && this.state.roles.length === 0 ?
              <Button className="pull-right" href="/login">
                <FontAwesomeIcon className="fa" icon={faSignInAlt} />
                aanmelden
              </Button> :
              <Button className="pull-right" onClick={this.logout} href='/login'>
                <FontAwesomeIcon className="fa" icon={faSignOutAlt} />
                afmelden
              </Button>}
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}
