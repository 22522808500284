import React, { Component } from 'react'
import { Card, Button, Popover, OverlayTrigger, Row } from 'react-bootstrap'
// import '../components/css/general.css'
import './css/card_custom.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faEuroSign,
  faExclamationTriangle,
  faCalendarAlt,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import moment from 'moment'
const TakText = (props) => <Card.Text>{props.text}</Card.Text>
const defaultContent = ['Er is nog geen volgende activiteit gepland voor deze tak.']

export default class kalenderInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      response: {
        tak: this.props.tak,
        content: null,
        date: { begin: null, end: null },
        time: { begin: null, end: null },
        location: { begin: null, end: null },
        price: null,
        required_items: [],
      }
    }
  }
  componentDidMount = () => {
    this.checkAuthorization()
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_generalServer}api/activities/nextActivity/tak/${this.props.tak}`
    //     )
    //     .then((res) => {
    //       if (res.data) {
    //          let response = { 
    //           tak: res.data.tak,
    //           content: res.data.content,
    //           date: res.data.date,
    //           time: res.data.time,
    //           location: res.data.location,
    //           price: res.data.price,
    //           required_items: res.data.required_items,
    //         }

    //         this.setState({
    //           response
    //         })
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
  }

  checkAuthorization = () => {
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/activities/check-editactivitiespageRole2/${this.props.tak}`,
        { body: false },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.setState({
          authenticated: response.data.authenticated,
        })
      })
      .catch(function (error) {
        console.log(error)
        if (error.response&&error.response.status !== 401) {
          console.log(error)
        }
      })
  }

  text() {
    return JSON.parse(this.props.data && this.props.data.content).map(function (text, i) {
      return <TakText text={text} key={i} />
    })
  }

  listBenodigdheden = () => {
    return JSON.parse(this.props.data && this.props.data.required_items).map(function (benodigdheden, i) {
      if (i < 3) {
        return ` ${benodigdheden}, `
      } else {
        return false
      }
    })
  }

  popoverItems = () => {
    return JSON.parse(this.props.data && this.props.data.required_items).map(function (item, i) {
      return <li key={i}>{item}</li>
    })
  }

  popover = () => {
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Benodigdheden</Popover.Title>
        <Popover.Content>{this.popoverItems()}</Popover.Content>
      </Popover>
    )
  }

  render() {

    const beginDate = this.props.data && moment.unix(this.props.data.date_begin)
    const endDate = this.props.data && moment.unix(this.props.data.date_end)

    let containerClassName = ` ${this.state.authenticated ? "authenticated" : ""}`

    return (

      <div id="card" className="card-container">
        {this.props && this.props.data && this.props.data.image_begin ?
          <img height="200" style={{
            width: '100%',

            objectFit: 'cover'
          }}
            alt=""
            // className="img-responsive" 
            // src={"https://images1.persgroep.net/rcs/XRc0rjUvA2VM0O7_de1L9pS3JOU/diocontent/101054766/_crop/0/0/1571/1044/_fitwidth/763?appId=2dc96dd3f167e919913d808324cbfeb2&quality=0.8"}
            src={`${process.env.REACT_APP_generalServer}${this.props && this.props.data && this.props.data.image_begin}`}
          /> : <div style={{ marginTop: 200 }} />}
        <h4 className="title">{this.props && this.props.tak}</h4>
        {!(this.props.data && this.props.data.content) ?
          <div className={containerClassName}>
            <p >{defaultContent}</p>
          </div>

          : <>
            {this.props.data.content && this.text()}
            {/* <div> */}
            {this.props.data && this.props.data.date_begin ? (
              <div className="activity-details">
                <Row>
                  <div className="fa-icon">
                    <FontAwesomeIcon className="fa-activity-info" icon={faCalendarAlt} /></div><div>
                    {beginDate.format('DD/MM/YYYY')}
                    {beginDate.format('DD/MM/YYYY') !== endDate.format('DD/MM/YYYY')
                      ? ` - ${endDate.format('DD/MM/YYYY')}`
                      : ''}
                  </div>
                </Row>
                <Row>
                  <div className="fa-icon">
                    <FontAwesomeIcon className="fa-activity-info" icon={faClock} /></div><div>
                    {beginDate.format('H:mm')} - {endDate.format('H:mm')}


                  </div>
                </Row>
                <Row>
                  {this.props.data && this.props.data.location_begin && this.props.data.location_end ? (
                    <>
                      <div className="fa-icon">
                        <FontAwesomeIcon className="fa-activity-info" icon={faMapMarkerAlt} /> </div><div>
                        {this.props.data.location_begin !== this.props.data.location_end ?
                          this.props.data.location_begin + " - " + this.props.data.location_end :
                          this.props.data.location_begin}
                      </div></>
                  ) : (
                    <div />
                  )}</Row>
                <Row>
                  {this.props.data && this.props.data.price > 0 ? (
                    <>
                      <div className="fa-icon">
                        <FontAwesomeIcon className="fa-activity-info" icon={faEuroSign} /> </div><div>
                        {this.props.data.price} Euro
                      </div></>
                  ) : (
                    <div />
                  )}</Row>
                {this.props.data && this.props.data.required_items &&
                  JSON.parse(this.props.data.required_items).length > 0 ? (
                  <div>
                    <Row>
                      <div className="fa-icon">
                        <FontAwesomeIcon
                          className="fa-activity-info"
                          icon={faExclamationTriangle}
                        /></div>
                      Benodigdheden:{' '}
                    </Row>
                    <div style={{ marginLeft: "25px" }}>
                      {this.listBenodigdheden()}
                      {this.props.data.required_items &&
                        this.props.data.required_items.length > 3 ? (
                        <OverlayTrigger
                          //   trigger="click"
                          placement="right"
                          overlay={this.popover()}
                        >
                          <text className="link">ect.</text>
                        </OverlayTrigger>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            ) : (
              <div />
            )}
            <div style={{ alignSelf: "flex-end" }}>
              <p>
                Ga verder naar de planning om de volgende activiteiten te bekijken
              </p>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="primary"
                  className="pull-down "
                  onClick={() => {
                    this.props.history.push(`/kalender/planning/${this.props.tak}`)
                  }}
                >
                  Planning
                </Button>
              </div>
              {/* </div> */}
            </div>
          </>}
        {this.state.authenticated && !(this.props.data && this.props.data.content) ?
          <div style={{ marginTop: "auto", textAlign: "center" }}>
            <Button
              variant="primary"
              className="pull-down "
              onClick={() => {
                this.props.history.push(`/kalender/planning/${this.props.tak}`)
              }}
            >
              Planning
            </Button>

            {/* <Button
        // style={{  left:"50%"}}
            variant="success"
            // className="pull-down"
            onClick={() => {
              this.props.history.push(`/kalender/planning/add/${this.props.tak}`)
            }}
          >
            Maak nieuwe activiteit
          </Button> */}
          </div> : <></>}

      </div>
    )
  }
}
