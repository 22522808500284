import React, { Component } from 'react'
import './mainpage.css'
import JsonData from './data.json'
import Features from './features'
// import About from './components/about'
import Evenementen from './events'
import NewMember from './newMember'
import Verhuur from './verhuur'
import FosShop from './fosShop'
import Takken from './takken'
// import Services from './components/services'
// import Gallery from './gallery'
// import Testimonials from './testimonials'
import Team from './Team'
import Contact from './contact'
import IntroCarousel from './introCarousel'
import Polisies from './policies'


import axios from 'axios'


const blue_button_style = {
  color: '#fff',
  borderRadius: '30px',
  paddingLeft: "25px",
  paddingRight: "25px",
  fontSize: "20px",
  margin: "10px",
  // float: 'right',
  backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
}

const red_button_style = {
  color: '#fff',

  borderRadius: '30px',
  paddingLeft: "25px",
  paddingRight: "25px",
  fontSize: "20px",
  // position: 'absolute',
  // right:'15px',
  float: 'right',
  backgroundImage: 'linear-gradient(to right, #f3b0b0 0%, #f73535 100%)',
}

// const Captcha =
//    <Recaptcha
//   // ref={ref => this.captcha = ref}
//   sitekey={process.env.REACT_APP_capcha_sitekey}
//   locale='nl'

//   />




// import  './mainpage.css'

export default class mainpage extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({ landingPageData: JsonData })
  }

  componentDidMount() {
    if (window.grecaptcha){
      window.grecaptcha.reset()
      window.location.reload(false);
    }

    this.getlandingPageData()
    // this.getIntroCarousels()
    this.getFoshopOpeninghour()
    this.getContactPhoneNumber()
    this.getEvents()
  }


  getFoshopOpeninghour = () => {
    axios.get(`${process.env.REACT_APP_generalServer}api/fosshopOpeningHours/nextOpening`,
      { withCredentials: true })
      .then((response) => {
        this.setState({ shopOpening: response.data })
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  // getIntroCarousels = () => {
  //   axios.get(`${process.env.REACT_APP_generalServer}api/carousel/images`,
  //   )
  //     .then((response) => {
  //       this.setState({ introCarousels: response.data })
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })

  // }



  getEvents = () => {
    axios.get(`${process.env.REACT_APP_generalServer}api/events/nextEvent`,)

      .then((response) => {
        this.setState({ event: response.data })
      })
      .catch(function (error) {
        console.log(error)
      })


  }

  getContactPhoneNumber = () => {
    // het telefoon nummer van de eenheidsleider wordt opgevraagd en in de conact details wer gegeven
    axios.get(`${process.env.REACT_APP_authServer}api/members/eenheidsleider`,)

      .then((response) => {
        this.setState({ phone_eenheidsleider: response.data })
      })
      .catch(function (error) {
        console.log(error)
      })

  }
  render() {
    return (
      <div>
        <div style={{ textAlign: 'center', height: '100vh', background: '#f6f6f6' }} >
          <IntroCarousel data={this.state.landingPageData.IntroCarousel}  />
          {/* <Header data={this.state.landingPageData.Header} /> */}
        </div>

        {this.state && this.state.event ?
          <Evenementen blue_button_style={blue_button_style} history={this.props.history} events={this.state.event} /> : <></>}
        <Features blue_button_style={blue_button_style} history={this.props.history} data={this.state.landingPageData.Features} />
        <Takken data={this.state.landingPageData.Takken} />
        <FosShop history={this.props.history} data={this.state.landingPageData.FosShop}
          red_button_style={red_button_style}
          from={this.state.shopOpening && this.state.shopOpening[0] && this.state.shopOpening[0].start}
          till={this.state.shopOpening && this.state.shopOpening[0] && this.state.shopOpening[0].end} />
        <Verhuur blue_button_style={blue_button_style} history={this.props.history} data={this.state.landingPageData.Verhuur} />
        <Team history={this.props.history} data={this.state.landingPageData.Team} />
        <NewMember red_button_style={red_button_style} history={this.props.history} data={this.state.landingPageData.NewMember} />
       
        <Contact red_button_style={red_button_style} phone_eenheidsleider={this.state.phone_eenheidsleider} data={this.state.landingPageData.Contact} 
        // recaptchaRef = {this.props.recaptchaRef}
        />
        <Polisies/>

      </div>
    )
  }
}
