import React, { Component } from 'react'
import jsPDF from "jspdf";
import "jspdf-autotable";
import Modal from '../doubleCheckModal/doubleCheckModal'
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPrint
} from '@fortawesome/free-solid-svg-icons'



//  const unit = this.props.unit||"pt";
//         const size = this.props.size||"A4"; // Use A1, A2, A3 or A4
//         const orientation = this.props.orientation||"landscape"; // portrait or landscape

//         const marginLeft = this.props.marginLeft||40;



const marginX = (pageWidth, canvasWidth = 200) => {
  return (pageWidth - canvasWidth) / 2
}

export default class exportPDFTable extends Component {
  constructor() {
    super();
    this.state = {
      modalMessage: false
    }
  }

  dateString2Date = (date) => {
    var b = date.split(/\D+/)
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  }

  addPage = (coordY, doc) => {
    if (coordY >= doc.internal.pageSize.getHeight()) {
      doc.addPage();
      coordY = 40 // Restart height position
    }
    return coordY

  }



  exportPDF = () => {

    const unit = this.props.unit || "pt";
    const size = this.props.size || "A4"; // Use A1, A2, A3 or A4
    const orientation = this.props.orientation || "landscape"; // portrait or landscape

    const marginLeft = this.props.marginLeft || 40;

    const doc = new jsPDF(orientation, unit, size,);
    const pageWidth = doc.internal.pageSize.getWidth();

    // const widthRatio = pageWidth / 180;
    // const heightRatio = pageHeight / 180;
    // const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;


    var canvasHeight = 180;




    const headers = this.props.headers;;


    const { first_name, last_name } = this.props.memberData

    let data = []


    // for (let i = 0; i < this.props.rows.length; i++) {

    //     if (this.props.rows[i].public){
    //     data.push(this.createRowItem(this.props.rows[i]));}

    //     else if (!this.state.modalMessage){
    //         this.setState({modalMessage: true})

    //     }
    // }

    let content = {
      startY: 40 + 15 + 12,
      head: headers,
      body: data,
    };


    let coordY = 40
    doc.setFont("times");
    doc.setFontSize(22);
    doc.text("Registreer en verifieren je account op onze wesbite", marginLeft, coordY); coordY += 20; coordY = this.addPage(coordY, doc)
    doc.setFontSize(15);
    doc.text("Hoe ga je tewerk?", marginLeft, coordY); coordY += 15; coordY = this.addPage(coordY, doc)

   
    canvasHeight=30
    doc.setFontSize(12);
    doc.text("1.\tNavigeer naar http://new.scoutsdepanne.be", marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    doc.text("2.\tClick in de rechter bovenhoek op inloggen", marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    let imgData = `${process.env.REACT_APP_generalServer}build/img/registration_steps/login_button.JPG`
    doc.addImage(imgData, 'JPEG', marginX(pageWidth, 100), coordY, 90, canvasHeight); coordY += canvasHeight + 15; coordY = this.addPage(coordY, doc)


    // doc.text("3.\tIndien je nog geen account hebt klik op Registreren", marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    // let imgData2 = `${process.env.REACT_APP_generalServer}build/img/registration_steps/register_button.JPG`
    // doc.addImage(imgData2, 'JPEG', marginX(pageWidth), coordY, 200, canvasHeight); coordY += canvasHeight + 15; coordY = this.addPage(coordY, doc)

    // doc.text("4.\tmaak een account aan doormiddel van alle velden in te vullen", marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    // let imgData3 = `${process.env.REACT_APP_generalServer}build/img/registration_steps/register_screen.JPG`
    // doc.addImage(imgData3, 'JPEG', marginX(pageWidth), coordY, 200, canvasHeight); doc.addPage(); coordY = 40; coordY = this.addPage(coordY, doc)


    // doc.text("5.\tdoormiddel van onderstaande gegevens in te vullen in het verificatie scherm wordt\n\t   uw account gevalideerd en de juiste rechten aan u toegekend", marginLeft + 20, coordY); coordY += 30
    // doc.text(`\t\t\t*\tVoornaam: ${this.props && this.props.memberData.first_name}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    // doc.text(`\t\t\t*\tAchternaam: ${this.props && this.props.memberData.last_name}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    // doc.text(`\t\t\t*\tIk ben: ${this.props && this.props.memberData.verification_function}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    // doc.text(`\t\t\t*\tVerifictie code: ${this.props && this.props.memberData.verification_code}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)

    doc.text("3.\tLog voor de aller eerste keer in met volgende gegeven. nadien kan je je passwoord nog \n\tweizigen. Indien je een ander email adres wenst te koppel aan je scouts account, neem \n\tdan contact op met de eenheidleiding.", marginLeft + 20, coordY); coordY += 45
    doc.text(`\t\t\t*\tEmail:        ${this.props && this.props.memberData.email}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    doc.text(`\t\t\t*\tPaswoord: ${this.props && this.props.memberData.verification_code}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    // doc.text(`\t\t\t*\tIk ben: ${this.props && this.props.memberData.verification_function}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    // doc.text(`\t\t\t*\tVerifictie code: ${this.props && this.props.memberData.verification_code}`, marginLeft + 20, coordY); coordY += 15; coordY = this.addPage(coordY, doc)
    let imgData4 = `${process.env.REACT_APP_generalServer}build/img/registration_steps/verification_screen.JPG`
    doc.addImage(imgData4, 'JPEG', marginX(pageWidth), coordY, 200, canvasHeight); coordY += canvasHeight + 15; coordY = this.addPage(coordY, doc)






    // doc.text(subtitle, marginLeft, 40+15);

    // doc.setFontSize(22);
    // doc.setTextColor(255, 0, 0);
    // doc.text(20, 20, 'This is a title');

    // doc.setFontSize(16);
    // doc.setTextColor(0, 255, 0);
    // doc.text(20, 30, 'This is some normal sized text underneath.');

    doc.autoTable(content);
    doc.save(`Registratie-formulier ${first_name}_${last_name}.pdf`)



  }


  createRowItem = (row) => {
    let self = this
    let dateSet = false

    return this.props.fieldNames.map(function (element, i) {
      let rtn = ""
      let date_begin = self.dateString2Date(row.date.begin)
      let date_end = self.dateString2Date(row.date.end)
      if (element === 'date' && !dateSet) {
        rtn =
          date_begin.toDateString() === date_end.toDateString() ? (

            String(date_begin.getDate() + "/" + String(date_begin.getMonth() + 1) + "/" +
              String(date_begin.getFullYear()))

          ) : (

            String(date_begin.getDate()) + "/" + String(date_begin.getMonth() + 1) + "/" +
            String(date_begin.getFullYear()) + " - " + String(date_end.getDate()) + "/" +
            String(date_end.getMonth() + 1) + "/" + String(date_end.getFullYear())

          )
        dateSet = true
      }
      else if (element === 'content') {
        rtn = String(row.content && row.content.join(', '))
      } else if (element === 'location') {
        rtn =
          row.location.begin === row.location.end ? (
            row.location.begin
          ) : (

            "Begin: " + row.location.begin + ", Einde: " + row.location.end
          )
      }
      else if (element === 'date' && dateSet) {
        rtn =
          date_begin.toTimeString() === date_end.toTimeString() ? (

            String(date_begin.getHours()) + ":" + String(date_begin.getMinutes()) + "u"
          ) : (

            "Begin: " + String(date_begin.getHours()) + ":" + String(date_begin.getMinutes()) + "U, " +
            "Einde: " + String(date_end.getHours()) + ":" + String(date_end.getMinutes()) + "U"

          )
      }
      else if (element === 'price') {
        rtn = row.price ? (
          String(row.price) + " EUR"
        ) : (
          String("")
        )
      } else if (row.Required_items) {
        rtn = (
          String(row.Required_items.join(', '))
        )
      }
      return rtn
    })


  }

  renderModal = () => {
    if (this.state.modalMessage) {
      return (
        "Enkel de publieke activiteiten worden in de gedownloade PDF weergegeven"
      )
    }
    else { return "" }
  }



  render() {
    return (
      <div >
        <Modal message={this.renderModal()}
          agreeAction={() => this.setState({ modalMessage: false })}
          closeAction={() => this.setState({ modalMessage: false })}
          onlyButtonTrue
          header="Let Op!"
          buttonTrue="oke" />

        <Button
          // className="pull-right" 
          // size= "sm"
          variant="success"
          onClick={() => this.exportPDF()}>
          <FontAwesomeIcon className="fa" icon={faPrint} />Print
        </Button>
      </div>
    )
  }
}
