import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEuroSign,
  faCalendarDay,
  faTimes,
  faQuestionCircle,
  faCalendarPlus,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import UnAuthorized from '../Pages/UnAuthorized'
import {
  Form,
  OverlayTrigger,
  Popover,
  Button,
  Row,
  Card,
  Badge,
  Col,
  Alert,
  FormControl,
  InputGroup,
  Modal,

} from 'react-bootstrap'
import axios from 'axios'

import './css/login.css'
import Calender from './calendar/CustomCalenderActiviteiten'

import './css/createCalenderActivity.css'

export default class InputActivity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: {
        content: '',
        beginLocation: 'Lokaal',
        endLocation: 'Lokaal',

        beginHour: 14,
        beginMinute: 0,
        endHour: 17,
        endMinute: 0,
        beginDate: '',
        endDate: '',
        currentItem: '',
        switchState: false,
        benodigdheden: [],
        price: '',
        contentSplit: [],
      },
      validated: false,
      comparePassword: false,
      errorResponse: '',
      showError: false,
      showCalendarBegin: false,
      showCalendarEnd: false,
      calendarErrorMessage: null,
      takOrEenheidsLeiding: true,
      // switchLabel: 'Intern',
      showModal: false,
      newLocation: '',
      modalError: '',
      locations: [],
    }
  }

  async componentDidMount() {
    const self = this
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/activities/check-editactivitiespageRole/${this.props.props.match.params.tak}`,
        { body: false },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        self.setState(
          {
            authorized: response.data.authenticated,
            publishable: response.data.publisher,
          }
        )
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })

    this.getLocations()


    if (this.props.props.match.params.id) {
      let resData = await axios
        .get(
          `${process.env.REACT_APP_generalServer}api/activities/${this.props.props.match.params.id}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          let d = response.data
          	console.log(d)

          let date_begin = this.dateString2Date(d.date_begin)
          let date_end = this.dateString2Date(d.date_end)
          let moment_date_begin = moment(
            `${date_begin.getDate()}/${date_begin.getMonth() + 1
            }/${date_begin.getFullYear()}`,
            'DD/MM/YYYY'
          )
          let moment_date_end = moment(
            `${date_end.getDate()}/${date_end.getMonth() + 1
            }/${date_end.getFullYear()}`,
            'DD/MM/YYYY'
          )

          let inputs = {
            content: JSON.parse(d.content).join('\n'),
            beginLocation: d.location_begin,
            endLocation: d.location_end,

            beginHour: date_begin.getHours(),
            beginMinute: date_begin.getMinutes(),
            endHour: date_end.getHours(),
            endMinute: date_end.getMinutes(),
            beginDate: moment_date_begin,
            endDate: moment_date_end,
            currentItem: '',
            switchState: d.public,
            benodigdheden: JSON.parse(d.required_items) || [],
            price: d.price || '',
            contentSplit: [],
          }
          this.setState({ inputs })

  
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 409
          ) {
            this.setState({ errorResponse: error.response.data })
          } else {
            console.log(error)
          }
        })
      this.setState({ resData })
    }
  }

  dateString2Date = (date) => {

    return new Date(date * 1000)
  }

  getLocations = () => {

    axios
      .get(
        `${process.env.REACT_APP_generalServer}api/locations/location`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.setState({ locations: response.data, }, () => this.setState({ showModal: this.state.locations.length === 0 }))
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          this.setState({ authorized: false })
        }
        console.log(error)
      })
  }



  handleSubmit = (event) => {
    let inputs = { ...this.state.inputs }

    //go over the form and report if anything is missing before sending to server

    const dutchlist = [
      'een beschrijving van de activiteit',
      'een begin locatie',
      'een eind locatie',
      'een begin uur',
      'begin minuten',
      'een eind uur',
      'een eind minuten',
      'een begin datum',
      'een eind datum',
      'currentItem',
      'switchState',
      'benodigdheden',
      'prijs',
    ]
    let errorResponse = ''
    let showError = true
    let counter = 0
    let errors = false

    for (const [key, value] of Object.entries(inputs).slice(0, 9)) {
      if (
        key !== 'content' &&
        inputs.price !== '' &&
        (Number.isNaN(parseFloat(inputs.price)) ||
          (typeof inputs.price !== 'number' && inputs.price.includes(',')))
      ) {
        errorResponse = `de prijs is niet correct ingevuld. Let er ook op dat je komma getallen steeds met een punt worden weergegeven. bv 1.5 in plaast van 1,5 `
        this.setState({ errorResponse, showError })
        errors = true
      } else if (value === '') {
        errorResponse = `Je bent verplicht ${dutchlist[counter]} te geven`
        this.setState({ errorResponse, showError })
        errors = true
        break
      }
      counter++
    }
    if (!errors) {
      inputs.contentSplit = inputs.content.split('\n')
      inputs.price = parseFloat(inputs.price)
      this.setState({ inputs }, () => this.sendData2Db())
    }
  }

  sendData2Db = () => {
    let body = { ...this.state.inputs }
    body.tak = this.props.props.match.params.tak
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/activities/${this.props.props.match.params.id
          ? `update/${this.props.props.match.params.id}`
          : 'add'
        }`,
        body,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.props.props.history.goBack()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  popover = () => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Wie mag dit zien</Popover.Title>
      <Popover.Content>
        <p>
          Enkel de{' '}
          <strong>
            takleider van de {this.props.props.match.params.tak} en
            eenheidsleiding
          </strong>{' '}
          kunnen een activitiet publiek maken
        </p>
        <p>
          Intern: dit bekend dat enkel ingelogde leiding deze activiteit kan
          zien.
        </p>
        <p>
          Publiek: dit bekend dat iedereen (ook niet ingelogde bezoekers deze
          activiteit kan zien
        </p>
      </Popover.Content>
    </Popover>
  )

  toggleSwitch = () => {
    if (this.state.takOrEenheidsLeiding) {
      let inputs = { ...this.state.inputs }
      inputs.switchState = !inputs.switchState
      // if (inputs.switchState === true) {
      //   this.setState({
      //     switchLabel: 'Publiek',
      //   })
      // } else {
      //   this.setState({
      //     switchLabel: 'Intern',
      //   })
      // }
      this.setState({ inputs })
    }
  }
  changeHandler = (e) => {
    const target = e.target.name
    const inputs = { ...this.state.inputs }
    if (target === 'KeepLoggedIn') {
      inputs[target] = !inputs[target]
    } else {
      inputs[target] = e.target.value
    }
    const showModal = (target === 'beginLocation' || target === 'endLocation') && e.target.value === 'Voeg nieuwe locatie toe...' ? true : false

    this.setState({ inputs, comparePassword: false, showError: false, showModal })
  }

  newLocationChangeHandler = (e) => {
    this.setState({ newLocation: e.target.value, modalError: '' })

  }

  errorMessage = () => {
    return (
      <Alert
        variant="danger"
        onClose={() => this.setState({ showError: false })}
        dismissible
      >
        <p>{this.state.errorResponse}</p>
      </Alert>
    )
  }

  showCalendarError = () => {
    return this.state.calendarErrorMessage ? (
      <Alert
        variant="danger"
        onClose={() => this.closeCalendarErrorMessage()}
        dismissible
      >
        <p>{this.state.calendarErrorMessage}</p>
      </Alert>
    ) : (
      <div />
    )
  }

  removeItem = (i) => {
    this.state.inputs.benodigdheden.splice(i.i, 1)
    this.setState({})
  }

  showCalender = (e) => {
    let showCalendarBegin = false
    let showCalendarEnd = false

    if (e === 'showBegin') {
      showCalendarBegin = !this.state.showCalendarBegin
    } else {
      showCalendarEnd = !this.state.showCalendarEnd
    }

    this.setState(
      { showCalendarBegin, showCalendarEnd, calendarErrorMessage: null },
      () => { }
    )
  }

  updateDates = (date, begin_end) => {
    let inputs = this.state.inputs

    if (begin_end === 'begin') {
      if (date < moment()) {
        this.setState({
          calendarErrorMessage: 'Je kan geen datum in het verleden selecteren',
        })
        inputs.beginDate = ''
      } else {
        inputs.beginDate = date
        inputs.endDate = date
        this.setState({
          calendarErrorMessage: null,
        })
      }
    } else {
      if (
        moment.isMoment(this.state.inputs.beginDate) &&
        date < this.state.inputs.beginDate
      ) {
        this.setState({
          calendarErrorMessage:
            'De eind datum moet later of gelijk zijn aan de begin datum',
        })
      } else if (!moment.isMoment(this.state.inputs.beginDate)) {
        this.setState({
          calendarErrorMessage: 'Selecteer eerst een begin datum',
        })
      } else {
        inputs.endDate = date
        this.setState({ calendarErrorMessage: null })
      }
    }
    this.setState({ inputs })
  }

  closeCalendarErrorMessage = () => {
    this.setState({ calendarErrorMessage: null })
  }

  addItem2list = (e) => {
    let inputs = { ...this.state.inputs }
    if (e.keyCode === 13) {
      inputs.benodigdheden.push(inputs.currentItem)
      inputs.currentItem = ''
      this.setState({ inputs })
    }
  }

  mapItems = () => {
    let items = this.state.inputs.benodigdheden
    if (items.length === 0) {
      return <div className="dummy-text">{'Nothing to show'} </div>
    } else {
      return items.map((item, i) => {
        return (
          <div key={i}>
            <Badge
              className="items-text-center margin-right-3px"
              variant="secondary"
            >
              {item}
              <FontAwesomeIcon
                className="items-text-center margin-left-10px"
                icon={faTimes}
                onClick={() => {
                  this.removeItem({ i })
                }}
              />
            </Badge>
          </div>
        )
      })
    }
  }

  ImgPreview = () => {
    return this.state && (this.state.currentFigureURL || this.state.inputs.image) ?
      <div id='imgPreview'>
        <div className='header'>
          <p>Preview</p>
          <div onClick={() => { let inputs = this.state.inputs; inputs.image = null; this.setState({ currentFigure: null, currentFigureURL: null, inputs }) }}>
            <FontAwesomeIcon icon={faTimes} className='fa' color='white' /></div>
        </div>
        <div className='body'>
          {this.state.currentFigureURL ?
            <img src={this.state.currentFigureURL} alt='' /> :
            <img src={`${process.env.REACT_APP_generalServer}${this.state.inputs.image}`} alt='' />}
        </div></div> : <></>
  }

  changeFileHandeler = (e) => {
    var file = e.target.files
    if (e.target.files.length > 0) {
      var url = window.URL.createObjectURL(file[0])
      this.setState({ currentFigure: file[0], currentFigureURL: url, modalError: '' })
    }
    //this wil reset the current value of the form file. 
    // meaning that one image can be added twice ore more after eatch other
    e.target.value = null
  }

  uploadNewLocation = () => {
    let self = this
    let fd = new FormData()
    fd.append('location', this.state.newLocation)
    fd.append(`image`, this.state.currentFigure)

    axios
      .post(`${process.env.REACT_APP_authServer}api/locations/upload`, fd, {
        withCredentials: true,
      })
      .then((response) => {
        this.setState({ showModal: false, newLocation: '', currentFigure: '', currentFigureURL: '' }, () => this.getLocations())
        // this.props.history.push('/admin/overview/leden')
      })
      .catch(function (error) {
        console.log(error, error.response && error.response.data.message)
        // this.setState({ modalError: 'Selecteer een afbeelding!' })
        self.setState({ modalError: (error.response && error.response.data.message) })

      })




  }

  checkModal = () => {
    if (this.state.newLocation === '') {
      this.setState({ modalError: 'Vul een locatie in!' })
    }
    else if (!this.state.currentFigure) {
      this.setState({ modalError: 'Selecteer een afbeelding!' })
    }
    else {
      this.uploadNewLocation()
    }


  }


  renderModal = () => {

    return <Modal
      show={this.state.showModal}
      onHide={() => { this.setState({ showModal: false, }) }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Nieuwe locatie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={this.state.modalError !== ''} onClose={() => this.setState({ modalError: '' })} dismissible>

          <p>
            {this.state.modalError}
          </p>
        </Alert>
        <Form>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Locatie
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text"
                placeholder="bv: Lokaal"
                value={this.state.newLocation}
                onChange={this.newLocationChangeHandler} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPlaintextPassword">
            <Form.Label column sm="2">
              Afbeelding
            </Form.Label>
            <Col sm="10">
              <Form.File
                id="custom-file-translate-html"
                label="Voeg je document toe"
                data-browse="Bestand kiezen"
                onChange={this.changeFileHandeler.bind(this)}
                custom />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="modalPreviewImage">
            <Form.Label column sm="2">

            </Form.Label>
            <Col sm="10">
              {this.ImgPreview()}
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => this.setState({ showModal: false, newLocation: '', currentFigure: '', currentFigureURL: '' })}>
          Annuleer
        </Button>
        <Button variant="primary" onClick={() => this.checkModal()}>Voeg toe</Button>
      </Modal.Footer>
    </Modal>
  }

  // dropdownChangeHandeler = (e)=>{
  //   const target = e.target.value

  //   if (e.target.name === 'monthInput') {
  //     this.setMonth(target)
  //   } else {
  //     this.setYear(target)
  //   }

  // }


  renderDropdownLocation = (name) => {

    return <Form.Control as="select" defaultValue={"Lokaal"} name={name} onChange={this.changeHandler}>
      {this.state && this.state.locations.map((location, i) => {
        return <option key={i} selected={location.location === "Lokaal" ? "selected" : false}>{location.location}</option>
      }
      )}
      <hr />
      <option >Voeg nieuwe locatie toe...</option>
    </Form.Control>
  }

  render() {

    if (typeof this.state.authorized === 'undefined') {
      return <div />
    } else if (!this.state.authorized) {
      return <UnAuthorized />
    } else {
      return (
        <div className='container'>
          {this.renderModal()}
          <div className="addicalender-item-container justify-content-md-center">
            <Form>
              <div className="login-title">
                <b>
                  {`${this.props.props.match.params.id ? 'Aanpassen' : 'Nieuwe'
                    } ${this.props.props.match.params.tak} activiteit`}{' '}
                </b>
              </div>
              {this.state.showError ? this.errorMessage() : <div />}
              <Form.Group
                as={Row}
                className="title-margin"
                controlId="formFirstName"
              >
                <Form.Label column sm={2}>
                  Activiteit beschrijving
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    name="content"
                    value={this.state.inputs.content}
                    type="text"
                    as="textarea"
                    rows="3"
                    placeholder="wat gaat de activiteit inhouden?"
                    onChange={this.changeHandler.bind(this)}
                  />
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row} controlId="formLastName">
                <Form.Label column sm={2}>
                  Prijs
                </Form.Label>
                <Col sm={10}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon className="fa" icon={faEuroSign} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      name="price"
                      value={this.state.inputs.price}
                      type="text"
                      placeholder="Indien dit van toepassing is, vul in!"
                      onChange={this.changeHandler.bind(this)}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>{' '}
              <Form.Group
                as={Row}
                controlId="formLastName"
                style={{ marginBottom: '0px' }}
              >
                <Form.Label column sm={2} style={{ paddingBottom: '2px' }}>
                  Benodigdheden
                </Form.Label>
                <Col sm={10}>
                  <Card
                    placeholder="Speciale benidigdheden bv: zaklamp, cadeautje, slaapzak, etc"
                    readOnly
                    as={InputGroup}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{
                      resize: 'both',
                      overflow: 'auto',
                    }}
                  >
                    {<h6 className="rowC ">{this.mapItems()}</h6>}
                  </Card>
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row} controlId="formItem">
                <Form.Label column sm={6}></Form.Label>

                <Col sm={6}>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        type="number"
                      >
                        item toevoegen
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      aria-label="Default"
                      value={this.state.inputs.currentItem}
                      name="currentItem"
                      aria-describedby="inputGroup-sizing-default"
                      onKeyDown={this.addItem2list.bind(this)}
                      onChange={this.changeHandler.bind(this)}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Weergave
                  <OverlayTrigger
                    trigger={['focus', 'hover', 'clicked']}
                    placement="right"
                    overlay={this.popover()}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      className="fa-left-marging-5px"
                      icon={faQuestionCircle}
                    />
                  </OverlayTrigger>
                  {/* </Button> */}
                </Form.Label>
                <Col sm={10} className="align-switch">
                  <Form.Check
                    checked={this.state.inputs.switchState}
                    type="switch"
                    id="custom-switch"
                    disabled={!this.state.publishable}
                    label={this.state.inputs.switchState?'Publiek':"Intern"}
                    onClick={() => this.toggleSwitch()}
                  />
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row} controlId="formLocation">
                <Form.Label column sm={2}>
                  Locatie
                </Form.Label>
                <Form.Label column sm={1}>
                  Begin
                </Form.Label>
                <Col sm={4}>
                  {this.renderDropdownLocation("beginLocation")}
                  {/* <Form.Control as="select" defaultValue="Choose...">
                  
                <option>Choose...</option>
                <option>...</option>
              </Form.Control> */}

                  {/* <Form.Control
                    name="beginLocation"
                    value={this.state.inputs.beginLocation}
                    type="text"
                    placeholder="bv: Lokaal"
                    onChange={this.changeHandler.bind(this)}
                  /> */}
                </Col>
                <Form.Label column sm={1}>
                  Einde
                </Form.Label>
                <Col>
                  {this.renderDropdownLocation('endLocation')}
                  {/* <Form.Control
                    name="endLocation"
                    value={this.state.inputs.endLocation}
                    type="text"
                    placeholder="bv: Lokaal"
                    onChange={this.changeHandler.bind(this)}
                  /> */}
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row} controlId="formTime">
                <Form.Label column sm={2}>
                  Tijdstip
                </Form.Label>
                <Form.Label column sm={1}>
                  Begin
                </Form.Label>
                <Col sm={4}>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        name="beginHour"
                        value={this.state.inputs.beginHour}
                        type="number"
                        placeholder="uur"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                    :
                    <Col>
                      <Form.Control
                        name="beginMinute"
                        value={this.state.inputs.beginMinute}
                        type="number"
                        placeholder="uur"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                  </Form.Row>
                </Col>

                <Form.Label column sm={1}>
                  Einde
                </Form.Label>

                <Col sm={4}>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        name="endHour"
                        value={this.state.inputs.endHour}
                        type="number"
                        placeholder="uur"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                    :
                    <Col>
                      <Form.Control
                        name="endMinute"
                        value={this.state.inputs.endMinute}
                        type="number"
                        placeholder="uur"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Datum
                </Form.Label>
                <Form.Label column sm={1}>
                  Begin
                </Form.Label>
                <Col sm={4}>
                  <InputGroup style={{ marginBottom: '5px' }}>
                    <FormControl
                      placeholder="23/03/2020"
                      readOnly
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={
                        moment.isMoment(this.state.inputs.beginDate)
                          ? this.state.inputs.beginDate.format('DD/MM/YYYY')
                          : this.state.inputs.beginDate
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        name="showBegin"
                        variant="outline-secondary"
                        onClick={() => {
                          this.showCalender('showBegin')
                        }}
                      >
                        <FontAwesomeIcon className="fa" icon={faCalendarDay} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.state.showCalendarBegin ? (
                    <div
                      style={{
                        position: 'relative',
                        right: '0px',
                        float: 'right',
                      }}
                    >
                      {this.showCalendarError()}
                      <Calender
                        classname="ustify-content-md-right"
                        style={{
                          position: 'relative',
                          right: '0px',
                          float: 'right',
                        }}
                        selectDateAllowed={true}
                        showReservations={false}
                        showErrorMesage={false}
                        selectRange={false}
                        width="302px"
                        selectedDates={(begin) => {
                          this.updateDates(begin, 'begin')
                        }}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                </Col>
                <Form.Label column sm={1}>
                  Einde
                </Form.Label>
                <Col sm={4}>
                  <InputGroup style={{ marginBottom: '5px' }}>
                    <FormControl
                      placeholder="23/03/2020"
                      readOnly
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={
                        moment.isMoment(this.state.inputs.endDate)
                          ? this.state.inputs.endDate.format('DD/MM/YYYY')
                          : this.state.inputs.endDate
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        name="showEnd"
                        variant="outline-secondary"
                        onClick={() => {
                          this.showCalender('showEnd')
                        }}
                      >
                        <FontAwesomeIcon className="fa" icon={faCalendarDay} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.state.showCalendarEnd ? (
                    <div
                      style={{
                        position: 'relative',
                        right: '0px',
                        float: 'right',
                      }}
                    >
                      {this.showCalendarError()}
                      <Calender
                        style={{
                          position: 'relative',
                          right: '0px',
                          float: 'right',
                        }}
                        classname="ustify-content-md-right"
                        selectDateAllowed={true}
                        showReservations={false}
                        showErrorMesage={false}
                        selectRange={false}
                        width="302px"
                        selectedDates={(end) => {
                          this.updateDates(end, 'end')
                        }}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                </Col>
              </Form.Group>{' '}
              <div className="align-center">
                <Button size="sm" onClick={this.handleSubmit.bind(this)}>
                  <FontAwesomeIcon className="fa" icon={faCalendarPlus} />
                  Activiteit{' '}
                  {this.props.props.match.params.id ? 'aanpassen' : 'toevoegen'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )
    }
  }
}
