import React, { Component } from 'react'
import { Form, InputGroup, Button, Col, Modal, Alert, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import axios from "axios"
// import Recaptcha from 'react-google-invisible-recaptcha'
import Recaptcha from '../../components/reCAPTCHA'

const recaptchaRef = React.createRef();

export default class vraag extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        question: '',
        response: '',
      },
      messageSent: false,
      message_to_much_characters: '',
      answerAlreadySent: false,
      inProcess: false,

      validated: false,
      showModal: false,
      errorMessage: ""
    }
  }
  responseForm = this.props.responseForm

  componentDidMount = () => {
    if (window.grecaptcha){
      window.grecaptcha.reset()
      window.location.reload(false);
    }

    if (this.props.responseForm) {
      let answerAlreadySent = (!this.props.data.response || this.props.data.response === "") ? false : true
      this.setState({ inputs: this.props.data, answerAlreadySent })
    }
   
   

  }


  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  send2Server = () => {
    if (this.state.messageSent === true) {
      if (!this.responseForm) {
        axios.post(`${process.env.REACT_APP_generalServer}api/questions/renting`,
          { data: this.state.inputs })
          .then(() => {
            this.setState({ showModal: true, inProcess: false })
          })
          .catch(function (error) {
            console.log(error)
          })
      }

      else {
        axios.post(`${process.env.REACT_APP_generalServer}api/questions/update/${this.props.props.match.params.id}`,
          { data: this.state.inputs }, { withCredentials: true })
          .then(() => {
            this.setState({ showModal: true })
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
    else { console.log('you are a robot') }


  }

  submitForm = () => {
    const { firstName, lastName, email, question, response } = this.state.inputs
    let errorMessage = ''
    if (this.props.responseForm) {
      if (response === "") {
        errorMessage = "Je hebt nog geen antwoord ingevuld"
      }
    }
    else {
      if (firstName === "") {
        errorMessage = "Gelieve uw voornaam in te vullen"
      }
      else if (lastName === "") {
        errorMessage = "Gelieve uw achternaam in te vullen"
      }
      else if (!this.validateEmail(email)) {
        errorMessage = "Gelieve een geldig e-mailadres in te vullen"
      }
      else if (question === "") {
        errorMessage = "U hebt nog geen vraag gesteld"
      }
    }

    if (errorMessage === "" && !this.responseForm) {
      // this.recaptcha.execute()
      this.recaptchaExecute()




    }
    else if (errorMessage === "" && this.responseForm) {
      this.onResolved()
    }
    else {
      this.setState({ errorMessage })
    }
  }

  recaptchaExecute=()=>{
    recaptchaRef.current.recaptchaExecute()
  }



  errorMessage = () => {
    return (
      <Alert
        show={this.state.errorMessage !== ""}
        variant="danger"
        onClose={() => this.setState({ errorMessage: "" })}
        dismissible
      >
        <p>{this.state.errorMessage}</p>
      </Alert>
    )
  }

  // handleSubmit = (event) => {
  //   const form = event.currentTarget
  //   event.preventDefault()
  //   if (form.checkValidity() === false) {
  //     event.stopPropagation()
  //   } else {
  //     this.setState({ showModal: true })
  //   }

  //   this.setState({ validated: true })
  // }

  handleClose = () => {
    this.setState({ showModal: false })
    if (this.props.history) {
      this.props.history.push('/')
    }
    else {
      this.props.props.history.push('/admin/overview/vragen')
    }

  }

  changeHandeler = (e) => {
    const target = e.target.name
    const inputs = { ...this.state.inputs }
    inputs[target] = e.target.value
    this.setState({
      inputs,
      errorMessage: '',
      message_to_much_characters: '',

    })

    if (target === 'question' && inputs.question.length >= 2000) {
      inputs[target] = e.target.value.substring(0, 2000)
      this.setState({ inputs })
      this.setState({
        message_to_much_characters:
          'je vraag mag maximaal 2000 karakters bevatten',
      })
    }
    this.setState({ inputs })

    // this.setState({ message: '' })
  }

  showModal = () => {
    return (
      <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
        <Modal.Header>
          <Modal.Title>{this.responseForm ? "De vraag werd succesvol beantwoord!" : "Uw vraag is goed ontvangen!"}</Modal.Title>
        </Modal.Header>
        {!this.responseForm ?
          <Modal.Body>
            {`Beste ${this.state.inputs.firstName}, wij zullen uw vraag zo spoedig mogelijk beantwoorden.`}
          </Modal.Body> : <></>}
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleClose.bind(this)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  onResolved = () => {
    this.setState({ messageSent: true, inProcess: true }, () => this.send2Server())
  }

  render() {
    return (
      <div className="container">
        {this.errorMessage()}
        {this.showModal()}
        <Form>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="validationContactVoornaam">
              <Form.Label>Voornaam*</Form.Label>
              <Form.Control
                disabled={this.responseForm}
                name="firstName"
                value={this.state.inputs.firstName}
                required
                type="text"
                placeholder="Voornaam"
                onChange={this.changeHandeler}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationContactAchterNaam">
              <Form.Label>Achternaam*</Form.Label>
              <Form.Control
                disabled={this.responseForm}
                name="lastName"
                value={this.state.inputs.lastName}
                required
                type="text"
                placeholder="Achternaam"
                onChange={this.changeHandeler}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="validationEmail">
              <Form.Label>Email*</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  disabled={this.responseForm}
                  name="email"
                  value={this.state.inputs.email}
                  type="text"
                  placeholder="Email"
                  aria-describedby="inputGroupPrepend"
                  required
                  onChange={this.changeHandeler}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationEmail">
              <Form.Label>Telefoonnummer</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend"> <FontAwesomeIcon icon={faPhoneAlt} /></InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  disabled={this.responseForm}
                  name="phoneNumber"
                  value={this.state.inputs.phoneNumber}
                  type="number"
                  placeholder="Telefoonnummer"
                  aria-describedby="inputGroupPrepend"
                  required
                  onChange={this.changeHandeler}
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Vraag*</Form.Label>
              <Form.Control
                disabled={this.responseForm}
                name="question"
                value={this.state.inputs.question}
                as="textarea"
                required
                rows="4"
                onChange={this.changeHandeler}
              />
              <Form.Text className="text-muted">
                {this.state.message_to_much_characters}{' '}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {this.responseForm ?
              <Form.Group
                as={Col}
                md="12"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Antwoord</Form.Label>
                <Form.Control
                  disabled={this.state.answerAlreadySent}
                  name="response"
                  value={this.state.inputs.response}
                  as="textarea"
                  required
                  rows="4"
                  onChange={this.changeHandeler}
                />
                <Form.Text className="text-muted">
                  {this.state.message_to_much_characters}{' '}
                </Form.Text>
              </Form.Group> : <></>}
          </Form.Row>

          {this.state.answerAlreadySent ? <></> :
            (this.state.inProcess ?
              <Button disabled>
                <Spinner
                  as="div"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Verwerken...
              </Button> :
              <Button onClick={() => this.submitForm()}>{this.responseForm ? "Vraag beantwoorden" : "Vraag versturen"}</Button>)}



          {/* <Button onClick={()=>this.submitForm()}>{this.responseForm?"Vraag beantwoorden" : "Vraag versturen"}</Button>} */}
          {!this.responseForm ?
          <Recaptcha
                    ref={recaptchaRef}
                    onResolved={() => this.onResolved()} 
                    />: <></>}


          {/* {!this.responseForm ?
            <Recaptcha
              ref={ref => this.recaptcha = ref}
              sitekey={process.env.REACT_APP_capcha_sitekey}
              locale='nl'
              onResolved={this.onResolved} /> : <></>} */}
        </Form>
      </div>
    )
  }
}
