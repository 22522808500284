import React, { Component } from 'react'
import { Nav } from 'react-bootstrap'

import UnAuthorized from '../../Pages/UnAuthorized'
import axios from 'axios'

export default class nav extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount = () => {
    const self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_authServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        self.setState({ authorized: true })
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })
  }

  render() {
    if (typeof this.state.authorized === 'undefined') {
      return <div />
    } else if (!this.state.authorized) {
      return <UnAuthorized />
    } else {
      return (
        <div>
          <Nav variant="tabs" defaultActiveKey={this.props.location.pathname}>
            <Nav.Item>
              <Nav.Link href="/admin/overview/users">Gebruikers</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/admin/overview/leden">Scouts leden</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/admin/overview/planningen">Planningen</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/admin/overview/verhuurReservaties">
                Reservaties verhuuring
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/admin/overview/vragen">
                Vragen
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/admin/overview/evenementen">
                Evenementen
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/admin/overview/nieuweLeden">
                Nieuwe leden
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/admin/overview/calendar-locations">
                Kalender Locaties
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link href="/admin/overview/foto-albums">
                Foto albums
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </div>
      )
    }
  }
}
