import React, { Component } from 'react'
import { Tabs, Tab, Table, Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import {
    faTrashAlt,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faEdit,
} from '@fortawesome/free-solid-svg-icons'


export default class tableFormInformation extends Component {
    constructor() {
        super();
        this.state = {
            newTabNumber: 0,
            editTab: null,
            currentTabTitle: null,


        }
    }

    setFocus = (table, row, tabName, column) => {
        this.setState({ cellFocus: `${tabName}_${table}_${row}_${column}` })
    }
    setTitleFocus = (tab, table) => {
        this.setState({ cellFocus: `${tab}_${table}` })
    }


    changeHandler = (e) => {
        let currentCell = e.target.name.split("_");
        let tableFormInformation = this.props.tableFormInformation
        tableFormInformation[parseInt(currentCell[0])].tabContent[parseInt(currentCell[1])].content[parseInt(currentCell[2])][parseInt(currentCell[3])] = e.target.value
        if (this.props.editMode) { this.props.updateTables(tableFormInformation) }
    }

    changeHandlerTitles = (e) => {
        let currentCell = e.target.name.split("_");
        let tableFormInformation = this.props.tableFormInformation
        tableFormInformation[parseInt(currentCell[0])].tabContent[parseInt(currentCell[1])].title = e.target.value
        if (this.props.editMode) {
            this.props.updateTables(tableFormInformation)
        }
    }

    onEnterClick = (e) => {
        if (e.keyCode === 13 || e.keyCode === 27) {
            this.setState({ cellFocus: null, editTab: null })
        }
    }


    renderTable = (tab, tabNumber) => {
        let self = this
        return tab.tabContent.map(function (table, i) {
            return (<div key={`table-${i}`}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th colSpan="2" >{`${tabNumber}_${i}` !== self.state.cellFocus ?

                                <div style={{ position: "relative", display: "flex", flexDirection: "row" }} ><div onClick={() => { if (self.props.editMode) { self.setTitleFocus(tabNumber, i) } }}> {table.title}</div>
                                    <div style={{ position: "absolute", right: "0px" }}>{self.props.editMode ? self.renderIconGroup("table", tabNumber, tab.tabContent.length - 1 === i, null, i) : <></>}</div></div> :
                                <input
                                    name={`${tabNumber}_${i}`}
                                    autoFocus
                                    type="text"
                                    className="search-input"
                                    placeholder="Vul iets in..."
                                    value={table.title}
                                    onChange={self.changeHandlerTitles}
                                    onKeyDown={self.onEnterClick}
                                    onBlur={() => self.setState({ cellFocus: null })}
                                />}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {table.content.map(function (row, j) {
                            return <tr key={`row-${j}`}>
                                <td onClick={() => { if (self.props.editMode) { self.setFocus(i, j, tabNumber, 0) } }}>{`${tabNumber}_${i}_${j}_0` !== self.state.cellFocus ?
                                    <div >{row[0]}</div> :
                                    <input
                                        name={`${tabNumber}_${i}_${j}_0`}
                                        autoFocus
                                        type="text"
                                        className="search-input"
                                        placeholder="Vul iets in..."
                                        value={row[0]}
                                        onChange={self.changeHandler}
                                        onKeyDown={self.onEnterClick}
                                        onBlur={() => self.setState({ cellFocus: null })}
                                    />}</td>
                                <td onClick={() => { if (self.props.editMode) { self.setFocus(i, j, tabNumber, 1) } }}>{`${tabNumber}_${i}_${j}_1` !== self.state.cellFocus ?
                                    <div >{row[1]}</div> :
                                    <input
                                        name={`${tabNumber}_${i}_${j}_1`}
                                        autoFocus
                                        className="search-input"
                                        placeholder="Vul iets in..."
                                        value={row[1]}
                                        onChange={self.changeHandler}
                                        onKeyDown={self.onEnterClick}
                                        onBlur={() => self.setState({ cellFocus: null })}
                                    />}</td>

                                {self.props.editMode ? <td >
                                    <div style={{ position: "relative", display: "flex", flexDirection: "row" }} >
                                        {self.renderIconGroup("row", tabNumber, table.content.length - 1 === j, null, i, j)}
                                    </div>
                                </td> : <></>}
                            </tr>

                        })}
                    </tbody>
                </Table>
                {self.props.editMode ? self.renderBottomButtons(tabNumber, i, i === tab.tabContent.length - 1) : <></>}
            </div>)
        })

    }

    renderNewTable = () => {

    }
    addTab = (e) => {
        let newTabName = this.state.newTabNumber !== 0 ? `Nieuw tab-blad(${this.state.newTabNumber})` : "Nieuw tab-blad"
        let newTab = {
            tabTitle: newTabName,
            tabContent: [{
                title: "nieuw tab-blad",
                content: [["Prijs per persoon/nacht", "4,00 EUR"]]
            }]
        }

        if (this.props.editMode) {
            let tableFormInformation = this.props.tableFormInformation
            tableFormInformation.push(newTab)
            this.props.updateTables(tableFormInformation)
            this.setState({ newTabNumber: this.state.newTabNumber + 1 })
        }
    }

    changeHandlertabTitle = (e) => {
        let tableFormInformation = this.props.tableFormInformation
        tableFormInformation[parseInt(e.target.name)].tabTitle = e.target.value
        if (this.props.editMode) {
            this.props.updateTables(tableFormInformation)

        }
    }

    remove = (tab, type, table = null, row = null) => {
        let tableFormInformation = this.props.tableFormInformation
        if (this.props.editMode) {
            if (type === "tab") {
                tableFormInformation.splice(tab, 1)
            }
            else if (type === "table") {
                tableFormInformation[tab].tabContent.splice(table, 1)
            }
            else if (type === "row") {
                tableFormInformation[tab].tabContent[table].content.splice(row, 1)
            }
            this.props.updateTables(tableFormInformation)
        }

    }



    addRow = (tab, table) => {
        let tableFormInformation = this.props.tableFormInformation
        tableFormInformation[tab].tabContent[table].content.push(["Nieuwe rij", "Nieuwe rij"])
        this.props.updateTables(tableFormInformation)
    }

    addTable = (tab) => {
        let newTable = {
            title: "Nieuwe Tabel",
            content: [["Nieuwe rij", "Nieuwe rij"]]
        }
        let tableFormInformation = this.props.tableFormInformation
        if (this.props.editMode) {

            tableFormInformation[tab].tabContent.push(newTable)
            this.props.updateTables(tableFormInformation)
        }


    }

    swapTabs = (tab, direction, type, table = null, row = null) => {
        let tableFormInformation = this.props.tableFormInformation
        if (type === "tab") {
            var temporarilyItem = tableFormInformation[tab + direction];

            tableFormInformation[tab + direction] = tableFormInformation[tab]
            tableFormInformation[tab] = temporarilyItem
        }
        else if (type === "table") {
            temporarilyItem = tableFormInformation[tab].tabContent[table + direction];
            tableFormInformation[tab].tabContent[table + direction] = tableFormInformation[tab].tabContent[table]
            tableFormInformation[tab].tabContent[table] = temporarilyItem
        }
        else if (type === "row") {
            temporarilyItem = tableFormInformation[tab].tabContent[table].content[row + direction];
            tableFormInformation[tab].tabContent[table].content[row + direction] = tableFormInformation[tab].tabContent[table].content[row]
            tableFormInformation[tab].tabContent[table].content[row] = temporarilyItem
        }
        this.props.updateTables(tableFormInformation)

    }



    renderBottomButtons = (tab, table, lastTable) => {

        return <div style={{ float: "right", marginBottom: "30px" }}>
            <Button onClick={() => this.addRow(tab, table)}>Voeg Rij Toe</Button>{" "}
            {lastTable ?
                <Button onClick={() => this.addTable(tab)}>Voeg Tabel Toe</Button> : <></>}
        </div>
    }

    renderIconGroup = (type, tab, lastPosition, title = null, table = null, row = null) => {
        let IconStyle = { width: "25px", margin: "auto", cursor: "pointer", textAlign: "center" }

        return <div style={{ display: "flex", flexDirection: "row", marginLeft: "auto" }}>


            {(type === "tab" && tab > 0) || (table > 0 && type === "table") || (row > 0 && type === "row") ? <div style={IconStyle} onClick={() => this.swapTabs(tab, -1, type, table, row)}>
                <FontAwesomeIcon
                    size="sm"
                    icon={type !== "tab" ? faChevronUp : faChevronLeft}
                /></div> : <></>}
            {!lastPosition ? <div style={IconStyle} onClick={() => this.swapTabs(tab, 1, type, table, row)}>
                <FontAwesomeIcon
                    size="sm"
                    icon={type !== "tab" ? faChevronDown : faChevronRight}
                /></div> : <></>}
            {type === "tab" ? <div style={IconStyle} onClick={() => this.setState({ editTab: `title-${tab}` })}>
                <FontAwesomeIcon
                    size="sm"
                    icon={faEdit}
                /></div> : <></>}
            <div style={IconStyle} onClick={() => this.remove(tab, type, table)}>
                <FontAwesomeIcon
                    size="sm"
                    icon={faTrashAlt}
                /></div>




        </div>

    }


    renderTabTitle = (title, tab, lastTab) => {
        return <div>
            {this.state.editTab !== `title-${tab}` ?
                <div style={{ display: "flex", flexDirection: "row" }} >
                    {title}
                    {this.props.editMode ? this.renderIconGroup("tab", tab, lastTab, title)
                        : <></>}

                </div>
                :
                <input
                    name={`${tab}`}
                    autoFocus
                    className="search-input"
                    placeholder="Vul iets in..."
                    value={title}
                    onChange={this.changeHandlertabTitle}
                    onKeyDown={this.onEnterClick}
                    onBlur={() => this.setState({ editTab: null })}
                />}
        </div>

    }

    render() {
        let self = this

        return (
            <div>
                <Tabs
                    DefaultActiveKey={`tab-${this.props.tableFormInformation && this.props.tableFormInformation.length - 1}`}

                    id="uncontrolled-tab-example">
                    {this.props.tableFormInformation && this.props.tableFormInformation.map(function (tab, i) {
                        return <Tab key={`tab-${i}`} eventKey={`tab-${i}`} title={self.renderTabTitle(tab.tabTitle, i, i === (self.props.tableFormInformation && self.props.tableFormInformation.length - 1))}>
                            {self.renderTable(self.props.tableFormInformation && self.props.tableFormInformation[i], i)}
                        </Tab>

                    })}
                    <Button>test</Button>

                    {this.props.editMode ?
                        <Tab key={`table-toevoegen`} eventKey="toevoegen" title={<div className="link" onClick={(e) => this.addTab(e)} > Tab-blad toevoegen</div>}  >
                        </Tab> : <></>}
                </Tabs>

            </div>
        )
    }
}
