import React, { Component } from 'react'
import axios from 'axios'
import { ListGroup } from 'react-bootstrap'
import SingleEvent from './singleEvent'
import { Button } from 'react-bootstrap'

const blue_button_style = {
    color: '#fff',
    borderRadius: '30px',
    paddingLeft: "25px",
    paddingRight: "25px",
    fontSize: "20px",
    margin: "10px",
    backgroundImage: 'linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)',
}

export default class eventsOverview extends Component {

    componentDidMount = () => {
        this.getEvents()
    }



    getEvents = () => {
        axios.get(`${process.env.REACT_APP_generalServer}api/events/`, {
            withCredentials: true,
        })

            .then((response) => {
                this.setState({ events: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })


    }
    singleEvents = () => {
        const self = this
        if (this.state && this.state.events) {
            return this.state.events.map(function (event, i) {
                return <ListGroup.Item key={i}><SingleEvent blue_button_style={blue_button_style} history={self.props.history} event={self.state && self.state.events[i]} /></ListGroup.Item>

            })
        }
        else { return <></> }

    }
    render() {
        return (
            <div className="container">
                <div style={{ marginBottom: '0px' }} className="section-title text-center">
                    <h2>evenementen</h2>
                </div>
                {this.state && this.state.events && this.state.events.length > 0 ?
                    <ListGroup variant="flush">
                        <this.singleEvents />
                    </ListGroup> :
                    <div className="section-title text-center">
                        <p>Er zijn nog geen nieuwe evenementen bekend</p>
                        <Button onClick={() => this.props.history.push('/')}>terug naar de hoofdpagina</Button></div>}
            </div>
        )
    }
}
