import React, { Component } from 'react'

import { Carousel } from 'react-bootstrap'




export default class introCarousel extends Component {

    carousel_items = () => {
        return this.props.data ? this.props.data.images.map(function (d, i) {
           
            return <Carousel.Item key={i}>


                <img
                    style={{ height: '65vh', objectFit: 'cover' }}
                    className="d-block w-100"
                    src={d}
                    // src={`${process.env.REACT_APP_generalServer}build\\introCarousel\\${d}`}
                    alt="First slide"
                />

            </Carousel.Item>
            // <div  key={`${d.name}-${i}`} className="col-xs-6 col-sm-6 col-md-3 col-lg-3 team text-center">
            //   <div className="thumbnail"
            //   style={{cursor:"pointer"}}
            //   onClick={()=>this.props.history.push({pathname:'/leiders/',id:'#welpen'})}
            //   // href='/leiders/#welpen'
            //   >
            //     {" "}
            //     <img src={d.img} alt="..." className="team-img" />
            //     <div className="caption">
            //       <h4>{d.name}</h4>
            //       <p>{d.job}</p>
            //     </div>
            //   </div>
            // </div>
        }) : <div>test</div>


    }
    render() {
        return (
            <div  >

                <Carousel >
                    {this.carousel_items()}

                    {/* <Carousel.Item>


                        <img
                            style={{ height: '65vh', objectFit: 'cover' }}
                            className="d-block w-100"
                            src={`${process.env.REACT_APP_generalServer}build\\img\\events\\6e228239-8560-41f0-bf33-a73ee24adefd_1624189233775.jpeg`}
                            alt="First slide"
                        />
              
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            style={{ height: '65vh', objectFit: 'cover' }}
                            className="d-block w-100"
                            src={`${process.env.REACT_APP_generalServer}figures\\memberImages\\undefined_1623441441356.jpeg`}

                            alt="Second slide"
                        />

                        <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            style={{ height: '65vh', objectFit: 'cover' }}
                            className="d-block w-100"
                            src={`${process.env.REACT_APP_generalServer}figures\\memberImages\\undefined_1623441441356.jpeg`}
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                        </Carousel.Caption>
                    </Carousel.Item>*/}
                </Carousel>
                <div style={{ textAlign: 'center', paddingTop: '20px', background: '#f6f6f6' }}>
                    <h4 >Welkom bij</h4>
                    <h4 ><b>Sea-Scouts Depanne</b></h4>
                    <h4>15de FOS 't Kraaienest</h4>
                </div>


            </div>
        )
    }
}
