import React, { Component } from 'react'
import moment from 'moment'
import axios from 'axios'

import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Button,
  Alert,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faPlusSquare, faTimes } from '@fortawesome/free-solid-svg-icons'
import UnAuthorized from '../../Pages/UnAuthorized'
import DatePicker from '../../components/calendar/datePicker'


export default class addMember extends Component {
  constructor() {
    super()
    this.state = {
      inputs: {
        first_name: '',
        last_name: '',
        totemnaam: '',
        voortotem: '',
        functions: [],
        verification_function: 'Lid',
        tak: 'Welpen',
        birth_date: '',
        public_contactdetails: false,
      },
      showFunctionOptions: false,
      errorMessage: '',
      showErrorMessage: false,
      listOpen: true,
      switchLabel: <span>E-mailadres en telefoonnummer <b>niet</b> weergeven op de leiderspagina</span>,
      headerTitle: 'Roles',
      roles: [
        { title: 'Standard', selected: false },
        { title: 'Takleider', selected: false },
        { title: 'Zeehonden Leiding', selected: false },
        { title: 'Welpen Leiding', selected: false },
        { title: 'Aspiranten Leiding', selected: false },
        { title: 'Junior Leiding', selected: false },
        { title: 'Senior Leiding', selected: false },
        // { title: 'lid', selected: false },
        // { title: 'kensis lid', selected: false },
        // { title: 'kensis lid', selected: false },
        // { title: 'ouder/voogd lid', selected: false },
        { title: 'EenheidsLeider', selected: false },
        { title: 'Asistent EenheidsLeider', selected: false },
        { title: 'VZW', selected: false },
        { title: 'Fos Shop', selected: false },
        { title: 'Admin', selected: false },
      ],
      validated: false,
      userTypes: [
        'Lid',
        'Leiding',
        'Ouder/Voogd van lid',
        'Familie/Vrienden van lid',
        'Stam',
        'Eenheidsleiding',
        'VZW',
        'Ander',
      ],
    }
  }

  componentDidMount = () => {
    const self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_authServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        self.setState({ authorized: true }, () => self.getMemberData())
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })
  }

  getMemberData = async () => {
    const self = this
    if (this.props.match.params.id) {
      axios
        .get(`${process.env.REACT_APP_authServer}api/members/id/${this.props.match.params.id}`, {
          withCredentials: true,
        })
        .then((response) => {

          let data = response.data
          let roles = self.state.roles
          data.functions = typeof JSON.parse(data.functions) === 'string' ? [JSON.parse(data.functions)] : JSON.parse(data.functions)
          data.birth_date = moment(data.birth_date)
          data.public_contactdetails = data.public_contactdetails === 1 ? true : false
          for (var i = 0; i < data.functions.length; i++) {
            for (var k in roles) {
              if (roles[k].title === data.functions[i]) {
                roles[k].selected = true;
                break; //Stop this loop, we found it!
              }
            }


          }


          self.setState({ inputs: data })

        })
        .catch(function (error) {
          if (error.response && error.response.status === 401) {
            self.setState({ authorized: false })
          }
          console.log(error)
        })
    }
  }
  updateRoles = (i) => {
    let roles = { ...this.state.roles }
    roles[i].selected = !roles[i].selected
    if (roles[i].selected) {
      this.state.inputs.functions.push(roles[i].title)
    } else {
      this.state.inputs.functions.splice(
        this.state.inputs.functions.indexOf(roles[i].title),
        1
      )
    }
    this.setState({})
  }

  renderDropdownRoles = () => {
    return this.state.roles.map(function (role, i) {
      return (
        <div>
          <Dropdown.ItemText
            key={`item-${i}`}
            style={{
              whiteSpace: 'nowrap',
              paddingRight: '5px',
              paddingLeft: '5px',
              paddingTop: '2px',
              paddingBottom: '2px',
            }}
          >
            <input
              checked={role.selected}
              className="margin-checkbox"
              name="test"
              type="checkbox"
              key={`role-${i}`}
              onChange={() => {
                this.updateRoles(i)
              }}
            />
            {role.title}
          </Dropdown.ItemText>
        </div>
      )
    }, this)
  }

  changeHandeler = (e) => {
    const target = e.target.name
    const inputs = { ...this.state.inputs }
    inputs[target] = e.target.value
    this.setState({ inputs, showErrorMessage: false })
  }

  addMemberToDb = () => {
    const  self = this

    const birth_date = this.state.inputs.birth_date.format('DD-MM-YYYY')
    let fd = new FormData()
    fd.append('first_name', this.state.inputs.first_name)
    fd.append('last_name', this.state.inputs.last_name)
    fd.append('totemnaam', this.state.inputs.totemnaam)
    fd.append(`voortotem`, this.state.inputs.voortotem)
    fd.append('email', this.state.inputs.email)
    fd.append('phonenumber', this.state.inputs.phonenumber)
    fd.append(`verification_function`, this.state.inputs.verification_function)
    fd.append('tak', this.state.inputs.tak)
    fd.append('birth_date', birth_date)
    if (this.state.inputs.public_contactdetails === true) {
      fd.append('public_contactDetails', this.state.inputs.public_contactdetails)
    }
    for (let i = 0; i < this.state.inputs.functions.length; i++) {
      fd.append(`functions`, this.state.inputs.functions[i])
    }

    if (this.state.currentFigure) {
      fd.append(`image`, this.state.currentFigure)
    }
    else {

      fd.append(`image`, this.state.inputs.image)
    }


    if (this.props.match.params.id) {
      axios
        .post(`${process.env.REACT_APP_authServer}api/members/update/${this.props.match.params.id}`, fd, {
          withCredentials: true,
        })
        .then((response) => {
          this.props.history.push('/admin/overview/leden')
        })
        .catch(function (error) {
          console.log(error)
          console.log(error.response.status)
          let errorMessage = self.state.errorMessage
          let showErrorMessage = false
          if (error.response.status === 409) {
            showErrorMessage = true
            errorMessage = 'Er bestaat al een lid met dit email adres. wijzig het email adres en probeer opnieuw!'
              }
              self.setState({ errorMessage, showErrorMessage })
        })


    }
    else {

      axios
        .post(`${process.env.REACT_APP_authServer}api/members/add`, fd, {
          withCredentials: true,
        })
        .then((response) => {
          this.props.history.push('/admin/overview/leden')
        })
        .catch(function (error) {
         
          console.log(error)
          console.log(error.response.status)
          let errorMessage = self.state.errorMessage
          let showErrorMessage = false
          if (error.response.status === 409) {
            showErrorMessage = true
            errorMessage = 'Er bestaat al een lid met dit email adres. wijzig het email adres en probeer opnieuw!'
              }
              self.setState({ errorMessage, showErrorMessage })
        })
    }
  }

  submitHandler = () => {
    let errorMessage = this.state.errorMessage
    let showErrorMessage = true
    if (this.state.inputs.first_name === '') {
      errorMessage = 'De voornaam moet ingevuld zijn!'
    } else if (this.state.inputs.last_name === '') {
      errorMessage = 'De achternaam moet ingevuld zijn!'
    } else if (this.state.inputs.functions.length === 0) {
      errorMessage = 'je moet minstens één functie opgeven!'
    } else if (this.state.inputs.verification_function === '') {
      errorMessage = 'je moet minstens een verificatie functie opgeven!'
    } else if (this.state.inputs.tak === '') {
      errorMessage = 'je moet een tak opgeven!'
    } else if (this.state.inputs.birth_date === '') {
      errorMessage =
        'Je moet de geboortedatum selecteren'
    } else {
      errorMessage = ''
      showErrorMessage = false
    }
    this.setState({ errorMessage, showErrorMessage }, () => {
      if (!showErrorMessage) {
        this.addMemberToDb()
      }
    })
  }


  setBirthdate = (date) => {
    let inputs = this.state.inputs
    inputs.birth_date = date
    this.setState({ inputs })
  }

  ImgPreview = () => {
    return this.state && (this.state.currentFigureURL || this.state.inputs.image) ?
      <div id='imgPreview'>
        <div className='header'>
          <p>Preview</p>
          <div onClick={() => { let inputs = this.state.inputs; inputs.image = null; this.setState({ currentFigure: null, currentFigureURL: null, inputs }) }}>
            <FontAwesomeIcon icon={faTimes} className='fa' color='white' /></div>
        </div>
        <div className='body'>
          {this.state.currentFigureURL ?
            <img src={this.state.currentFigureURL} alt='' /> :
            <img src={`${process.env.REACT_APP_generalServer}${this.state.inputs.image}`} alt='' />}
        </div></div> : <></>
  }


  changeValidationFunction = (e) => {
    let inputs = { ...this.state.inputs }
    inputs.verification_function = e.target.value
    this.setState({ inputs })
  }

  selectUserType = () => {
    return this.state.userTypes.map(function (type, i) {
      return <option key={i}>{type}</option>
    })
  }

  showErrorMessage = () => {
    return this.state.showErrorMessage ? (
      <Alert variant="danger">
        <p>{this.state.errorMessage}</p>
      </Alert>
    ) : (
      <div />
    )
  }

  changeFileHandeler = (e) => {
    var file = e.target.files
    if (e.target.files.length > 0) {
      var url = window.URL.createObjectURL(file[0])
      this.setState({ currentFigure: file[0], currentFigureURL: url, })
    }
    //this wil reset the current value of the form file. 
    // meaning that one image can be added twice ore more after eatch other
    e.target.value = null
  }
  changePrivateSetting = () => {
    let inputs = this.state.inputs
    let switchLabel = <span>E-mailadres en telefoonnummer <b>niet</b> weergeven op de leiderspagina</span>
    inputs.public_contactdetails = !inputs.public_contactdetails

    if (inputs.public_contactdetails === true) {
      switchLabel = <span>E-mailadres en telefoonnummer <b>wel</b> weergeven op de leiderspagina</span>
    }
    this.setState({ inputs, switchLabel })

  }

  render() {

    if (typeof this.state.authorized === 'undefined') {
      return <div />
    } else if (!this.state.authorized) {
      return <UnAuthorized />
    } else {
      return (
        <div className='container'>
          <Form>
            <Form.Group as={Row} controlId="id_error">
              <Form.Label column sm={2}></Form.Label>
              <Col sm={10}>{this.showErrorMessage()}</Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_firstname">
              <Form.Label column sm={2}>
                Voornaam*
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="first_name"
                  value={this.state.inputs.first_name}
                  type="text"
                  placeholder="Voornaam"
                  onChange={this.changeHandeler.bind(this)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_lastname">
              <Form.Label column sm={2}>
                Achernaam*
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="last_name"
                  type="text"
                  placeholder="Achernaam"
                  value={this.state.inputs.last_name}
                  onChange={this.changeHandeler.bind(this)}
                />
              </Col>
            </Form.Group>


            <Form.Group as={Row} controlId="id_firstname">
              <Form.Label column sm={2}>
                Voortotem
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="voortotem"
                  value={this.state.inputs.voortotem}
                  type="text"
                  placeholder="Voortotem"
                  onChange={this.changeHandeler.bind(this)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_lastname">
              <Form.Label column sm={2}>
                Totemnaam
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="totemnaam"
                  type="text"
                  placeholder="Totemnaam"
                  value={this.state.inputs.totemnaam}
                  onChange={this.changeHandeler.bind(this)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="id_email">
              <Form.Label column sm={2}>
                E-mailadres
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="email"
                  type="text"
                  placeholder="E-mailadres"
                  value={this.state.inputs.email}
                  onChange={this.changeHandeler.bind(this)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="id_phonenumber">
              <Form.Label column sm={2}>
                Telefoonnummer*
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="phonenumber"
                  type="number"
                  placeholder="Telefoonnummer"
                  value={this.state.inputs.phonenumber}
                  onChange={this.changeHandeler.bind(this)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_functien">
              <Form.Label column sm={2}>
                Functie*
              </Form.Label>
              <Col sm={10}>
                <InputGroup className="mb-3">
                  <FormControl
                    name="function"
                    placeholder="Selecteer functies"
                    value={this.state.inputs.functions ? this.state.inputs.functions.join(', ') : ''}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    readOnly={true}
                  />
                  <InputGroup.Append>
                    <div>
                      <DropdownButton
                        onClick={() => {
                          this.setState({ showErrorMessage: false })
                        }}
                        title={
                          <span >
                            <FontAwesomeIcon
                              className="fa"
                              icon={faPlusSquare}
                            />
                            Voeg toe
                          </span>
                        }
                        id="dropdown-menu-align-right"
                      >
                        {this.renderDropdownRoles()}
                      </DropdownButton>
                    </div>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>





            {/* <Form.Group as={Row} controlId="id_ferificationFunction">
              <Form.Label column sm={2}>
                Verificatie functie*
              </Form.Label>
              <Col sm={10}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    as="select"
                    value={this.state.inputs.verification_function}
                    onChange={this.changeValidationFunction}
                  >
                    {this.selectUserType()}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Group> */}

            <Form.Group as={Row} controlId="id_tak">
              <Form.Label column sm={2}>
                Tak*
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  name="tak"
                  value={this.state.inputs.tak}
                  onChange={this.changeHandeler.bind(this)}
                >
                  <option >Zeehonden</option>
                  <option >Welpen</option>
                  <option >Aspiranten</option>
                  <option >Juniors</option>
                  <option >Seniors</option>
                  <option >Verkenners</option>
                  <option >Eenheid</option>
                  <option >Stam</option>
                  <option >VZW</option>
                </Form.Control>
              </Col>
            </Form.Group>
            {//['Leiding', 'Stam', 'Eenheidsleiding'].includes(this.state.inputs.verification_function)
            true ?
              <Form.Group as={Row} controlId="id_switchpublic">
                <Form.Label column sm={2}>
                  Weergave pesoonlijke gegevens
                </Form.Label>
                <Col className='align-self-center' sm={10}>
                  <Form.Check
                    // style={{cursor:"pointer"}}
                    checked={this.state.inputs.public_contactdetails}
                    type="switch"
                    id="custom-switch"
                    onChange={this.changePrivateSetting}
                    label={this.state.switchLabel}
                  />
                </Col>

              </Form.Group> : <></>}

            <Form.Group as={Row} controlId="id_birthdate">
              <Form.Label column sm={2}>
                Geboorte datum*
              </Form.Label>
              <Col sm={6} lg={4}>
                <DatePicker date={moment(this.state.inputs['birth_date'])}
                  birthday={true}
                  selectedDate={(date) => this.setBirthdate(date)} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}
              className="title-margin"
              controlId="formDetails" >
              <Form.Label column xs={3} md={2}>Afbeelding*</Form.Label>
              <Col xs={9} md={5}>
                <Form.File
                  id="custom-file"
                  label='Voeg afbeelding toe'
                  name="fileName"

                  data-browse="Afbeelding kiezen"
                  onChange={this.changeFileHandeler.bind(this)}
                  custom
                />

              </Col>
              <Col xs={{ span: 9, offset: 3 }} md={{ span: 5, offset: 0 }}>
                <this.ImgPreview />
              </Col>


            </Form.Group>

            <Form.Group as={Row} controlId="id_birthdate">
              <Form.Label column sm={2}></Form.Label>
              <Col >



                <Button style={{ whiteSpace: 'nowrap' }} onClick={this.submitHandler.bind(this)}>
                  <FontAwesomeIcon className="fa" icon={faUserPlus} />
                  {this.props.match.params.id ? "Lid bijwerken" : "Lid Toevoegen"}
                </Button>
              </Col>

            </Form.Group>

          </Form>
        </div>
      )
    }
  }
}
