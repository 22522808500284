
import React, { Component } from 'react'
// import { Form, Col, Button, InputGroup, FormControl, Alert, Modal } from "react-bootstrap"
// import moment, { isMoment } from 'moment'
// import DatePicker from '../../components/calendar/datePicker'
// import axios from 'axios'
import Recaptcha from 'react-google-invisible-recaptcha'
// import Recaptcha from 'react-google-recaptcha'



// const fields = ["firstname", "lastname", "email", "phonenumber", "gender", "birthday", "street", "number",
//     "postalcode", "state"]
// const fields_dutch = ["voornaam", "achternaam", "E-mailadres", "telefoonnummer", "geslacht", "geboortedatum", "straatnaam",
//     "huisnummer", "postcode", "gemeente"]

// const reff = React.createRef();

export default class reCAPTCHA extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // showModal:false,
            // toggle: { 1: true, 2: true },
            // // switch2:false,
            // 'birthday_lid': '',
            // alertMessage: null
        }
    }

    recaptchaExecute = (e) => {
        this.recaptcha.execute()
    }

    recaptchaReset= (e) => {
        // if (window.grecaptcha){
        //     window.grecaptcha.reset()
        //     console.log('reCAPTCHA is reset')
        //     // window.location.reload(false);
        //   }
        //   console.log('reCAPTCHA is reset')
        this.recaptcha.reset()
        
    }


    
    onResolved = () => {
        this.props.onResolved()
      }

   

    // verifyCallback = async (response) => {
    //     console.log('test')
    //     if (response) {
    //         const data = {
    //             token: response,
    //         }
    //          const verifyStatus = await fetch('app', {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             redirect: 'manual',
    //             body: JSON.stringify(data)
    //         })
    //         if(verifyStatus) {
    //             this.recaptcha.remove()
    //             return this.onResolved(true) 
    //         }
    //         else return //reject('Invalid captcha verification.') 
    //         return this.onResolved() // We need to resolve promise anyway
    //     } else {
    //         window.grecaptcha.reset()
    //         return //reject('Invalid captcha verification.')
    //     }
    // }

    
    

   
    render() {
      
        
        return (
                <Recaptcha style ={{ visibility: 'visisble'}}//hidden
                id="example3"
                    ref={ref => this.recaptcha = ref}
                    // ref={reff}
                    sitekey={process.env.REACT_APP_capcha_sitekey}
                    locale='nl'
                    size='invisible'

                    onResolved={this.onResolved} 
                    // onLoaded={this.onLoaded }
                    onError={(err)=>console.log('recapcha error', err)}
                    onExpired = {()=>console.log('recaptha expired')}
                    // data-expired-callback  = {()=>console.log('recaptha expired2')}
                    // callback = {this.verifyCallback}

                    /> 
        )
    }
}
