import React, { Component } from 'react'
import "./main.css"

export default class cookiePolicyPage extends Component {


  render() {
    return <div id="container"><h2>cookiebeleid</h2>
    <p>
    <i>dit cookiebeleid werd het laatst bijgewerkt op 19 juli 2023.</i></p>


        <h4>Introductie</h4>
        <p>Onze website, <a href={ process.env.REACT_APP_Client_URL}>{ process.env.REACT_APP_Client_URL.split("//").pop()}</a> (hierna: "de website") maakt gebruik van cookies en andere gerelateerde technologieën 
            (voor het gemak worden alle technologieën "cookies" genoemd). 
            Cookies worden ook geplaatst door derden die wij hebben ingeschakeld (indien van toepassing). 
            In het onderstaande document informeren wij u over het gebruik van cookies op onze website.</p>



        <h4>Wat zijn cookies</h4>
        <p>Een cookie is een eenvoudig klein bestandje dat met pagina's van deze website wordt meegestuurd en door uw browser op de harde
             schrijf van uw computer of een ander apparaat wordt opgeslagen. De daarin opgeslagen informatie kan bij een volgend bezoek worden
              teruggestuurd naar onze servers of naar de servers van de betreffende derden.</p> 


        <h4>Wat zijn scripts</h4>
        <p>Een script is een stukje programmacode dat wordt gebruikt om onze website goed en interactief te laten functioneren. 
            Deze code wordt uitgevoerd op onze server of op uw apparaat.
            </p>

        <h4>wat is een web beacon</h4>
        <p>Een web beacon (of een pixel tag) is een klein, onzichtbaar stukje tekst of afbeelding
             op een website dat wordt gebruikt om het verkeer op een website te monitoren. Om dit te kunnen doen, worden verschillende 
             gegevens over u opgeslagen met behulp van webbakens.</p>


        <h4>Cookies</h4>
        <h6> Technische of functionale cookies</h6>
        <p>Sommige cookies zorgen ervoor dat bepaalde onderdelen van de website goed werken en dat uw gebruikersvoorkeuren bekend blijven. 
            Door functionele cookies te plaatsen, maken we het bezoek aan onze website voor u gemakkelijker. Zo hoef je bij een bezoek aan onze 
            website niet herhaaldelijk dezelfde informatie in te voeren en blijven bijvoorbeeld de artikelen in je winkelwagen totdat je hebt betaald.
             Wij kunnen deze cookies zonder uw toestemming plaatsen.</p>
        <h6> Statistiche cookies</h6>
        <p>Statistische cookies kunnen gebruikt worden om de website-ervaring voor onze gebruikers te optimaliseren.
             Met deze statistische cookies krijgen we inzicht in het gebruik van onze website. 
             Toestemming voor het plaatsen van statistische cookies moet steeds gevraagd worden. (Deze website hebruikt geen statistische cookies).</p>
        <h6> Marketing/traceer cookies</h6>
        <p>Marketing/traceercookies zijn cookies of andere vormen van lokale opslag die worden gebruikt om gebruikersprofielen aan te maken om advertenties
             weer te geven of om de gebruiker op deze website of op verschillende websites te volgen voor vergelijkbare marketingdoeleinden.
             (Ook dit type cookies worden niet gebruikt op deze website).</p>

        <h4>toestemmingsinstellingen beheren</h4>
        <p>Wanneer je onze website voor de eerste keer bezoekt, tonen we je een pop-up om u te informeren dat deze website gebruik maakt van cookies. 
            Zodra je op "Ik begijp het" klikt, zal de pup-up verdwijnen.
            Deze website maakt enkel gebruik van functionlele cookies waarvoor geen toesteming van de gebruiker vereist is,
            zoals beschreven in dit cookiebeleid. U kunt het gebruik van cookies uitschakelen via uw browser, 
            maar houd er rekening mee dat onze website dan mogelijk niet meer naar behoren werkt.</p>
        
      
        
        <h4>(De)activeren en verweideren van cookies</h4>
         <p>U kunt uw internetbrowser gebruiken om cookies automatisch of handmatig te verwijderen. U kunt ook aangeven dat bepaalde 
            cookies niet geplaatst mogen worden. Een andere optie is om de instellingen van uw internetbrowser te wijzigen zodat 
            u een melding krijgt telkens wanneer er een cookie wordt geplaatst. Raadpleeg voor meer informatie over deze opties 
            de instructies in de Help-sectie van uw browser.<br/>Houd er rekening mee dat onze website mogelijk niet goed werkt 
            als alle cookies zijn uitgeschakeld. Als u de cookies in uw browser verwijdert, 
            worden ze na uw toestemming opnieuw geplaatst wanneer u onze website opnieuw bezoekt.</p> 



        <h4>Uw rechten met betrekking tot persoonlijke gegevens</h4>
        U hebt de volgende rechten met betrekking tot uw persoonlijke gegevens:
        <ol>
        <li><p>Je hebt het recht om te weten waarom je persoonlijke gegevens nodig zijn, wat ermee zal gebeuren en hoe lang ze zullen worden bewaard.</p></li>
        <li><p>Recht op toegang: Je hebt het recht op toegang tot je persoonsgegevens die bij ons bekend zijn.</p></li>
        <li><p>Recht op rectificatie: u heeft het recht om uw persoonsgegevens aan te vullen, te corrigeren, te laten verwijderen of af te schermen wanneer u dat wenst.</p></li>
        <li><p>Als u ons toestemming geeft om uw gegevens te verwerken, hebt u het recht om die toestemming in te trekken en uw persoonsgegevens te laten verwijderen.</p></li>
        <li><p>Recht op overdracht van uw gegevens: u hebt het recht om al uw persoonsgegevens op te vragen bij de verwerkingsverantwoordelijke en deze in zijn geheel over te dragen aan een andere verwerkingsverantwoordelijke.</p></li>
        <li><p>Recht op bezwaar: u kunt bezwaar maken tegen de verwerking van uw gegevens. Wij voldoen hieraan, tenzij er gerechtvaardigde redenen zijn voor de verwerking.</p></li>
        </ol>
        <p>Om deze rechten uit te oefenen, kunt u onze <a href='/privacy-verklaring'>privacyverklaring</a>  raadplegen contact met ons opnemen. Zie de contactgegevens onderaan dit Cookiebeleid. Als u een klacht heeft over hoe wij met uw gegevens omgaan, dan horen wij dat graag van u, maar u heeft ook het recht om een klacht in te dienen bij de toezichthoudende autoriteit (de Autoriteit Persoonsgegevens).</p>
      

    	<h4>Contacteer ons</h4>
        <p>Indien u bijkomende vragen hebt over de Cookie Policie, kunt u ons 
            contacteren via het email, telefonisch  of per post:</p>
            <ol>
                <li>scoutsdepanne@gmail.com</li>
                <li>+32 487 37 11 00</li>
                <li>sportlaan 10, 8660 De panne</li>
                
            </ol>
            


    
    </div>
  }
}
