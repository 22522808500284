import React, { Component } from 'react'
import moment, { isMoment } from 'moment'
import 'moment/locale/nl-be'
import axios from 'axios'
import Legend from './calenderLegend'

import { OverlayTrigger, Button, Tooltip, Form, Alert } from 'react-bootstrap'
import './calendarActiviteieten.css'

export default class CustomCalender extends Component {
  state = {
    dateContext: this.props.onlySummerPeriod ? moment().set('month', 6) : moment(),
    today: moment().startOf('day'),
    selectedDay: null,
    selectedDateRange: [],
    errorMessage: '',
    minYear: this.props.birthday ? moment().year() - 100 : moment().year(),
    maxYear: this.props.birthday ? moment().year() : moment().year() + 9,
    reservations: {
      reservedDays: [],
      reservedGroup: [],
      begins: [],
      ends: [],
    },
  }

  constructor(props) {
    super(props)
    this.width = props.width || '350px'
    this.style = props.style || {}

    this.showErrorMesage =
      props.showErrorMesage && props.showErrorMesage === true
        ? props.showErrorMesage
        : false

    this.style.width = this.width // add this
    this.selectRange =
      props.selectRange && props.selectRange === true
        ? props.selectRange
        : false
  }

  componentDidMount() {
    this.getReservationDates(10, 2020)
  }

  getReservationDates(a, b) {
    if (this.props.showReservations) {
      let month = this.state.dateContext.get('month')
      let year = this.state.dateContext.get('year')
      axios.all([axios
        .get(
          `${process.env.REACT_APP_generalServer}api/rentingReservations/daysInMonth/${month}/${year}`),
      axios
        .post(
          `${process.env.REACT_APP_generalServer}api/rentingReservations/reservations/${month}/${year}`,
          { begindate: (isMoment(this.state.selectedDateRange[0]) ? this.state.selectedDateRange[0] : null) }
        )])
        .then((response) => {
          if (response.length === 2) {
            let reservationEndDates = []
            for (let i = 0; i < response[1].data.length; i++) {
              reservationEndDates.push(moment(response[1].data[i]))
            }
            this.setState({ reservations: response[0].data, reservationEndDates })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  weekdays = moment.weekdays(true) //["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]
  weekdaysShort = moment.weekdaysShort(true) // ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  months = this.props.onlySummerPeriod ? moment.months().slice(6, 8) : moment.months()

  year = () => {
    return this.state.dateContext.format('Y')
  }
  month = () => {
    return this.state.dateContext.format('MMMM')
  }
  daysInMonth = () => {
    return this.state.dateContext.daysInMonth()
  }
  currentDate = () => {
    return this.state.dateContext.get('date')
  }
  currentDay = () => {
    return this.state.today.format('MM/DD/YYYY')
  }
  selectedDay = () => {
    return this.state.selectedDay && this.state.selectedDay.format('MM/DD/YYYY')
  }
  selectedDateRange = (i, format) => {
    if (format === 'date') {
      return (
        this.state.selectedDateRange[i] &&
        this.state.selectedDateRange[i].format('MM/DD/YYYY')
      )
    } else {
      return (
        this.state.selectedDateRange[i] &&
        this.state.selectedDateRange[i].get('date')
      )
    }
  }

  firstDayOfMonth = () => {
    let dateContext = this.state.dateContext
    let firstDay = moment(dateContext).startOf('month').format('d') - 1 // Day of week 0...1..5...6
    return firstDay
  }

  setMonth = (month) => {
    let monthNo = this.months.indexOf(month) //+onlySummerFlag*6
    let dateContext = Object.assign({}, this.state.dateContext)
    dateContext = moment(dateContext).set('month', monthNo)
    this.setState(
      {
        dateContext: dateContext,
      },
      () => {
        this.getReservationDates(10, 2020)
        // this.getReservationDates2()
      }
    )
  }
  SelectionOverlapsReservation = (day) => {
    day = this.state.dateContext.set('date', day)
    let reservationEndDates = []
    for (let i = 0; i < this.state.reservationEndDates.length; i++) {
      if (this.state.selectedDateRange[0] < this.state.reservationEndDates[i] && this.state.reservationEndDates[i] < day) {
        reservationEndDates.push(this.state.reservationEndDates[i]);
      }
    }
    return reservationEndDates.length !== 0
  }

  nextMonth = () => {
    let dateContext = Object.assign({}, this.state.dateContext)
    if (this.props.onlySummerPeriod && moment(dateContext).month() === 7) {
      dateContext = moment(dateContext).add(11, 'month')

    }
    else {
      dateContext = moment(dateContext).add(1, 'month')
    }
    if (dateContext.year() <= this.state.maxYear) {
      this.setState(
        {
          dateContext: dateContext,
          errorMessage: '',
          reservations: {
            reservedDays: [],
            reservedGroup: [],
            begins: [],
            ends: [],
          },
        },
        () => {
          this.getReservationDates(10, 2020)
        }
      )
    } else {
      this.setState({
        errorMessage: `het is hellaas niet mogelijk om het kalenderjaar ${this.state.maxYear + 1
          } en volgende weer te geven.`,
      })
    }
    this.props.onNextMonth && this.props.onNextMonth()
  }

  prevMonth = () => {
    let dateContext = Object.assign({}, this.state.dateContext)
    if (this.props.onlySummerPeriod && moment(dateContext).month() === 6) {
      dateContext = moment(dateContext).subtract(11, 'month')

    }
    else {
      dateContext = moment(dateContext).subtract(1, 'month')
    }
    if (dateContext.year() >= this.state.minYear) {
      this.setState(
        {
          dateContext: dateContext,
          errorMessage: '',
          reservations: {
            reservedDays: [],
            reservedGroup: [],
            begins: [],
            ends: [],
          },
        },
        () => {
          this.getReservationDates(10, 2020)
        }
      )
    } else {
      this.setState({
        errorMessage: `het is hellaas niet mogelijk om het kalenderjaar ${this.state.minYear - 1
          } en eerder weer te geven.`,
      })
    }
    this.props.onPrevMonth && this.props.onPrevMonth()
  }

  monthList = () => {
    let monthList = this.months.map((month) => {
      return (
        <option value={month} key={month}>
          {month}
        </option>
      )
    })
    return monthList
  }

  yearList = () => {
    let years = []
    if (!this.props.birthday) {
      for (
        let i = this.state.today.year();
        i < this.state.today.year() + 10;
        i++
      ) {
        years.push(i)
      }
    }
    else {
      for (
        let i = this.state.today.year() - 100;
        i < this.state.today.year() + 1;
        i++
      ) {
        years.push(i)
      }

    }

    let yearList = years.map((year) => {
      return (
        <option value={year} key={`year-${year}`}>
          {year}
        </option>
      )
    })
    return yearList
  }

  MonthNav = () => {
    const onlySummerPeriod = this.props.onlySummerPeriod && this.props.onlySummerPeriod ? 1 : 0
    return (
      <div>
        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
          <Form.Control
            name="monthInput"
            onChange={this.changeHandeler}
            as="select"
            size="sm"
            custom
            value={this.months[moment(this.state.dateContext).month() - 6 * onlySummerPeriod]}
          >
            {this.monthList()}
          </Form.Control>
        </Form.Group>
      </div>
    )
  }

  setYear = (year) => {
    let dateContext = Object.assign({}, this.state.dateContext)
    dateContext = moment(dateContext).set('year', year)
    this.setState(
      {
        dateContext: dateContext,
      },
      () => {
        this.getReservationDates(10, 2020)
      }
    )
  }


  YearNav = () => {
    return (
      <div>
        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
          <Form.Control
            name="yearInput"
            onChange={this.changeHandeler}
            as="select"
            size="sm"
            custom
            value={this.state.dateContext.year()}
          >
            {this.yearList()}
          </Form.Control>
        </Form.Group>
      </div>
    )
  }

  onDayClick = (e, day) => {
    let selectedDay = Object.assign({}, this.state.dateContext)
    selectedDay = moment(selectedDay).set('date', day).startOf('day')
    this.setState({
      selectedDay: selectedDay,
    })
    let errorMessage
    if (this.props.selectDateAllowed) {
      console.log('calendar log', selectedDay, this.state.today, this.props.birthday, selectedDay > this.state.today && this.props.birthday)
      if (selectedDay > this.state.today && this.props.birthday) {
        errorMessage =
          'U mag geen datum in de toekomst selecteren'
      }
      else if (this.state.reservations.reservedDays.includes(day) && !this.state.reservations.ends.includes(day) && this.state.selectedDateRange.length !== 1 && this.state.reservations.begins.includes(day)) {
        errorMessage = 'Deze dag is reeds vanaf 12u geboekt'
      }
      else if ((this.state.reservations.reservedDays.includes(day) && !this.state.reservations.ends.includes(day) && this.state.selectedDateRange.length !== 1) ||
        (this.state.reservations.reservedDays.includes(day) && this.state.reservations.begins.includes(day) && this.state.reservations.ends.includes(day) && this.state.selectedDateRange.length !== 1)) {
        errorMessage = 'U kunt geen dagen selecteren die reeds geboekt zijn'
      } else if (selectedDay < this.state.today && !this.props.birthday) {
        errorMessage =
          'Het is niet mogelijk om een datum te selecteren in het verleden.'
      } else if (
        this.props.selectRange && selectedDay <= this.state.selectedDateRange[0] && this.state.selectedDateRange.length % 2 !== 0 &&
        this.state.errorMessage === ''
      ) {
        errorMessage = 'Je eind datum na je begin datum vallen'
      } else if (
        this.props.selectRange && selectedDay >= this.state.selectedDateRange[0] &&
        this.state.selectedDateRange.length !== 2 &&
        this.SelectionOverlapsReservation(day)
      ) {
        errorMessage =
          'Binnen de periode die je probeerd te reserveren is er reeds een andere boeking'
      } else {
        errorMessage = ''
      }
      console.log('error message', errorMessage)
      this.setState({
        errorMessage: errorMessage,
      })
    }

    /*if seleected dat is NOT within reservedDates list and
     selected daterange is empty (initial) or equal to length2 and de stecond selected 
     date is smaller or equal to the first one  then a new first date is defined.
     to add second date we check if there is an overlap with already reserved dates
    */
    //  ||
    //         (selectedDay >= this.state.selectedDateRange[0] &&
    //           this.SelectionOverlapsReservation(day))
    if (
      (!this.props.selectRange && !this.state.reservations.reservedDays.includes(day) && selectedDay >= this.state.today) ||
      (!this.props.selectRange && this.state.reservations.reservedDays.includes(day) && selectedDay >= this.state.today && this.state.reservations.ends.includes(day) && !this.state.reservations.begins.includes(day)) ||
      (!this.state.reservations.reservedDays.includes(day) &&
        (selectedDay >= this.state.today || this.props.birthday) &&
        (this.state.selectedDateRange.length % 2 === 0
        ))
    ) {
      this.setState(
        {
          selectedDateRange: [selectedDay],
        },
        () => {
          this.props.selectedDates(this.state.selectedDateRange[0], '')
        }
      )
    } else if (
      this.props.selectRange &&
      (!this.state.reservations.reservedDays.includes(day) ||
        (this.state.reservations.reservedDays.includes(day) && this.state.reservations.begins.includes(day) && !this.state.reservations.ends.includes(day))) &&
      selectedDay >= this.state.selectedDateRange[0] &&
      selectedDay > this.state.today &&
      !this.SelectionOverlapsReservation(day)
    ) {
      this.state.selectedDateRange.push(selectedDay)
      this.props.selectedDates(
        this.state.selectedDateRange[0],
        this.state.selectedDateRange[1]
      )
    } else {
      this.setState(
        {
          selectedDateRange: [], selectedDay: null
        },
        this.props.selectedDates('', '')
      )
    }
    this.props.onDayClick && this.props.onDayClick(e, selectedDay)
  }

  changeHandeler = (e) => {
    const target = e.target.value

    if (e.target.name === 'monthInput') {
      this.setMonth(target)
    } else {
      this.setYear(target)
    }
  }

  errorMessage = () => {
    return (
      <Alert
        variant="danger"
        onClose={() => this.setState({ errorMessage: '' })}
        dismissible
      >
        <p>{this.state.errorMessage}</p>
      </Alert>
    )
  }
  render() {

    let weekdays = this.weekdaysShort.map((day) => {
      return (
        <td key={day} className="week-day">
          {day}
        </td>
      )
    })
    

    let blanks = []
    for (let i = 0; i < (this.firstDayOfMonth()===-1?6:this.firstDayOfMonth()); i++) {
      blanks.push(
        <td key={i * 80} className="emptySlot">
          {''}
        </td>
      )
    }

    let daysInMonth = []
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let dateDay = Object.assign({}, this.state.dateContext)
      dateDay = moment(dateDay).set('date', d).startOf('day')
      let dateDayString = dateDay.format('MM/DD/YYYY')
      let selectedGroup = this.state.reservations.reservedGroup[
        this.state.reservations.reservedDays.indexOf(d)
      ]
      let className =
        dateDayString === this.currentDay()
          ? 'day current-day'
          : this.state.reservations.reservedDays.includes(d) && this.state.reservations.begins.includes(d) && !this.state.reservations.ends.includes(d)
            ? 'day begin-day'
            : this.state.reservations.reservedDays.includes(d) && this.state.reservations.ends.includes(d) && !this.state.reservations.begins.includes(d)
              ? 'day end-day'
              : this.state.reservations.reservedDays.includes(d)
                ? 'day reserved-day'
                : 'day'

      let selectedClass =
        this.props.selectDateAllowed &&
          ((dateDay.diff(this.state.selectedDateRange[0], 'day') >= 0 &&
            this.state.selectedDateRange.length === 2 &&
            dateDay.diff(this.state.selectedDateRange[1], 'day') <= 0) ||
            (dateDayString === this.selectedDay() && dateDay > this.state.today))
          ? ' selected-day '
          : ''

      this.state.reservations.reservedDays.includes(d) &&
        this.props.showReservationDetails
        ? daysInMonth.push(
          <OverlayTrigger
            key={`top-${d}`}
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-top`}>
                {`groep: ${selectedGroup}`}
                {this.state.showAllReservationDetails ? (
                  <Button variant="link">Details</Button>
                ) : (
                  <div></div>
                )}
              </Tooltip>
            }
          >
            <td key={d} className={className + selectedClass}>
              <span>{d}</span>
            </td>
          </OverlayTrigger>
        )
        : daysInMonth.push(
          <td key={d} className={className + selectedClass} onClick={(e) => {
            if (this.props.selectDateAllowed) {
              this.onDayClick(e, d)
            }
          }}>
            <span

            >
              {d}
            </span>
          </td>
        )
    }

    var totalSlots = [...blanks, ...daysInMonth]
    let rows = []
    let cells = []

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        let insertRow = cells.slice()
        rows.push(insertRow)
        cells = []
        cells.push(row)
      }
      if (i === totalSlots.length - 1) {
        let insertRow = cells.slice()
        rows.push(insertRow)
      }
    })
    let trElems = rows.map((d, i) => {
      return <tr key={i * 100}>{d}</tr>
    })
    return (
      <div className="calendar-main-container " >
        <div style={this.style}>
          <div>
            {this.showErrorMesage && this.state.errorMessage !== '' ? (
              this.errorMessage()
            ) : (
              <div />
            )}
            <div className="calendar-container" style={this.style}>
              <table className="calendar">
                <thead>
                  <tr className="calendar-header">
                    <td colSpan="5">
                      <Form.Row>
                        <this.MonthNav md="2" />
                        <this.YearNav md="2" />

                        {/* <SpinnerButton md="2" className="form-control" /> */}
                      </Form.Row>
                    </td>
                    <td colSpan="2" className="nav-month">
                      <i
                        className="prev fa fa-fw fa-chevron-left"
                        onClick={(e) => {
                          this.prevMonth()
                        }}
                      ></i>
                      <i
                        className="prev fa fa-fw fa-chevron-right"
                        onClick={(e) => {
                          this.nextMonth()
                        }}
                      ></i>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="weekday-header">{weekdays}</tr>
                  {trElems}
                </tbody>
              </table>
            </div>
          </div>
          <div></div>
        </div>
        {this.props.legend ?
          <Legend iconWidth="40px"
            iconHeight="25px"
            leftMargin="30px"
            legend={this.props.legend} /> : <></>}
      </div>
    )
  }
}