import React, { Component } from 'react'
import Vraag from '../verhuur/vraag'
import axios from "axios"
import UnAuthorized from '../../Pages/UnAuthorized'

export default class individualQuestion extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount = () => {
        this.getQuestion()
        let self = this
        //check if user has admin rights
        axios
            .get(`${process.env.REACT_APP_generalServer}api/members/check-roles`, {
                withCredentials: true,
            })
            .then((response) => {
                self.setState({ authorized: true })
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    self.setState({ authorized: false })
                }
                console.log(error)
            })

    }

    getQuestion = () => {
        axios.get(`${process.env.REACT_APP_generalServer}api/questions/question/${this.props.match.params.id}`,
            { withCredentials: true })
            .then((response) => {
                this.setState({ data: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    render() {
        if (!this.state.authorized) {
            return <UnAuthorized />
        } else {
            return (

                <div>
                    {this.state.data ?
                        <Vraag data={this.state.data}
                            props={this.props}
                            responseForm={true} /> : <></>}
                </div>
            )
        }
    }
}
