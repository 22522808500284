import React, { Component } from 'react'
import axios from "axios"
import { Button, Form } from "react-bootstrap"

import './verhuur.css'
import Troeven from "./troeven"
import Introduction from "./introduction"
import Carousels from "./accommodatieCarousels"
import Tables from "./tableFormInformation"
import Calender from '../../components/calendar/CustomCalenderActiviteiten'
import Modal from "../../components/doubleCheckModal/doubleCheckModal"

import ScrollToTop from "../../ScrollToTop"

export default class verhuur extends Component {
  constructor() {
    super();
    this.state = {
      content: {},
      componentFocus: null,
      editMode: false,
      showCalendar: false

    }
  }

  componentDidMount = () => {
    this.getPageData()
    this.getAccomodationCard()
    this.checkAuthentication()
  }

  checkAuthentication = () => {
    const self = this
    axios
      .get(
        `${process.env.REACT_APP_generalServer}api/pages/renting/check-editRentingpageRole`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.setState({ authenticated: response.data.editable })
      })
      .catch(function (error) {
        console.log('error', error)
        if (error.response.status === 401) {

          self.setState({ authenticated: false })
        }
      })

    this.getPageData()
    this.getAccomodationCard()
  }

  getPageData = () => {
    let self = this
    let content = { ...this.state.content }
    axios
      .get(`${process.env.REACT_APP_generalServer}api/pages/renting/`)
      .then((response) => {
        let data = response.data.data
        content.title = data.title
        content.subTitle = data.subTitle
        content.info = data.info
        content.itemTitle = data.itemTitle
        content.itemList = data.itemList
        content.cardTitle = data.cardTitle
        content.tables = data.tables
        self.setState({ content })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  getAccomodationCard = () => {
    let self = this
    let accommodatie = this.state.accommodatie
    axios
      .get(`${process.env.REACT_APP_generalServer}api/rentingPage/`)
      .then((response) => {
        if (response.data) {
          accommodatie = response.data
          self.setState({ accommodatie })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  updateContent = (e) => {
    let content = { ...this.state.content }
    let item = e.name.split("-");
    if (item[1]) {
      content[item[0]][item[1]] = e.value
    }
    else {
      content[item[0]] = e.value

    }
    this.setState({ content })
  }

  updateTables = (e) => {
    let content = { ...this.state.content }
    content.tables = e
    this.setState({ content })
  }

  addListItem = (e) => {
    let content = { ...this.state.content }
    content.itemList.push(e.value);
    this.setState({ content })
  }
  swapListItem = (e) => {
    let content = { ...this.state.content }
    var temporarilyItem = content.itemList[e.item + e.direction];
    content.itemList[e.item + e.direction] = content.itemList[e.item]
    content.itemList[e.item] = temporarilyItem
    this.setState({ content })
  }

  swapAcommodationCards = (e) => {
    axios.all([axios.post(`${process.env.REACT_APP_generalServer}api/rentingPage/updatePosition/${e.ids[0]}`,
      { position: e.position[1] },
      { withCredentials: true }),
    axios.post(`${process.env.REACT_APP_generalServer}api/rentingPage/updatePosition/${e.ids[1]}`,
      { position: e.position[0] },
      { withCredentials: true })])
      .then(() => {
        this.getAccomodationCard()
      })
      .catch(function (error) {
        console.log(error)
      })


  }
  removeListItem = (e) => {
    let content = { ...this.state.content }
    // removeElement(content.itemList, e);

    content.itemList.splice(e.value, 1)
    // content.itemList.push(e.value);
    this.setState({ content })
  }

  removeAccomodationCardItem = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_generalServer}api/rentingPage/card/${id}`, { withCredentials: true }
      )
      .then((response) => {
        this.getAccomodationCard()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  save = () => {
    let self = this
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/pages/updatePage/renting`,
        self.state.content,
        { withCredentials: true }
      )
      .then((response) => {
        this.setState({ editMode: false })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async saveCurrentCard(newCard) {


    let fd = new FormData()
    for (let i in newCard.images) {
      fd.append(`images`, newCard.images[i])
    }

    fd.append('title', newCard.title)
    fd.append('content', newCard.info)

    let self = this
    await axios
      .post(
        `${process.env.REACT_APP_generalServer}api/rentingPage/uploadCard`,
        fd,
        { withCredentials: true }
      )
      .then((response) => {
        self.getAccomodationCard()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  renderCsvError = () => {
    if (this.state.showCalendar) {
      return (
        <div >
          <p>Wij verhuren enkel in de maanden juli en augustus</p>
          <div className="d-flex justify-content-center">
            <Calender
              classname="ustify-content-md-center"
              style={{
                position: 'relative',
                // width: "100%"
                // marginBottom:"10px"
                // marginLeft: 'auto',
                // right: '0px',
                // float: 'left',
              }}
              onlySummerPeriod={true}
              selectDateAllowed={false}
              showReservations={true}
              showErrorMesage={false}
              selectRange={false}
              legend={[{ type: "today", text: "Vandaag" },
              { type: "reservated", text: "Bezet" },
              { type: "begin", text: "Bezet vanaf 12u" },
              { type: "end", text: "Bezet tot 12u" },
              ]}
              width="302px"
            />
          </div>

        </div>

      )
    } else { return "" }

  }

  render() {
    return (
      <ScrollToTop>
      <div className="container">
        <Modal size='lg' message={this.renderCsvError()}
          agreeAction={() => this.setState({ showCalendar: false })}
          closeAction={() => this.setState({ showCalendar: false })}
          onlyButtonTrue
          backdrop="false"
          header="Beschikbaarheid"
          buttonTrue="oke" />

        <div style={{ marginLeft: "10px", float: "right" }}>

          {/* TODO bekijk beschikbaarheid  */}
          {/* <Button style={{marginTop: "5px",marginBottom:"10px"}} 
                onClick={()=>this.setState({showCalendar:true})}>Bekijk Beschikbaarheid</Button> */}
          <div>
            {this.state.editMode ?
              <Button
                onClick={() => this.save()}
                variant="success"
              >Pagina bijwerken</Button> : <></>}
            {this.state.authenticated ?
              <Form >
                <Form.Check
                  type="switch"
                  checked={this.state.editMode}
                  onChange={() => this.setState({ editMode: !this.state.editMode })}
                  id="custom-switch"
                  label="Pagina Aanpassen" />
              </Form> : <></>}
          </div>
        </div>
        <Introduction
          editMode={this.state.editMode}
          title={this.state.content.title}
          introduction={this.state.content.info}
          updateAction={(element) => this.updateContent(element)} />
        <Troeven focused={this.state.componentFocus === "troeven"}
          editMode={this.state.editMode}
          items={this.state.content.itemList}
          itemTitle={this.state.content.itemTitle}
          updateAction={(element) => this.updateContent(element)}
          removeAction={(element) => this.removeListItem(element)}
          swapAction={(element) => this.swapListItem(element)}
          addAction={(element) => this.addListItem(element)} />
        <Carousels accommodatie={this.state.accommodatie}
          cardTitle={this.state.content.cardTitle}
          editMode={this.state.editMode}
          updateAction={(element) => this.updateContent(element)}
          addAction={(element) => this.saveCurrentCard(element)}
          swapAction={(element) => this.swapAcommodationCards(element)}
          removeAction={(element) => this.removeAccomodationCardItem(element)} />

        <Tables editMode={this.state.editMode}
          tableFormInformation={this.state.content.tables}
          updateTables={(tables) => this.updateTables(tables)} />
        <div style={{ paddingBottom: "15px" }}>
          <Button onClick={() => {
            this.props.history.push('/verhuur/reserveer/nieuw')
          }}
          > Reserveer nu
          </Button>{' '}
          <Button onClick={() => {
            this.props.history.push('/verhuur/vraag')
          }}
          >  Stel bijkomende vragen
          </Button>
        </div>
        {/* <VerhuurPagina props={this.props} /> */}

      </div>
      </ScrollToTop>
    )
  }
}
