import React, { Component } from 'react'
import { Button, Table, Form, Row, Col, InputGroup, FormControl, Alert, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus, faCalendarDay, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
// import Modal from '../../components/doubleCheckModal/doubleCheckModal'
import Calender from '../../components/calendar/CustomCalenderActiviteiten'
import moment, { isMoment } from 'moment'
import axios from 'axios'
import UnAuthorized from '../../Pages/UnAuthorized'

export default class openingHours extends Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef();
    this.state = {
      modalMessage: "",
      showCalendar: false,
      showModal: false,
      alertMessage: '',
      openings: [],
      inputs: {
        date: '',
        beginHour: 17,
        beginMinute: 0,
        endHour: 18,
        endMinute: 0,


      }
    }
  }
  componentDidMount = () => {

    let self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_generalServer}api/members/check-roles-shop`, {
        withCredentials: true,
      })
      .then((response) => {
        this.setState({ authorized: true },()=> this.getOpeningHours())
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })

  }

  save = () => {
    let body = {}
    const { date, beginMinute, beginHour, endHour, endMinute } = this.state.inputs
    body.from = moment(date).add(beginHour, 'hours').add(beginMinute, 'minutes')
    body.till = moment(date).add(endHour, 'hours').add(endMinute, 'minutes')


    axios
      .post(`${process.env.REACT_APP_generalServer}api/fosshopOpeningHours/add/`, body, { withCredentials: true })
      .then((response) => {
        const inputs = {
          date: '',
          beginHour: 17,
          beginMinute: 0,
          endHour: 18,
          endMinute: 0,


        }
        // this.getOpeningHours()
        this.setState({ inputs, alertMessage: '' }, () => this.getOpeningHours())
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  getOpeningHours = () => {
    axios
      .get(`${process.env.REACT_APP_generalServer}api/fosshopOpeningHours/`, { withCredentials: true })
      .then((response) => {
        this.setState({ openings: response.data })
        // self.getOrders()
      })
      .catch(function (error) {
        console.log(error)
      })

  }
  deleteOpening = (id) => {
    axios
      .delete(`${process.env.REACT_APP_generalServer}api/fosshopOpeningHours/${id}`, { withCredentials: true })
      .then((response) => {
        this.getOpeningHours()
      })
      .catch(function (error) {
        console.log(error)
      })

  }

  // showCalendarError = () => {
  //   return this.state.calendarErrorMessage ? (
  //     <Alert
  //       variant="danger"
  //       onClose={() => this.closeCalendarErrorMessage()}
  //       dismissible
  //     >
  //       <p>{this.state.calendarErrorMessage}</p>
  //     </Alert>
  //   ) : (
  //     <div />
  //   )
  // }

  timeList = (type) => {
    let array = type === "hour" ? Array.from(Array(24).keys()) : Array.from(Array(60).keys());
    return array.map(function (test, i) {
      return (
        <option value={i} key={i}>
          {i}
        </option>
      )
    })

  }
  alert = () => {
    return (
      <Alert
        show={this.state.alertMessage !== ''}
        variant="danger"
        onClose={() => this.setState({ alertMessage: '' })}
        dismissible
      >
        <p>{this.state.alertMessage}</p>
      </Alert>
    )
  }

  updateDates = (date) => {
    let inputs = this.state.inputs
    inputs.date = date

    let showCalendar = isMoment(date) ? false : true
    this.setState({
      inputs,
      showCalendar,
      calendarErrorMessage: null,
    }//,()=>this.modalContent()
    )



  }

  showCalender = () => {
    this.setState(
      { showCalendar: !this.state.showCalendar, alertMessage: '' },//()=>this.modalContent()

    )
  }

  changeHandler = (e) => {
    let inputs = this.state.inputs
    inputs[e.target.name] = e.target.value
    this.setState({ inputs, alertMessage: '', }//,()=>this.modalContent()
    )
  }

  clearDate = (e) => {
    let inputs = this.state.inputs
    if (e.keyCode === 8 || e.keyCode === 46) {
      inputs[e.target.name] = ''
      this.setState({ inputs }//,()=>this.modalContent()
      )
    }
  }

  modalCheck = () => {
    const { beginHour, beginMinute, endHour, endMinute, date } = { ...this.state.inputs }
    const timeDelta = (endHour * 60 + endMinute) - (beginHour * 60 + beginMinute)


    if (!isMoment(date)) {
      this.setState({ alertMessage: "je heb nog geen datum geselecteerd" }//,()=>this.modalContent()
      )

    }
    else if (timeDelta === 0) {
      this.setState({ alertMessage: "Begin en eind tijd mogen niet gelijk zijn" }//,()=>this.modalContent()
      )

    }
    else if (timeDelta < 0) {
      this.setState({ alertMessage: "Oeps, de eind tijd is eerder dan de begin tijd" }//,()=>this.modalContent()
      )

    }
    else {
      this.setState({ showModal: false }, () => this.save())
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }


  renderModal = () => {
    return (
      <Modal show={this.state.showModal} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Openingsmoment toevoegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.alert()}
          <Form>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Form.Label column sm="2">
                Datum
              </Form.Label>
              <Col sm="10">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Selecteer een datum"
                    readOnly
                    name="date"
                    onKeyDown={this.clearDate}
                    value={
                      moment.isMoment(this.state.inputs.date)
                        ? this.state.inputs.date.format('DD/MM/YYYY')
                        : this.state.inputs.date
                    }

                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />

                  <InputGroup.Append>
                    <Button
                      name="showBegin"
                      variant="outline-secondary"
                      onClick={() => {
                        this.showCalender()

                      }}
                    >
                      <FontAwesomeIcon className="fa" icon={faCalendarDay} />
                    </Button>

                    {/* <Button variant="outline-secondary">Button</Button> */}
                  </InputGroup.Append>
                </InputGroup>
                {this.state.showCalendar ? (
                  <div
                    style={{
                      position: 'relative',
                      right: '0px',
                      float: 'right',
                    }}
                  >
                    {/* {this.showCalendarError()} */}
                    {/* {this.alert()} */}
                    <Calender
                      classname="ustify-content-md-right"
                      style={{
                        position: 'relative',
                        right: '0px',
                        float: 'right',
                      }}
                      selectDateAllowed={true}
                      showReservations={false}
                      showErrorMesage={true}
                      selectRange={false}
                      width="302px"
                      selectedDates={(date) => {
                        this.updateDates(date)
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formTime">
              <Form.Label column xs={2} md={2}>
                Tijdstip
              </Form.Label>
              <Form.Label column xs={2} md={2}>
                Begin*
              </Form.Label>
              <Col xs={8} md={8}>
                <Form.Row>
                  <Col>
                    <Form.Control
                      style={{ cursor: "pointer" }}
                      name="beginHour"
                      as="select"
                      size="sm"
                      custom
                      value={this.state.inputs.beginHour}
                      type="number"
                      placeholder="uur"
                      onChange={this.changeHandler.bind(this)}
                    >
                      {this.timeList('hour')}
                    </Form.Control>
                  </Col>
                  :
                  <Col>
                    <Form.Control
                      style={{ cursor: "pointer" }}
                      name="beginMinute"
                      as="select"
                      size="sm"
                      custom
                      value={this.state.inputs.beginMinute}
                      type="number"
                      placeholder="minuten"
                      onChange={this.changeHandler.bind(this)}
                    >
                      {this.timeList('minute')}
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Col>

              <Form.Label column xs={{ span: 2, offset: 2 }} md={{ span: 2, offset: 2 }}>
                Einde
              </Form.Label>

              <Col xs={8} md={8}>
                <Form.Row>
                  <Col>
                    <Form.Control
                      style={{ cursor: "pointer" }}
                      name="endHour"
                      as="select"
                      size="sm"
                      custom
                      value={this.state.inputs.endHour}
                      type="number"
                      placeholder="uur"
                      onChange={this.changeHandler.bind(this)}
                    >
                      {this.timeList('hour')}
                    </Form.Control>
                  </Col>
                  :
                  <Col>
                    <Form.Control
                      style={{ cursor: "pointer" }}
                      name="endMinute"
                      as="select"
                      size="sm"
                      custom
                      value={this.state.inputs.endMinute}
                      type="number"
                      placeholder="minuten"
                      onChange={this.changeHandler.bind(this)}
                    >
                      {this.timeList('minute')}
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Col>
            </Form.Group>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeModal}>
            Annuleer
          </Button>
          <Button variant="primary" onClick={() => this.modalCheck()}>
            Voeg toe
          </Button>
        </Modal.Footer>
      </Modal>)
  }




  renderheader = () => {
    return <thead>
      <tr>
        <th>#</th>
        <th>Datum</th>
        <th>Tijd (begin)</th>
        <th>Tijd (einde)</th>
      </tr>
    </thead>
  }


  renderRow = () => {
    let self = this
    return this.state.openings && this.state.openings.map(function (opening, i) {
      return <tr key={i}>

        <td>{i}</td>
        <td>{moment.unix(opening.start).format('DD/MM/YYYY')}</td>
        <td>{moment.unix(opening.start).format('HH:mm')}u</td>
        <td>{moment.unix(opening.end).format('HH:mm')}u</td>
        <td>{self.renderButtons(opening._id)}</td>

      </tr>
    }
    )
  }
  renderButtons = (id) => {
    return <div style={{ float: "right" }}>
      <Button
        size="sm"
        variant="danger"
        onClick={() => this.deleteOpening(id)}
      >
        <FontAwesomeIcon className="fa" icon={faTrashAlt} />
        Delete
      </Button></div>
  }

  render() {
    if (typeof this.state.authorized === 'undefined') {
      return <div />
    } else if (!this.state.authorized) {
      return <UnAuthorized />
    } else {
    return (
      <div className="container">

        <this.renderModal />
        <Button className="pull-right"
          variant="success"
          style={{ margin: '5px' }}
          // onClick={()=>{this.modalContent()}}>
          onClick={() => { this.setState({ showModal: true }) }}>
          <FontAwesomeIcon className="fa" icon={faCalendarPlus} />
          Openingsmoment toevoegen
        </Button>

        <Table striped bordered hover size="sm">
          {this.renderheader()}
          <tbody>
            {this.renderRow()}
          </tbody>
        </Table>


      </div>
    )
    }
  }
}
