import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashAlt,
  faUserPlus,
  // faFileCsv,
} from '@fortawesome/free-solid-svg-icons'
// import ReactFileReader from 'react-file-reader'
import moment from 'moment'
import PrintPDF from "../../components/exportPDFTable/printRegistrationGuidance"
import Modal from"../../components/doubleCheckModal/doubleCheckModal"

export default class members extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resData: [],
      modalMessage:false,
      modalMessage2:false,
      modalMessage3:false,
      currentUser:"",
    }
  }
  componentDidMount = () => {
    this.getLeden()
    const self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_generalServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        self.setState({ authorized: true })
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })
  }



  getLeden = () => {
    let self = this
    axios
      .get(`${process.env.REACT_APP_generalServer}api/members/`, { withCredentials: true, })
      .then((response) => {
        if (response.data) {
          self.setState({ resData: response.data })
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          self.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
  }

  deleteMember = (id) => {
    axios
      .delete(`${process.env.REACT_APP_generalServer}api/members/delete/${id}`, { withCredentials: true, })
      .then((response) => {
        this.getLeden()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  id2verificationCode = (id) => {
    let verificationCode = id
      // .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1-')
      .trim()
      .slice(0, -1)
    return verificationCode
  }

  
  resetPassword  = (id) => {
    let self = this
    axios
      .post(
        `${process.env.REACT_APP_authServer}api/resetLoginPassword/admin`,
        {_id:id},
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({modalMessage:false, waiting:false},()=>this.getLeden())
         
        }
      })
      .catch(function (error) {
        self.setState({ showError: true, errorResponse: 'Email of wachtwoord in niet juist!'})
        console.log(error)
      })
  }



  // handleFiles = (files) => {
  //   var reader = new FileReader()

  //   reader.onload = function (e) {
  //     alert(reader.result)
  //   }
  //   reader.readAsText(files[0])
  // }

  renderRow = () => {
    let self = this

    return this.state.resData.map(function (user, i) {
      let functions
      if (user.functions) {
        functions = typeof JSON.parse(user.functions) !== 'string' ? JSON.parse(user.functions).join(', ') : JSON.parse(user.functions)
      }
      else { functions = '' }
      return (
        <tr key={`row-${i}`}>
          <td>{i}</td>
          <td>{user.first_name ? user.first_name : ''}</td>
          <td>{user.last_name ? user.last_name : ''}</td>
          <td>{functions}</td>
          <td>{user.tak ? user.tak : ''}</td>
          <td>{user.birth_date ? moment(user.birth_date).format("DD/MM/YYYY") : ''}</td>
          {/* <td>
            {user.verification_function ? user.verification_function : ''}
          </td> */}
          {user.functions.includes('Admin')?
          <td><dev 
          style={{  cursor: "pointer" }}
          onClick={()=>self.setState({modalMessage3:true})}>{user.verification_code ? user.verification_code : ''}</dev></td>
          : <td><dev 
          style={{  cursor: "pointer" }}
          onClick={()=>self.setState({modalMessage2:true,currentUser:user._id})}>{user.verification_code ? user.verification_code : ''}</dev></td>
    }
          <td  >
            <div style={{ flexDirection: 'row', textAlign: "center" }}>
              <div style={{ display: 'inline-block' }}>
                <Button
                  variant="primary"
                  // style={{display: 'inline-block'}}
                  onClick={() => {
                    self.props.history.push(
                      `/admin/users/edit/${user._id && user._id}`
                    )
                  }}
                >
                  <FontAwesomeIcon className="fa" icon={faEdit} />
                  edit
                </Button></div>{' '}
              <div style={{ display: 'inline-block' }}>
                <PrintPDF title="Planning Sea-scouts Depanne 15FOS t'Kraaienest"
                  // subtitle={ this.props.match.params.tak}
                  size="A4"
                  marginLeft={40}
                  orientation="portret"
                  // headers = {[this.state.headersDutch]} 
                  // rows = {this.state.resData} 
                  // fieldNames = {this.state.headers} 
                  memberData={user}
                  unit="pt"

                /></div>{' '}
              <div style={{ display: 'inline-block' }}>
                <Button
                  variant="danger"

                  onClick={()=>self.setState({modalMessage:true,currentUser:user._id})}
                >
                  <FontAwesomeIcon className="fa" icon={faTrashAlt} />
                  delete
                </Button>
              </div>
            </div>
          </td>
        </tr>
      )
    })
  }

  renderModal = () => {
    if (this.state.modalMessage){
    return(
        "ben je zeker dat je deze gebruiker wil verwijderen"
    )} 
    else{return ""}   
  }

  renderModal2 = () => {
    if (this.state.modalMessage2){
    return(
        "ben je zeker dat je de verificatie code van  deze gebruiker wil resetten?"
    )} 
    else{return ""}   
  }

  renderModal3 = () => {
    if (this.state.modalMessage3){
    return(
        "Opgepast: voor gebruikers met Admin rechten kan de verificatie code niet worden gereset!"
    )} 
    else{return ""}   
  }

  render() {
    if (!this.state.authorized) {
      return <div />
    } else {
      return (
        
        <div>
           <Modal   message= {this.renderModal()} 
                        agreeAction={()=>this.setState({modalMessage:false},()=>this.deleteMember(this.state.currentUser && this.state.currentUser))}
                        closeAction={()=>this.setState({modalMessage:false})}
                        // onlyButtonTrue
                        header="Gebruiker verwijderen"
                        buttonTrue= "Ja"
                        buttonFalse= "Nee"/>

          <Modal   message= {this.renderModal2()} 
                        agreeAction={()=>this.setState({modalMessage2:false},()=>this.resetPassword(this.state.currentUser && this.state.currentUser))}
                        closeAction={()=>this.setState({modalMessage2:false})}
                        // onlyButtonTrue
                        header="Verificatie Code Resetten?"
                        buttonTrue= "Ja"
                        buttonFalse= "Nee"/>
           <Modal   message= {this.renderModal3()} 
                        agreeAction={()=>this.setState({modalMessage3:false})}
                        closeAction={()=>this.setState({modalMessage3:false})}
                        onlyButtonTrue
                        header="Verificatie Code Resetten?"
                        buttonTrue= "ok"
                        // buttonFalse= "Nee"
                        />
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Voornaam</th>
                <th>Achternaam</th>
                <th>Functie(s)</th>
                <th>Tak</th>
                <th>Geboorte datum</th>
                {/* <th>Verificatie functie</th> */}
                <th>Verificatie code</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.renderRow()}</tbody>
          </Table>
          <Button
            className="pull-right"
            variant="success"
            onClick={() => {
              this.props.history.push('/admin/addMembers')
            }}
          >
            <FontAwesomeIcon className="fa" icon={faUserPlus} />
            Lid toevoegen
          </Button>
          {'  '}
          {/* <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
            <Button className="pull-right" variant="success">
              <FontAwesomeIcon className="fa" icon={faFileCsv} />
              laad Keoo csv
            </Button>
          </ReactFileReader> */}
        </div>
      )
    }
  }
}
