import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'


export default class users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resData: [],
    }
  }

  componentDidMount = () => {
    this.getUserDate()
    const self = this
    //check if user has admin rights
    axios
      .get(`${process.env.REACT_APP_authServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        self.setState({ authorized: true })
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })
  }

  getUserDate = () => {
    let self = this
    axios
      .get(`${process.env.REACT_APP_authServer}api/auth/users`, { withCredentials: true })
      .then((response) => {
        if (response.data) {
          self.setState({ resData: response.data })
        }

      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          self.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
  }

  deleteUser = (id) => {
    axios
      .delete(`${process.env.REACT_APP_authServer}api/auth/user/${id}`, { withCredentials: true })
      .then((response) => {
        this.getUserDate()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  renderRow = () => {
    let self = this
    return this.state.resData.map(function (user, i) {

      let functions = typeof JSON.parse(user.functions) === 'string' ? Array(JSON.parse(user.functions)) : JSON.parse(user.functions)

      return (
        <tr key={`row-${i}`}>
          <td>{i}</td>
          <td>{user.user_first_name ? user.user_first_name : ''}</td>
          <td>{user.user_last_name ? user.user_last_name : ''}</td>
          <td>{user.user_email ? user.user_email : ''}</td>
          <td>{functions ? functions.join(', ') : ''}</td>

          <td>{user.scoutMember ? user.scoutMember : ''}</td>
          <td>{user.scoutGroup ? user.scoutGroup : ''}</td>
          <td>{user.scoutMemberRelation ? user.scoutMemberRelation : ''}</td>
          <td>{user.user_verified ? 'Ja' : 'Nee'}</td>
          <td>{user.lastOnline ? user.lastOnline : ''}</td>
          <td>{user.registerDate ? user.registerDate : ''}</td>
          <td>
            {/* <Button
              variant="primary"
              onClick={() => {
                self.props.history.push(
                  `/admin/users/edit/${user._id && user._id}`
                )
              }}
            >
              <FontAwesomeIcon className="fa" icon={faEdit} />
              edit
            </Button>{' '} */}
            <Button
              variant="danger"
              onClick={() => self.deleteUser(user._id && user._id)}
            >
              <FontAwesomeIcon className="fa" icon={faTrashAlt} />
              delete
            </Button>
          </td>
        </tr>
      )
    })
  }

  render() {
    if (!this.state.authorized) {
      return <div />
    } else {
      return (
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Voornaam</th>
                <th>Achternaam</th>
                <th>email</th>
                <th>Functies</th>
                <th>Lid Connectie</th>
                <th>Lid Tak</th>
                <th>Relatie lid</th>
                <th>Gevalideerd</th>
                <th>Laatst aangemeld</th>
                <th>Registratie datum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.renderRow()}</tbody>
          </Table>
        </div>
      )
    }
  }
}
