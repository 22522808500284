import React, { Component } from 'react'
import { Table, Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFileDownload, faInfo,  faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import axios from "axios"
import {CSVLink} from "react-csv";

export default class newMembers extends Component {
    constructor(props) {
        super(props)
        // this.download = this.download.bind(this);
        this.state = {
            newMembers:[],
            dataToDownload: []
        }
    }

    componentDidMount = () => {
        const self = this
        //check if user has admin rights
        axios
            .get(`${process.env.REACT_APP_generalServer}api/members/check-roles`, {
                withCredentials: true,
            })
            .then((response) => {
                self.setState({ authorized: true })
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    self.setState({ authorized: false })
                }
                console.log(error)
            })

        this.getNewMembers()


    }


    getNewMembers = () => {
        axios.get(`${process.env.REACT_APP_generalServer}api/newMember/lidOnly`,
            { withCredentials: true })
            .then((response) => {
                this.setState({ newMembers: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    deleteEvents = (id) => {
        axios.delete(`${process.env.REACT_APP_generalServer}api/newMember/${id}`,
            { withCredentials: true })
            .then((response) => {
                this.getNewMembers()
            })
            .catch(function (error) {
                console.log(error)
            })

    }



    showDetails = (id) => {
        this.props.history.push(
            `/admin/lidWorden/edit/${id}`
          )

    }

    // dateString2Date = (date) => {
    //     var b = date.split(/\D+/)
    //     return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
    // }
    
    dateString2Date = (date) => {
        return new Date(date * 1000)
      }

    formatDate = (date) => {
        const zeroCheck = (number) => {
            //if the number is less than 9, a zero is added in front
            number = number > 9 ? number : `0${number}`
            return number
        }
        let d = this.dateString2Date(date)
        return `${zeroCheck(d.getDate())}/${zeroCheck(d.getMonth() + 1)}/${d.getFullYear()}`
    }
    
    download = () =>{
       let  hearders = this.getCSVHeaders()
       this.setState({csvHeaders: hearders})
    }


    getCSVHeaders =()=>{
        let uniqueHeaders = Object.keys(Object.assign({}, ...this.state.newMembers));
        let csvhearders = []
        for (let i=0;i<uniqueHeaders.length;i++){
            csvhearders.push({Header:uniqueHeaders[i], accessor: uniqueHeaders[i] })
        }
        
        return uniqueHeaders

    }

    // csvReport =(0=>)


    renderheader = () => {
        return <thead>
            <tr>
                <th>#</th>
                <th>Achternaam</th>
                <th>Voornaam</th>
                <th>Geboortedatum</th>
                <th>Geslacht</th>
                <th>Adres</th>
                <th>Geregistreerd op</th>


            </tr>
        </thead>
    }

    renderRow = () => {
        let self = this
        let gender
       
        return this.state.newMembers && this.state.newMembers.map(function (nM, i) {
     
            let adres = `${nM.straatnaam_lid ? nM.straatnaam_lid : ''} 
                            ${nM.huisnummer_lid ? nM.huisnummer_lid : ''}
                            ${nM.bus_lid ? ` bus ${nM.bus_lid}` : ''}, 
                            ${nM.postcode_lid ? nM.postcode_lid : ''} 
                            ${nM.gemeente_lid ? nM.gemeente_lid : ''}`
        
            switch (nM.geslacht_lid) {
                case "V": gender = "Vrouw"
                    break;
                case "X": gender = "Transgender"
                    break;
                default: gender = "Man"
            }

            return <tr key={i}>
                <td>{i}</td>
                <td>{nM.achternaam_lid}</td>
                <td>{nM.voornaam_lid}</td>
                <td>{nM.geboortedatum_lid}</td>
                <td>{gender}</td>
                <td>{adres}</td>
                <td>{nM.registratie_tijd}</td>

                <td>{self.renderButtons(nM._id)}</td>
            </tr>
        }
        )
    }



    renderButtons = (id) => {
        return <div style={{ float: "right" }}>
            <Button
                size="sm"
                // variant="danger"
                onClick={() => this.showDetails(id)}
            >
                <FontAwesomeIcon className="fa" icon={faInfo} />
                Details
            </Button>{ ' '}

            <Button
                size="sm"
                variant="danger"
                onClick={() => this.deleteEvents(id)}
            >
                <FontAwesomeIcon className="fa" icon={faTrashAlt} />
                Delete
            </Button></div>
    }
    render() {
       
        if (!this.state.authorized) {
            return <div />
        } else {
            return (
                <div className="container">
                    

           <div style = {{marginBottom:"5px", marginTop:"5px"}} className='pull-right'> 
      <CSVLink   target="_blank" className="hidden" data={this.state.newMembers} filename={"data.csv"}>
          <Button variant="success"> <FontAwesomeIcon className="fa" icon={faFileDownload} /> Exproteer naar CSV</Button>
          </CSVLink>
          </div>     
    
                   


                    <Table striped bordered hover size="sm">
                        {this.renderheader()}
                        <tbody>
                            {this.renderRow()}
                        </tbody>
                    </Table>
                </div>
            )
        }
    }
}
