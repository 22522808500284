import React, { Component } from 'react'
import {Form} from "react-bootstrap"

export default class introduction extends Component {
    constructor() {
        super();
        this.state = {
          elementFocus:null,
          newItem:"",
       
        }
      }
    
    changeHandler = (e)=>{
        this.props.updateAction({name:e.target.name,value:e.target.value})
    }

    onEnterClick = (e) => {
        if (e.keyCode === 13||e.keyCode === 27) {
            this.setState({elementFocus:null,newItem:""})
        }
      }

    render() {
        return (
            <div className="verhuur-introduction">
                <div onClick={()=>{if(this.props.editMode){this.setState({elementFocus:`intro-title`})}}}>
             
                {this.state.elementFocus===`intro-title`?<Form.Control  
                autoFocus type="text" 
                value={this.props.title}
                name = {`title`}
                onChange={this.changeHandler}
                onBlur = {()=>this.setState({elementFocus:null})}
                onKeyDown = { this.onEnterClick.bind(this)}
                placeholder="Enter email" />
            :<b>{this.props.title}</b>}
            </div>


            <div onClick={()=>{if(this.props.editMode){this.setState({elementFocus:`intro-info`})}}}>
             
             {this.state.elementFocus===`intro-info`?<Form.Control  
             autoFocus type="text" 
             value={this.props.introduction}
             name = {`info`}
             onChange={this.changeHandler}
             onBlur = {()=>this.setState({elementFocus:null})}
             onKeyDown = { this.onEnterClick.bind(this)}
             placeholder="Enter email" />
         :<p>{this.props.introduction}</p>}
                
                
                </div>
                {/* <p>{this.props.introduction}</p> */}
            </div>
        )
    }
}
