import React, { Component } from 'react'
import SingleDateCalendar from '../mainPage/singleDateCalendar/singleDateCalendar'
import { Row, Col, Button } from 'react-bootstrap'


export class singeleEvent extends Component {
  render() {
    let details = null

    if (this.props.event && this.props.event.details)
      details = JSON.parse(this.props.event.details)
    if (typeof details === 'string') {
      details = [details]
    }


    return (
      <div style={{ marginTop: "50px" }} id="events" >
        <div className="container">
          <div className="text-center calendar-xs-sm"  >
            <SingleDateCalendar from={this.props.event && this.props.event.beginDate} till={this.props.event && this.props.event.endDate} />
          </div>

          {this.props.event ?
            <Row id="event" style={{ paddingTop: 0 }}>

              <Col xs={12} lg={6} className="text-center" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <img src={`${process.env.REACT_APP_generalServer}${this.props.event.image}`} className="img-responsive" alt="" />
              </Col>
              <Col xs={12} lg={6}>
                <div className="event-text">




                  <Row style={{ marginTop: "25px", position: "relative", marginLeft: "auto", marginRight: "auto" }}>
                    <Col xs={12} lg={6}>
                      <h2  >{this.props.event.title}</h2>
                    </Col>
                    <div className="text-center calendar-md-lg-xl" style={{ position: "absolute", bottom: 30, right: 0 }} >
                      <SingleDateCalendar from={this.props.event.beginDate} till={this.props.event.endDate} />
                    </div>
                  </Row>

                  <p>{this.props.event ? this.props.event.content : 'loading...'}</p>
                  {details ? <div>
                    <h3>Details</h3>
                    {/* <div className="list-style"> */}
                    <div >
                      <ul>
                        {details.map((d, i) => <li key={`${d}-${i}`}>{d}</li>)}
                      </ul>
                    </div></div> : <></>}
                  <div style={{ float: "right" }}>
                    {this.props.event.eventForm ?
                      <Button size="lg"
                        style={this.props.blue_button_style}
                        onClick={() => this.props.history.push(`/evenementen/${this.props.event._id}`)}> Inschrijven</Button> : <></>}
                    {' '}
                    {this.props.alleventsButton && this.props.alleventsButton ?
                      <Button size="lg"
                        style={this.props.blue_button_style}
                        onClick={() => {this.props.history.push("/evenementen")
                        window.location.reload()
                      }
                      }
                        > Bekijk alle evenementen</Button>
                      : <></>}
                  </div>
                </div>
              </Col>
            </Row> : <div />}

        </div>
      </div>
    )
  }
}

export default singeleEvent

