import React, { Component } from 'react'
import { Form, Button, Row, Col, Alert } from 'react-bootstrap'
import axios from 'axios'

import '../../components/css/login.css'

export default class resetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: {
        tempPassword:'',
        password: '',
        passwordConfirm: '',
      },
      validated: false,
      comparePassword: false,
      errorResponse: '',
      showError: false,
    }
  }




  handleSubmit = (event) => {
    event.preventDefault()
     if (this.state.inputs.password !== this.state.inputs.passwordConfirm) {
      this.setState(
        { showError: true, errorResponse: 'paswoorden zijn niet hetzelfde!' },
        () => {
          this.errorMessage()
        }
      )
    } else {
      // this.setState({ showModal: true })
      this.updatePassword()
    }

    this.setState({ validated: true, comparePassword: true })
  }
  changeHandler = (e) => {
    const target = e.target.name
    const inputs = { ...this.state.inputs }
    if (target === 'KeepLoggedIn') {
      inputs[target] = !inputs[target]
    } else {
      inputs[target] = e.target.value
    }
    this.setState({ inputs, comparePassword: false, showError: false })
  }

  register = () => {
 
    axios
      .post(
        `${process.env.REACT_APP_authServer}api/auth/register`,
        this.state.inputs, 
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push( {pathname: '/verify',
          
          state: { firstName: this.state.inputs.firstName,lastName: this.state.inputs.lastName }})
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          this.setState(
            { showError: true, errorResponse: error.response.data },
            () => {
              this.errorMessage()
            }
          )
        }
      })
  }

  updatePassword = () => {
    let self = this
    axios
      .post(
        `${process.env.REACT_APP_authServer}api/auth/updatePasword`,
        {inputs:this.state.inputs,userID:this.props.match.params.id},
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push( {pathname: '/',})
          
          // state: { firstName: this.state.inputs.firstName,lastName: this.state.inputs.lastName }})
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          self.setState(
            { showError: true, errorResponse: error.response.data },
            () => {
              self.errorMessage()
            }
          )
        }
      })
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  errorMessage = () => {
    return (
      <Alert
        variant="danger"
        onClose={() => this.setState({ showError: false })}
        dismissible
      >
        <p>{this.state.errorResponse}</p>
      </Alert>
    )
  }

  render() {
    
    return (
      <div id = "register" className= "container register-container">
        <div className=" justify-content-md-center">
          <Form>
            <div className="login-title">
              <b>Wijzig wachtwoord </b>
            </div>
            {this.state.showError ? this.errorMessage() : <div />}
            <Form.Group as={Row} controlId="formPasswordTemp">
              <Form.Label column sm={2}>
                Wachtwoord
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="tempPassword"
                  value={this.state.inputs.tempPassword}
                  type="password"
                  placeholder="Wachtwoord"
                  isValid={
                    this.state.inputs.password ===
                      this.state.inputs.passwordConfirm &&
                    this.state.inputs.password !== ''
                  }
                  onChange={this.changeHandler.bind(this)}
                />
              </Col>
            </Form.Group>{' '}
            <Form.Group as={Row} controlId="formPassword">
              <Form.Label column sm={2}>
                Wachtwoord
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="password"
                  value={this.state.inputs.password}
                  type="password"
                  placeholder="Wachtwoord"
                  isValid={
                    this.state.inputs.password ===
                      this.state.inputs.passwordConfirm &&
                    this.state.inputs.password !== ''
                  }
                  onChange={this.changeHandler.bind(this)}
                />
              </Col>
            </Form.Group>{' '}
            <Form.Group as={Row} controlId="formPasswordConfirm">
              <Form.Label column sm={2}>
                Bevestig Wachtwoord
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="passwordConfirm"
                  value={this.state.inputs.passwordConfirm}
                  type="password"
                  placeholder="Wachtwoord"
                  isValid={
                    this.state.inputs.password ===
                      this.state.inputs.passwordConfirm &&
                    this.state.inputs.password !== ''
                  }
                  onChange={this.changeHandler.bind(this)}
                />
              </Col>
            </Form.Group>{' '}
            <div className="align-center">
              <Button size="sm" onClick={this.handleSubmit.bind(this)}>
                wijzigen
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}
