import React, { Component } from 'react'
import { Row, Col, Form, Alert, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import Modal from '../../components/doubleCheckModal/doubleCheckModal'
//import Recaptcha from 'react-google-invisible-recaptcha'
import Recaptcha from '../../components/reCAPTCHA'
// import Recaptcha from 'react-google-recaptcha'

const recaptchaRef = React.createRef();


export default class contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      inProcess: false,
      cap:false,
      inputs: {
        firstName: '',
        lastName: '',
        email: '',
        question: '',
        
      }
    }
  }

//   onChange =()=>{
//     this.recaptcha.reset()
// }
// componentDidMount=()=>{
//   if (window.grecaptcha){
//     window.grecaptcha.reset()
//     window.location.reload(false);
//   }
  
// }
// componentWillUnmount=()=>{
//   // if (window.grecaptcha){
//   //       window.grecaptcha.reset()
//   //       // window.location.reload(false);
//   //     }
//   // recaptchaRef.current.reset()
//   console.log('unounting')
//   recaptchaRef.current.recaptchaReset()

// }


  send2Server = () => {

    axios.post(`${process.env.REACT_APP_generalServer}api/questions/general`,
      { data: this.state.inputs },{withCredentials:true})
      .then(() => {
        this.setState({ responseMessage: "Wij proberen uw vraag zo snel mogelijk te beantwoorden", inProcess: false })
      })
      .catch(function (error) {
        console.log(error)
      })



  }


  validateForm = () => {
    let { firstName, lastName, email, question } = this.state.inputs
    let errorMessage = ''
    if (firstName === '') {
      errorMessage = "U moet uw voornaam invullen"
    }
    else if (lastName === '') {
      errorMessage = "U moet uw achternaam invullen"
    }
    else if (!this.validateEmail(email)) {
      errorMessage = "U moet een geldig E-mailadres invullen"
    }
    else if (question === '') {
      errorMessage = "U hebt nog geen vraag gesteld"
    }

    if (errorMessage === '') {
      // this.props.onSelectLanguage(lang); 
       this.recaptchaExecute()
      //recaptchaRef.current.execute()
     
      // window.grecaptcha.execute()

    } else {
      this.setState({ errorMessage })
    }
  }

  recaptchaExecute=()=>{
    // Recaptcha.recaptchaExecute()
    recaptchaRef.current.recaptchaExecute()
  }

  alertMessage = () => {
    return <Alert show={this.state.errorMessage !== ''} variant="danger" onClose={() => this.setState({ errorMessage: '' })} dismissible>

      <p>
        {this.state.errorMessage}
      </p>
    </Alert>
  }

  clearForm = () => {
    let inputs = this.state.inputs
    inputs.firstName = inputs.lastName = inputs.email = inputs.question = ''
    this.setState({ inputs, responseMessage: null })

  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  changehandler = (e) => {
    let inputs = this.state.inputs
    inputs[e.target.name] = e.target.value
    this.setState({ inputs, errorMessage: '' })
  }

//   captchaHandler = (e) => {
//     let cap =  this.state.cap
//     this.setState({cap:!cap})
// }

  onResolved = () => {
    this.setState({ messageSent: true, inProcess: true }, () => this.send2Server())
  }
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <Modal message={this.state.responseMessage ? this.state.responseMessage : ''}
              agreeAction={() => this.clearForm()}
              closeAction={() => this.clearForm()}
              onlyButtonTrue
              backdrop="false"
              header="Uw vraag werd goed ontvangen"
              buttonTrue="oke" />



            <Row>
              <Col lg={8}>
                <div className="section-title">
                  <h2>Contacteer ons</h2>
                  <p>
                    Vul onderstaand formulier om ons een email te sturen
                    en wij proberen u zo spoedigmogelijk te beantwoorden.
                  </p>

                </div>
                <this.alertMessage />

                <Form >
                  <Row>
                    <Col md={12} lg={6}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control placeholder="Voornaam"
                          name='firstName'
                          value={this.state.inputs.firstName}
                          onChange={this.changehandler} />
                      </Form.Group>
                    </Col>
                    <Col md={12} lg={6}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control placeholder="Achternaam"
                          name='lastName'
                          value={this.state.inputs.lastName}
                          onChange={this.changehandler} />
                      </Form.Group>
                    </Col>
                    {/* <Col md={12} lg = {6}>
                    
                    </Col> */}
                  </Row>

                  <Form.Group controlId="exampleForm.ControlInput2">
                    <Form.Control placeholder="E-mailadres"
                      name='email'
                      value={this.state.inputs.email}
                      onChange={this.changehandler} />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control as="textarea" rows={4} placeholder="Vraag"
                      name='question'
                      value={this.state.inputs.question}
                      onChange={this.changehandler} />
                  </Form.Group>
                  {this.state.inProcess ?
                    <Button style={this.props.red_button_style} size="lg" disabled>
                      <Spinner
                        as="div"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Verwerken...
                    </Button> :
                    <Button size="lg"
                      style={this.props.red_button_style}
                      onClick={() => this.validateForm()}> Vraag verzenden</Button>}


<Recaptcha
                    ref={recaptchaRef}
                    onResolved={() => this.onResolved()} 
                    // agreeAction={() => this.clearForm()}
                    />

                  {/* <Recaptcha
                      // ref={ref => this.recaptcha = ref}
                      ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_capcha_sitekey}
                    locale='nl'
                    size='invisible'
                    // onChange={this.onChange}
                    onResolved={this.onResolved} 
                    /> */}
                </Form>
              </Col>
              <Col lg={{ span: 3, offset: 1 }}>

                <div className="contact-item">
                  <h3>Contact Info</h3>
                  <p>
                    <span>
                      <i className="fa fa-map-marker"></i> Adres
                    </span>
                    Sportlaan 10, 8660 De panne
                  </p>
                </div>
                {this.props.phone_eenheidsleider && this.props.phone_eenheidsleider.phonenumber ?
                  <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-phone"></i> Telefoonnummmer
                      </span>{" "}
                      {this.props.phone_eenheidsleider.phonenumber.replace(/(\d{4})(\d{2})(\d{2})(\d{2})/, '$1/$2.$3.$4')}
                    </p>
                  </div> : <div />}
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-envelope-o"></i> E-mailadres
                    </span>{" "}
                    info@scoutsdepanne.be
                  </p>
                </div>
              </Col>
            </Row>
            <Row >
              <Col className="text-center">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                       <a href={this.props.data ? this.props.data.instagram : "/"}>
                         <i className="fa fa-instagram"></i>
                       </a>
                     </li>
                     {/* <li>
                       <a href={this.props.data ? this.props.data.youtube : "/"}>
                         <i className="fa fa-youtube"></i>
                       </a>
                     </li> */}
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}



