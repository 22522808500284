import React, { Component } from 'react'
import SingleDateCalendar from './singleDateCalendar/singleDateCalendar'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const red_button_style = {
  color: '#fff',
  pointerEvents: 'none',

  borderRadius: '30px',
  paddingLeft: "25px",
  paddingRight: "25px",
  fontSize: "20px",
  // position: 'absolute',
  // right:'15px',
  float: 'right',
  backgroundImage: 'linear-gradient(to right, #f3b0b0 0%, #f73535 100%)',
}

export class FosShop extends Component {
  render() {
    return (
      <div >
        {/* <div className="container"> */}

        <div id="FosShop" >
          <div className="container">
            <div className="section-title-md-lg-xl text-center">
              <h2>FOS-Shop</h2>
            </div>
            <div className="row">
              <div className="col-xs-12 col-lg-6 text-center"> <img src="img/about.jpg" className="img-responsive" style={{ marginBottom: "20px" }} alt="" /> </div>
              <div className="col-xs-12 col-lg-6" >
                <div>{this.props.data ?
                  <div>
                    {/* <div className="col-xs-12 col-md-6"> */}
                    <div className="about-text-xs-sm " >
                      <h2>FOS-Shop</h2>
                    </div>
                    <div className="about-text">
                      {/* </div> */}
                     
                      <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>

                      <div className="row text-center" style={{ display: "flex", marginTop: "25px" }} >
                        {/* <div className="col-xs-7 col-sm-8 col-md-6" > */}
                        {/* <div className="row" style={{display: "flex" , marginTop: "25px"}}> */}
                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5" >
                          <SingleDateCalendar from={this.props.from} till={this.props.till} header={"Eerst volgende openingsmoment"} />
                        </div>


                        <div className="col-lg-7 col-md-7 col-sm-7  col-xs-7 " style={{ marginTop: "auto" }}>
                          {/* <div className="col-lg-8 col-sm-8 col-xs-7"  style={{marginTop: "auto" }}> */}
                          <p style={{ textAlign: 'left' }}>Onze webshop is nog in aanbouw.wij hopen deze spoedig werkend te krijgen. Bij het volgende openingsmoment kan je je bestelling komen afhalen</p>

                          {/* <Button size="lg"
                            style={this.props.red_button_style}
                            onClick={() => this.props.history.push('/fos-shop/shop')}> Bestel</Button> */}

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">nog even geduld! deze pagina is nog in aanbouw</Tooltip>}>
                            <span className="d-inline-block">
                              <Button disabled style={red_button_style}>
                                Bestel
                              </Button>
                            </span>
                          </OverlayTrigger>
                        </div>


                      </div>
                    </div>
                  </div>
                  : <div />}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default FosShop
