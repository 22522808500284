import React, { Component } from 'react'
import { Button, Modal, Form, Alert, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'


import '../../components/css/Photo-gallery.css'

export default class photoAlbumOverview extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            currentIndex: null,
            albumTitle: '',
            errorMessage: '',
            deleteFlag: false,
        }
    }

    componentDidMount = () => {
        const self = this
        //check if user has admin rights
        axios
            .get(`${process.env.REACT_APP_generalServer}api/members/check-roles`, {
                withCredentials: true,
            })
            .then((response) => {
                self.setState({ authorized: true })
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    self.setState({ authorized: false })
                }
                console.log(error)
            })
        this.getAlbums()
    }



    getAlbums = () => {
        axios.get(`${process.env.REACT_APP_generalServer}api/photoAlbums/`,
            { withCredentials: true })
            .then((response) => {
                this.setState({ albums: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    handleClose = () => {
        this.setState({ showModal: false })

    }



    deleteAlbum = (id) => {
        axios.delete(`${process.env.REACT_APP_generalServer}api/photoAlbums/${id}`,
            { withCredentials: true })
            .then((response) => {
                this.getAlbums()
                // this.setState({ albums: response.data })
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    changeFileHandeler = (e) => {
        var file = e.target.files
        if (e.target.files.length > 0) {
            var url = window.URL.createObjectURL(file[0])
            this.setState({ currentFigure: file[0], currentFigureURL: url, errorMessage: '' })
        }
        //this wil reset the current value of the form file. 
        // meaning that one image can be added twice ore more after eatch other
        e.target.value = null
    }

    saveNewAlbum = () => {
        let fd = new FormData()
        fd.append('title', this.state.albumTitle)
        fd.append('mainImage', this.state.currentFigure)

        axios.post(
            `${process.env.REACT_APP_generalServer}api/photoAlbums/add-newAlbum`,
            fd,
            { withCredentials: true }
        )
            .then((response) => {
                this.setState({ showModal: false }, () => this.getAlbums())
                // self.props.history.push('/admin/overview/evenementen')
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    checkModal = () => {
        let errorMessage = ''
        if (this.state.albumTitle === '') {
            errorMessage = 'Je moet een een album titel opgeven'
        }
        else if (!this.state.currentFigureURL || this.state.currentFigureUR === '') {
            errorMessage = "je moet ee afbeelding selecteren"
        }
        else { this.saveNewAlbum() }

        if (errorMessage !== '') {
            this.setState({ errorMessage })
        }
    }
    renderAlert = () => {
        return <Alert show={this.state.errorMessage !== '' ? true : false} variant="danger" onClose={() => this.setState({ errorMessage: '' })} dismissible>
            <p>
                {this.state.errorMessage}
            </p>
        </Alert>

    }

    renderMdoal = () => {
        return <Modal show={this.state.showModal} backdrop="static" onHide={this.handleClose}>

            <Modal.Header closeButton>
                <Modal.Title>Nieuw foto album</Modal.Title>
            </Modal.Header>
            {this.renderAlert()}
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Album titel</Form.Label>
                        <Form.Control type="text" placeholder="Enter email" value={this.state.albumTitle} onChange={(e) => { this.setState({ albumTitle: e.target.value }) }} />

                    </Form.Group>
                    <Form.File
                        id="custom-file"
                        label="Selecteer introductie foto"
                        data-browse="Afbeelding kiezen"
                        onChange={this.changeFileHandeler.bind(this)}
                        accept="image/png, image/jpeg"
                        custom
                    />
                </Form>
                <div>
                    <img style={{ maxWidth: '70%' }} src={this.state.currentFigureURL} alt='' />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    Annuleer
                </Button>
                <Button variant="primary" onClick={() => this.checkModal()}>
                    Voeg album toe
                </Button>
            </Modal.Footer>
        </Modal>
    }
    touchMove = (index) => {
        this.setState({ currentIndex: index })
    }

    renderAlbums = () => {
        const self = this
        if (this.state.albums) {
            return <Row>{this.state.albums.map(function (album, i) {

                return <Col sm='4' key={i} className='album-item' >
                    <div className={self.state.currentIndex === i ? "touchMove" : "hover-bg"}>
                        {/* <div  className="hover-bg"> */}

                        <a
                            onTouchMove={() => self.touchMove(i)}
                            onClick={() => self.props.history.push(`/fotos/${album.title}/${album._id}`)}

                            // onBlur={() => console.log('lalalal')}
                            title="Project Title"
                            data-lightbox-gallery="gallery1"
                            href="/#"
                        >
                            <div id="album" className="hover-text-album">


                                <h4>{album.title} </h4>

                            </div>

                            <div className='album-item' >

                                <img style={{ maxWidth: '100%', cursor: 'pointer' }}
                                    src={`${process.env.REACT_APP_generalServer}${album.mainImage}`}
                                    // onError={this.addDefaultSrc} 
                                    className="img-responsive"
                                    alt='' />
                                <div
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // opacity:0.4,
                                        right: '0px',
                                        top: '0px',
                                        zIndex: 100,
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        margin: 'auto',
                                        width: '40px',
                                        height: '40px'
                                    }}
                                >
                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={(event) => { event.stopPropagation(); self.deleteAlbum(album._id) }} size='lg' icon={faTrashAlt} /></div>
                            </div>
                            {" "}
                        </a>{" "}
                    </div>









                    {/* <div className='leiders-item' style={{position:'relative'}}>
                            <img style={{maxWidth:'100%' , cursor:'pointer'}}
                                        onClick={()=>self.props.history.push(`/fotos/${album.title}/${album._id}`)}
                                        src={`${ process.env.REACT_APP_generalServer}${album.mainImage}`}
                                        alt=''/>
                        <div
                            style={{position:'absolute', 
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    // opacity:0.4,
                                    right:'0px', 
                                    top:'0px', 
                                    backgroundColor:'rgba(255, 255, 255, 0.8)',
                                    margin :'auto',
                                    width:'40px',
                                    height:'40px'}}
                        >
                            <FontAwesomeIcon  style={{cursor:'pointer'}} onClick ={()=>self.deleteAlbum(album._id)} size='lg' icon={faTrashAlt} /></div>
                            </div> */}
                </Col>
            })} </Row>
        }
        else return <></>
    }
    render() {
        if (!this.state.authorized) {
            return <div />
        } else {

            return (
                <div className='container' onClick={() => this.setState({ currentIndex: null })}>
                    <this.renderMdoal />
                    <Button variant='success' onClick={() => this.setState({ showModal: true })}>Nieuw album aanmaken</Button>
                    <this.renderAlbums />


                </div>
            )
        }
    }
}

