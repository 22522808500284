import React, { Component } from 'react'
import jsPDF from "jspdf";
import "jspdf-autotable";
import Modal from '../doubleCheckModal/doubleCheckModal'
import {Button} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload
} from '@fortawesome/free-solid-svg-icons'
import moment from "moment"



export default class exportPDFTable extends Component {
    constructor() {
        super();
        this.state = {
            modalMessage:false
        }
      }
      dateString2Date = (date) => {

        return new Date(date * 1000)
      }

      // dateString2Date = (date) => {
      //   var b = date.split(/\D+/)
      //   return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
      // }

      
    
      exportPDF = () => {
        const unit = this.props.unit||"pt";
        const size = this.props.size||"A4"; // Use A1, A2, A3 or A4
        const orientation = this.props.orientation||"landscape"; // portrait or landscape
    
        const marginLeft = this.props.marginLeft||40;
        const doc = new jsPDF(orientation, unit, size, );
  
        const title = this.props.title||"";
        const subtitle = this.props.subtitle||"";
        const headers = this.props.headers;;
    
        let data=[]

        
        for (let i = 0; i < this.props.rows.length; i++) {

            if (this.props.rows[i].public){
            data.push(this.createRowItem(this.props.rows[i]));}
            
            else if (!this.state.modalMessage){
                this.setState({modalMessage: true})

            }
        }

    
        let content = {
          startY: 40+15+12,
          head: headers,
          body: data, 
        };
        
        doc.setFont("times");
        doc.setFontSize(15);
        doc.text(title,  marginLeft, 40);
        doc.setFontSize(12);
        doc.text(subtitle, marginLeft, 40+15);

        doc.autoTable(content);
        doc.save(`planning_${subtitle}.pdf`)
      }


      createRowItem = (row) => {
        let self = this
        let dateSet = false
        console.log(row)
        let date_begin = self.dateString2Date(row.date_begin)
            let date_end = self.dateString2Date(row.date_end)
            let moment_date_begin = moment(
              `${date_begin.getDate()}/${date_begin.getMonth() + 1
              }/${date_begin.getFullYear()}`,
              'DD/MM/YYYY'
            )
            let moment_date_end = moment(
              `${date_end.getDate()}/${date_end.getMonth() + 1
              }/${date_end.getFullYear()}`,
              'DD/MM/YYYY'
            )

          return this.props.fieldNames.map(function (element, i) {
            let rtn = ""
            console.log(element)
            
            console.log("moment_date_end = ", moment_date_end,moment_date_end._i)
            if (element === 'date' && !dateSet) {
              rtn =
              moment_date_begin._i === moment_date_end._i ? (
                 
                moment_date_end._i
                 
                ) : (
                 
                    `${moment_date_begin._i}-${moment_date_end._i}`
                 
                )
              dateSet = true
            } 
            else if (element === 'content') {
              console.log(row.content)
              rtn = String(row.content && JSON.parse(row.content).join(', '))
            } else if (element === 'location') {
              console.log(row.location_begin , row.location_end,row.location_begin === row.location_end)
              rtn =
                row.location_begin === row.location_end ? (
                  row.location_begin
                ) : (
                  
                    "Begin: "+row.location_begin+", Einde: "+row.location_end
                )
            } 
            else if (element === 'date' && dateSet) {
              rtn =
              moment_date_begin === moment_date_end ? (
                 
                    String(date_begin.getHours())+":"+String(date_begin.getMinutes())+"u"
                ) : (
                  `Begin: ${date_begin.toLocaleString('nl-BE',{timeStyle: 'short'})}, Einde: ${date_end.toLocaleString('nl-BE',{timeStyle: 'short'})} `
                    // "Begin: "+String(date_begin.getHours())+":"+String(date_begin.getMinutes())+"U, "+
                    // "Einde: "+String(date_end.getHours())+":"+String(date_end.getMinutes())+"U"
                  
                )
            } 
            else if (element === 'price') {
              rtn = row.price ? (
                String(row.price)+" EUR"
              ) : (
                String("")
              )
            } else if (element === 'required_items')  {
              
              rtn = (
                JSON.parse(row.required_items).join(', ')
              )
              console.log(JSON.parse(row.required_items).join(', '))
            }
            return rtn
          })
      
       
      }

      renderModal = () => {
        if (this.state.modalMessage){
        return(
            "Enkel de publieke activiteiten worden in de gedownloade PDF weergegeven"
        )} 
        else{return ""}   
      }

  
    
    render() {
        return (
            <div >
                 <Modal   message= {this.renderModal()} 
                        agreeAction={()=>this.setState({modalMessage:false})}
                        closeAction={()=>this.setState({modalMessage:false})}
                        onlyButtonTrue
                        header="Let Op!"
                        buttonTrue= "oke"/>

                <Button style={{margin:"10px"}} 
                        className="pull-right" 
                        size= "sm"
                        onClick={() => this.exportPDF()}>
                     <FontAwesomeIcon className="fa" icon={faFileDownload} />Download planning
                </Button>
            </div>
        )
    }
}
