import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import SingleEvent from '../eventsOverview/singleEvent'

export class evenementen extends Component {

  componentDidMount=()=>{
        // if (window.grecaptcha){
        //   window.grecaptcha.reset()
        //   window.location.reload(false);
        // }
      //   if(!window.location.hash) {
      //     window.location = window.location + '#loaded';
      //     window.location.reload();
      // }
      }


  render() {
    return (
      <div id="event" >
        <div className="container">
          <div className="section-title text-center center_after">
            <h2>evenementen</h2>
          </div>
          <SingleEvent blue_button_style={this.props.blue_button_style} history={this.props.history} event={this.props && this.props.events} alleventsButton={true} />

          {this.props.data ?
            <Row id="event" style={{ paddingTop: 0 }}>

              {/* <Col xs= {12} lg= { 6} className="text-center" > <img src={ this.props.data.img} className="img-responsive" alt=""/> </Col> */}
              <Col xs={12} lg={6}>
                <div className="event-text">






                </div>
              </Col>
            </Row> : <div />}

        </div>
      </div>
    )
  }
}

export default evenementen

