import React, { Component } from 'react'
import Button  from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form  from 'react-bootstrap/Form';

// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExportPDFTable from "../../components/exportPDFTable/exportPDFTable"
import {
  faPlusSquare,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import '../../components/css/general.css'

import axios from 'axios'



const GenText = (props) => <p>{props.text}</p>

export default class individuelePlanning extends Component {
  constructor(props) {
    super(props)
    this.state = {
      radioValue:'1',
      showAll:false,
      authenticated: false,
      headersDutch: [
        'Datum',
        'Activiteit',
        'Locatie',
        'Tijdstip',
        'Prijs',
        'Speciale Benodigdheden',
      ],
    }
  }

  checkAuthorization = () => {
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/activities/check-editactivitiespageRole/${this.props.match.params.tak}`,
        { body: false },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.setState({
          authenticated: response.data.authenticated,
        })
      })
      .catch(function (error) {
        if (error.response.status !== 401) {
          console.log(error)
        }
      })
  }

  async componentDidMount() {
    this.checkAuthorization()
    // let self = this
    // axios
    //   .get(
    //     `${process.env.REACT_APP_generalServer}api/activities/check-editactivitiespageRole/${this.props.match.params.tak}`,
    //     {
    //       withCredentials: true,
    //     }
    //   )
    //   .then((response) => {
    //     console.log(response)
    //     this.setState({
    //       authenticated: response.data.authenticated,
    //     })
    //   })
    //   .catch(function (error) {
    //     console.log('error', error.response.status)
    //     if (error.response.status !== 401) {
    //       console.log(error)
    //     }
    //   })
    this.getData()

    
  }


 getData= async ()=>{
    let resData = await axios
      .get(
        `${process.env.REACT_APP_generalServer}api/activities/tak/${this.props.match.params.tak}?showall=${this.state.showAll}`,
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data) {
          this.setState({ resData: response.data })
          return response.data
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          this.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
    this.checkRequiredHeaders(resData)

  }

  text(text) {
    return text.map(function (text, i) {
      return <GenText text={text} key={i} />
    })
  }

  checkStartEnd = () => {
    let areEqual = true
    if (this.state.resData) {
      for (var i = 0; i < Object.keys(this.state.resData).length; i++) {
        areEqual =
          areEqual === false ||
            (this.state.resData[i].date_begin &&
              this.state.resData[i].date_end &&
              this.state.resData[i].location_begin !==
              this.state.resData[i].date_begin)
            ? false
            : true
        // takNamen.push(Object.keys(this.state)[i])
      }
    }
  }

  updateview =(e)=>{
    this.setState({showAll:!e},()=>this.getData())
    
  }

  checkRequiredHeaders = (resData) => {
    let checkitems = [
      'date',
      'content',
      'location',
      'date',
      'price',
      'required_items',
    ]
    let columnwidths = [
      '100px',
      '200px',
      '100px',
      '100px',
      '10px',
      '200px',
    ]
    let resulst = [true, true, true, true, false, false]

    if (resData) {
      for (var i = 0; i < Object.keys(resData).length; i++) {
        for (var j = 0; j < checkitems.length; j++) {

          resulst[j] =
            resulst[j] === true || (resData[i][checkitems[j]] && resData[i][checkitems[j]] !== "[]") ? true : false
        }
      }
    }
    var headersDutch = this.state.headersDutch.filter(function (_, i) {
      return resulst[i] === true
    })
    var headers = checkitems.filter(function (_, i) {
      return resulst[i] === true
    })
    columnwidths = columnwidths.filter(function (_, i) {
      return resulst[i] === true
    })
    this.setState({ headers: headers, headersDutch: headersDutch, columnwidths })
  }
  createHeaders = () => {
    let columnwidths = this.state.columnwidths
    return this.state.headersDutch.map(function (item, i) {
      let currentwidth = columnwidths ? columnwidths[i] : "100%"

      return <th key={i} ><div style={{ minWidth: currentwidth }}>{item}</div></th>
    })
  }

  deleteActivity = (id) => {
    axios
      .delete(`${process.env.REACT_APP_generalServer}api/activities/${id}/${this.props.match.params.tak}`, { withCredentials: true })
      .then((response) => {
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  createRow = () => {
    let self = this

    if (this.state.resData) {
      return this.state.resData.map(function (activity, i) {
        let classname = activity.public ? '' : 'table-inactive-row'
        return (
          <tr key={i} className={classname}>
            {self.createRowItem(activity)}{' '}
            {self.state.authenticated ? (
              <td >
                <div style={{ width: "143px" }} className="pull-right">
                  <Button
                    size="sm"
                    onClick={() =>
                      self.props.history.push(
                        `/kalender/planning/edit/${self.props.match.params.tak}/${activity._id}`
                      )
                    }
                  >
                    <FontAwesomeIcon className="fa" icon={faEdit} />
                    edit
                  </Button>{' '}
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() =>
                      self.deleteActivity(activity._id && activity._id)
                    }
                  >
                    <FontAwesomeIcon className="fa" icon={faTrashAlt} />
                    delete
                  </Button>
                </div>
              </td>
            ) : (
              <></>
            )}
          </tr>
        )
      })
    }
  }

  dateString2Date = (date) => {
    return new Date(date * 1000)
  }

  createRowItem = (row) => {
    let self = this
    let dateSet = false
    if (this.state.headers) {
      return this.state.headers.map(function (element, i) {
        let rtn = null
        let date_begin = self.dateString2Date(row.date_begin)
        let date_end = self.dateString2Date(row.date_end)
        if (element === 'date' && !dateSet) {
          rtn =
            date_begin.toDateString() === date_end.toDateString() ? (
              <td key={i}>
                {date_begin.getDate()}/{date_begin.getMonth() + 1}/
                {date_begin.getFullYear()}
              </td>
            ) : (
              <td key={i}>
                {date_begin.getDate()}/{date_begin.getMonth() + 1}/
                {date_begin.getFullYear()} - {date_end.getDate()}/
                {date_end.getMonth() + 1}/{date_end.getFullYear()}
              </td>
            )
          dateSet = true
        } else if (element === 'content') {
          rtn = <td key={i}>{row.content && JSON.parse(row.content).join(', ')}</td>
        } else if (element === 'location') {
          rtn =
            row.location_begin === row.location_end ? (
              <td key={i}>{row.location_begin}</td>
            ) : (
              <td key={i}>
                Begin: {row.location_begin}, Einde: {row.location_end}
              </td>
            )
        } else if (element === 'date' && dateSet) {
          rtn =
            date_begin.toTimeString() === date_end.toTimeString() ? (
              <td key={i}>
                {date_begin.getHours()}:{date_begin.getMinutes()}u
              </td>
            ) : (
              <td key={i}>
                Begin: {date_begin.getHours()}:{date_begin.getMinutes()}u,
                Einde: {date_end.getHours()}:{date_end.getMinutes()}u
              </td>
            )
        } else if (element === 'price') {
          rtn = row.price ? (
            <td key={i}>{`\u20AC ${row.price}`}</td>
          ) : (
            <td key={i}>{``}</td>
          )
        } else {
          rtn = (
            <td key={i}>
              {row.required_items && JSON.parse(row.required_items).join(', ')}
            </td>
          )
        }

        return rtn
      })
    }
  }

  render() {
    return (
      <div className="container">
     <div  className="pull-right" style={{display:'flex'}}>


        {this.state.resData && this.state.resData.length > 0 ?
          <ExportPDFTable title="Planning Sea-scouts Depanne 15FOS t'Kraaienest"
            subtitle={this.props.match.params.tak}
            size="A4"
            marginLeft={40}
            orientation="landscape"
            headers={[this.state.headersDutch]}
            rows={this.state.resData}
            fieldNames={this.state.headers}
            unit="pt"

          /> : <></>}
           {this.state.authenticated ? (<Form.Check // prettier-ignore 
 style={{alignSelf:'center'
}}
 className="pull-right"
        type="switch"
        id="custom-switch"
        // checked={true}
        onChange={()=>this.updateview(this.state.showAll)}

        label="Toon alle activiteiten"
        // feedbackTooltip={true} 
      />
      ) : (
        <div />
      )}
          </div>
        <Table responsive>
          <thead>
            <tr>{this.createHeaders()}</tr>
          </thead>
          <tbody>{this.createRow()}</tbody>
        </Table>
        {this.state.authenticated ? (
          <Button
            style={{ margin: "10px" }}
            className="pull-right"
            size="sm"
            variant="success"
            onClick={() => {
              this.props.history.push(
                `/kalender/planning/add/${this.props.match.params.tak}`
              )
            }}
          >
            <FontAwesomeIcon className="fa" icon={faPlusSquare} />
            Activiteit toevoegen
          </Button>
        ) : (
          <div />
        )}
      </div>
    )
  }
}
