import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDay,
  faChevronUp,
  faChevronDown,
  faTrashAlt,
  faTimes,
  faQuestionCircle,
  faCalendarPlus,
} from '@fortawesome/free-solid-svg-icons'
import moment, { isMoment } from 'moment'
import UnAuthorized from '../../Pages/UnAuthorized'
import {
  Form,
  OverlayTrigger,
  Popover,
  Button,
  Row,
  Col,
  Alert,
  FormControl,
  InputGroup,
} from 'react-bootstrap'
import axios from 'axios'

import '../css/login.css'
import Calender from '../calendar/CustomCalenderActiviteiten'

import '../css/createCalenderActivity.css'
import './events.css'

export default class InputActivity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: {
        title: '',
        content: '',
        beginLocation: 'Lokaal',
        endLocation: '',
        beginHour: 14,
        beginMinute: 0,
        endHour: '',
        endMinute: '',
        beginDate: '',
        endDate: '',
        switchState: false,
        switchStateRegister: false,
        details: [],
        newDetail: '',
        eventForm: 1,
      },
      validated: false,
      comparePassword: false,
      errorResponse: '',
      showError: false,
      showCalendarBegin: false,
      showCalendarEnd: false,
      calendarErrorMessage: null,
      takOrEenheidsLeiding: true,
      switchLabel: 'Intern',
      switchLabelRegister: 'Nee',
      serverImage: null,
    }
  }

  async componentDidMount() {
    const self = this
    axios
      .post(
        `${process.env.REACT_APP_generalServer}api/events/check-editEventspageRole`,
        { body: false },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data)
        self.setState(
          {
            authorized: response.data.authenticated,
            publishable: response.data.publisher,
          }
        )
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })

    if (this.props.match.params.id) {
      this.getEventById()
    }



  }

  dateString2Moment = (date) => {

    return moment(new Date(date * 1000))
  }

  getEventById = () => {
    axios.get(`${process.env.REACT_APP_generalServer}api/events/${this.props.match.params.id}`,
      { withCredentials: true })
      .then((res) => {
        let details = []
        if (res.data && res.data.details)
          details = JSON.parse(res.data.details)
        if (typeof details === 'string') {
          details = [details]
        }
        let inputs = this.state.inputs
        inputs.title = res.data.title ? res.data.title : ''
        inputs.content = res.data.content ? res.data.content : ''
        inputs.beginLocation = res.data.beginLocation ? res.data.beginLocation : 'Lokaal'
        inputs.endLocation = res.data.endLocation ? res.data.endLocation : ''
        inputs.beginHour = res.data.beginDate ? this.dateString2Moment(res.data.beginDate).hours() : 14
        inputs.beginMinute = res.data.beginDate ? this.dateString2Moment(res.data.beginDate).minutes() : 0
        inputs.endHour = res.data.endDate ? this.dateString2Moment(res.data.endDate).hours() : ''
        inputs.endMinute = res.data.endDate ? this.dateString2Moment(res.data.endDate).minutes() : ''
        inputs.beginDate = res.data.beginDate ? this.dateString2Moment(res.data.beginDate).startOf('day') : ''
        inputs.endDate = res.data.endDate ? this.dateString2Moment(res.data.endDate).startOf('day') : ''
        inputs.switchState = res.data.public && res.data.public === 1 ? true : false
        inputs.switchStateRegister = res.data.eventForm ? true : false
        inputs.eventForm = res.data.eventForm ? res.data.eventForm : '1'
        inputs.details = details

        let serverImage = res.data.image ? `${res.data.image}` : ''

        this.setState({ inputs, serverImage })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  dateString2Date = (date) => {
    var b = date.split(/\D+/)
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  }



  handleSubmit2 = () => {
    let errorResponse = ''
    let { title, content, beginLocation, endLocation,
      beginHour, beginMinute, endHour, endMinute, beginDate,
      endDate } = this.state.inputs
    if (title === '') {
      errorResponse = 'Je bent verplicht een titel op te geven'
    }
    else if (content === '') {
      errorResponse = 'Je bent verplicht een evenement beschrijving op te geven'
    }
    else if ((!this.state.currentFigure && !this.state.serverImage) || (this.state.currentFigure && this.state.currentFigure.length === 0)) {
      errorResponse = 'Je bent verplicht een afbeelding op te geven'
    }
    else if (beginLocation === '') {
      errorResponse = 'Je bent verplicht een begin locatie op  te geven'
    }
    else if (endLocation === '' && (endHour !== '' || endMinute !== '' || isMoment(endDate))) {
      errorResponse = 'Wanneer een eind tijd (uur en/of minuten) of eind datum is opgegeven, ben je veplicht ook een eind locatie op te geven'
    }
    else if (beginHour === '') {
      errorResponse = 'Je bent verplicht een begin uur op te geven'
    }
    else if (beginMinute === '') {
      errorResponse = 'Je bent verplicht begin minuten op te geven'
    }
    else if (endHour === '' && (endLocation !== '' || endMinute !== '' || isMoment(endDate))) {
      errorResponse = 'Wanneer een eind tijd (minuten), een eindlocatie of eind datum is opgegeven, ben je veplicht ook een eind uur op te geven'
    }
    else if (endMinute === '' && (endLocation !== '' || endHour !== '' || isMoment(endDate))) {
      errorResponse = 'Wanneer een eind tijd (uur), een eindlocatie of eind datum is opgegeven, ben je veplicht ook eind minuten op te geven'
    }
    else if (beginDate === '') {
      errorResponse = 'Je bent verplicht begin datum op titel te geven'
    }
    else if (endDate === '' && (endLocation !== '' || endMinute !== '' || endHour !== '')) {
      errorResponse = 'Wanneer een eind tijd (uur en/of minuten) of eindlocatie is opgegeven, ben je veplicht ook een eind datum op te geven'
    }
    if (errorResponse === '') {

      this.sendData2Db()

    }
    else {

      this.setState({ errorResponse, showError: true })
    }

  }


  sendData2Db = async () => {

    let beginDate = moment(this.state.inputs.beginDate).add(
      this.state.inputs.beginHour, 'hours')

    let fd = new FormData()
    if (this.state.currentFigure) {
      fd.append(`image`, this.state.currentFigure)
    }
    else {
      fd.append(`image`, this.state.serverImage)
    }
    fd.append('title', this.state.inputs.title)
    fd.append('content', this.state.inputs.content)
    fd.append('beginDate', beginDate)
    fd.append(`beginLocation`, this.state.inputs.beginLocation)
    fd.append('public', this.state.inputs.switchState)

    let endDate = this.state.inputs.endDate

    if (isMoment(endDate)) {
      endDate = moment(this.state.inputs.endDate).add(
        this.state.inputs.endHour, 'hours').add(
          this.state.inputs.endMinute, 'minutes')

      fd.append('endDate', endDate)

    }
    if (this.state.inputs.endLocation !== '') {
      fd.append(`endLocation`, this.state.inputs.endLocation)

    }
    if (this.state.inputs.details.length >= 1) {
      for (let i = 0; i < this.state.inputs.details.length; i++) {
        fd.append(`details`, this.state.inputs.details[i])
      }

    }
    if (this.state.inputs.switchStateRegister === true) {
      fd.append(`eventForm`, this.state.inputs.eventForm)
    }

    let self = this
    let urlSufix = this.props.match.params.id ? `update/${this.props.match.params.id}` : 'add'
    await axios
      .post(
        `${process.env.REACT_APP_generalServer}api/events/${urlSufix}`,
        fd,
        { withCredentials: true }
      )
      .then((response) => {
        self.props.history.push('/admin/overview/evenementen')
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  popover = () => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Wie mag dit zien</Popover.Title>
      <Popover.Content>
        <p>
          Enkel de{' '}
          <strong>

            eenheidsleiding
          </strong>{' '}
          kan een evenement publiek maken
        </p>
        <p>
          Intern: dit bekend dat enkel ingelogde leiding dit evenement kan
          zien.
        </p>
        <p>
          Publiek: dit bekend dat iedereen (ook niet ingelogde bezoekers) dit
          evenement kan zien
        </p>
      </Popover.Content>
    </Popover>
  )

  popoverregister = () => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Wat doet dit</Popover.Title>
      <Popover.Content>
        <p>
          Indien een evenement niet louter informatief is, maar je ook mensen de kans wil geven om zich in te schrijven voor dit evenement, dan moet de deze schakelaar op ja zetten.
        </p>
        <p>
          Indien inschrijvingen gewenst zijn kan je uit een vast aantal inschrijvingsformulieren kiezen. Indien nodig, kunnen nieuwe formulieren aangemaakt worden.
        </p>

      </Popover.Content>
    </Popover>
  )

  popoverEnd = () => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Wanneer Vul je dit in</Popover.Title>
      <Popover.Content>
        <p> Enkel wanneer het evenement een eind datum/uur heeft, moet je dit in vulllen.</p>
        <p>
          Wanneer je dit invult zal er vermeld worden <strong>van</strong> XXX <strong>tot</strong> XXX
        </p>
        <p>
          Wanneer je dit leeg laat zal er vermeld worden  <strong>vanaf</strong> XXX
        </p>
      </Popover.Content>
    </Popover>
  )

  toggleSwitch = (e) => {
    if (this.state.takOrEenheidsLeiding) {
      let inputs = { ...this.state.inputs }
      inputs[e] = !inputs[e]
      if (inputs[e] === true && e === 'switchState') {
        this.setState({
          switchLabel: 'Publiek',
        })
      } else if (inputs[e] === false && e === 'switchState') {
        this.setState({
          switchLabel: 'Intern',
        })
      }
      else if (inputs[e] === true && e === 'switchStateRegister') {
        this.setState({
          switchLabelRegister: 'Ja',
        })
      }
      else if (inputs[e] === false && e === 'switchStateRegister') {
        this.setState({
          switchLabelRegister: 'Nee',
        })
      }
      this.setState({ inputs })
    }
  }
  changeHandler = (e) => {


    let name = e.target.name.split('-')
    let inputs = this.state.inputs
    if (name.length === 1) {

      if (name[0] === 'KeepLoggedIn') {
        inputs[name[0]] = !inputs[name[0]]
      } else {
        inputs[name[0]] = e.target.value
      }
    }
    else {

      inputs[name[0]][parseInt(name[1])] = e.target.value
    }

    this.setState({ inputs, comparePassword: false, showError: false })
  }

  renderIconGroup = (row) => {
    let IconStyle = { width: "25px", margin: "auto", cursor: "pointer", textAlign: "center" }
    return <div style={{ display: "flex", flexDirection: "row" }}>


      {row !== 0 ? <div style={IconStyle} onClick={() => this.swapTabs(row, -1)}>
        <FontAwesomeIcon
          size="sm"
          icon={faChevronUp}
        /></div> : <></>}
      {row !== this.state.inputs.details.length - 1 ? <div style={IconStyle} onClick={() => this.swapTabs(row, 1)}>
        <FontAwesomeIcon
          size="sm"
          icon={faChevronDown}
        /></div> : <></>}
      <div style={IconStyle} onClick={() => this.remove(row)}>
        <FontAwesomeIcon
          size="sm"
          icon={faTrashAlt}
        /></div>
    </div>

  }

  errorMessage = () => {
    return (
      <Alert
        variant="danger"
        onClose={() => this.setState({ showError: false })}
        dismissible
      >
        <p>{this.state.errorResponse}</p>
      </Alert>
    )
  }

  showCalendarError = () => {
    return this.state.calendarErrorMessage ? (
      <Alert
        variant="danger"
        onClose={() => this.closeCalendarErrorMessage()}
        dismissible
      >
        <p>{this.state.calendarErrorMessage}</p>
      </Alert>
    ) : (
      <div />
    )
  }


  showCalender = (e) => {
    let showCalendarBegin = false
    let showCalendarEnd = false

    if (e === 'showBegin') {
      showCalendarBegin = !this.state.showCalendarBegin
    } else {
      showCalendarEnd = !this.state.showCalendarEnd
    }

    this.setState(
      { showCalendarBegin, showCalendarEnd, calendarErrorMessage: null },
      () => { }
    )
  }

  updateDates = (date, begin_end) => {
    let inputs = this.state.inputs

    if (begin_end === 'begin') {
      if (date < moment()) {
        this.setState({
          calendarErrorMessage: 'Je kan geen datum in het verleden selecteren',
        })
        inputs.beginDate = ''
      } else {
        inputs.beginDate = date
        // inputs.endDate = date
        this.setState({
          calendarErrorMessage: null,
        })
      }
    } else {
      if (
        moment.isMoment(this.state.inputs.beginDate) &&
        date < this.state.inputs.beginDate
      ) {
        this.setState({
          calendarErrorMessage:
            'De eind datum moet later of gelijk zijn aan de begin datum',
        })
      } else if (!moment.isMoment(this.state.inputs.beginDate)) {
        this.setState({
          calendarErrorMessage: 'Selecteer eerst een begin datum',
        })
      } else {
        inputs.endDate = date
        this.setState({ calendarErrorMessage: null })
      }
    }
    this.setState({ inputs })
  }

  closeCalendarErrorMessage = () => {
    this.setState({ calendarErrorMessage: null })
  }



  addToDetails = () => {
    let inputs = this.state.inputs
    inputs.details.unshift(inputs.newDetail)
    inputs.newDetail = ''

    this.setState({ inputs })
  }

  swapTabs = (row, direction) => {
    let details = this.state.inputs.details
    var temporarily = details[row + direction];
    details[row + direction] = details[row]
    details[row] = temporarily
    this.setState({ details })
  }

  remove = (row) => {
    let details = this.state.inputs.details
    details.splice(row, 1)
    this.setState({ details })


  }

  ownPopoverEnd = () => {
    return <OverlayTrigger
      trigger={['focus', 'hover', 'clicked']}
      placement="right"
      overlay={this.popoverEnd()}
    >
      <FontAwesomeIcon
        size="sm"
        className="fa-left-marging-5px"
        icon={faQuestionCircle}
      />
    </OverlayTrigger>
  }

  ImgPreview = () => {

    return this.state && (this.state.currentFigureURL || this.state.serverImage) ?
      <div id='imgPreview'>
        <div className='header'>
          <p>Preview</p>
          <div onClick={() => { this.setState({ currentFigure: null, currentFigureURL: null, serverImage: null }) }}>
            <FontAwesomeIcon icon={faTimes} className='fa' color='white' /></div>
        </div>
        <div className='body'>
          {this.state.currentFigureURL ?
            <img src={this.state.currentFigureURL} alt="" /> :
            <img src={`${process.env.REACT_APP_generalServer}${this.state.serverImage}`} alt="" />}
        </div></div> : <></>
  }

  detailsList = () => {
    let self = this
    return <ul>{this.state.inputs && this.state.inputs.details && this.state.inputs.details.map(function (detail, i) {
      return <li key={i} style={{ display: 'flex' }}> <div>{self.state && self.state.editRow !== `detail-${i}` ?
        <div onClick={() => self.setState({ editRow: `detail-${i}`, previus: detail })}>
          {detail}
        </div> :
        <input
          name={`details-${i}`}
          autoFocus
          className="search-input"
          placeholder="Vul iets in..."
          value={detail}
          onChange={self.changeHandler}
          onKeyDown={self.onEnterClick}
          onBlur={() => self.setState({ editRow: null })}
        />}</div> {self.renderIconGroup(i)} </li>

    })}</ul>
  }

  clearDate = (e) => {
    let inputs = this.state.inputs
    if (e.keyCode === 8 || e.keyCode === 46) {
      inputs[e.target.name] = ''
      this.setState({ inputs })
    }
  }

  changeFileHandeler = (e) => {
    var file = e.target.files
    if (e.target.files.length > 0) {
      var url = window.URL.createObjectURL(file[0])
      this.setState({ currentFigure: file[0], currentFigureURL: url, errorMessage: '' })
    }
    //this wil reset the current value of the form file. 
    // meaning that one image can be added twice ore more after eatch other
    e.target.value = null
  }

  render() {


    // if (typeof this.state.authorized === 'undefined') {
    //   return <div />
    // }
    // else
    if (!this.state.authorized) {
      return <UnAuthorized />
    }
    else {
      return (
        <div>
          <div className="container addicalender-item-container justify-content-md-center">
            <Form>
              <div className="login-title">
                {this.props.match.params.id ?
                  <b>
                    {`Evenement Bijwerken`}
                  </b> :
                  <b>
                    {` Nieuw evenement`}
                  </b>}
              </div>
              {this.state.showError ? this.errorMessage() : <div />}
              <Form.Group
                as={Row}
                className="title-margin"
                controlId="EventTitle"
              >
                <Form.Label column xs={3} md={2}>
                  Titel*
                </Form.Label>
                <Col xs={9} md={10}>
                  <Form.Control
                    name="title"
                    value={this.state.inputs.title}
                    type="text"
                    // as="textarea"
                    // rows="3"
                    placeholder="Titel van dit evenement"
                    onChange={this.changeHandler.bind(this)}
                  />
                </Col>
              </Form.Group>{' '}
              <Form.Group
                as={Row}
                className="title-margin"
                controlId="formFirstName"
              >
                <Form.Label column xs={3} md={2}>
                  Evenement beschrijving*
                </Form.Label>
                <Col xs={9} md={10}>
                  <Form.Control
                    name="content"
                    value={this.state.inputs.content}
                    type="text"
                    as="textarea"
                    rows="8"
                    placeholder="wat gaat de activiteit inhouden?"
                    onChange={this.changeHandler.bind(this)}
                  />
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row}
                className="title-margin"
                controlId="formDetails" >
                <Form.Label column xs={3} md={2}>Details</Form.Label>
                <Col xs={9} md={10}>
                  <InputGroup md="3" style={{ marginBottom: '2px' }}>
                    <FormControl
                      placeholder="Voeg eventueel extra details toe"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name='newDetail'
                      value={this.state.inputs.newDetail}
                      onChange={this.changeHandler}
                    />
                    <InputGroup.Append>
                      <Button variant="outline-secondary" onClick={() => this.addToDetails()}>Voeg toe</Button>
                    </InputGroup.Append>
                  </InputGroup>

                  <this.detailsList />

                </Col>


              </Form.Group>



              <Form.Group as={Row}
                className="title-margin"
                controlId="formDetails" >
                <Form.Label column xs={3} md={2}>Afbeelding*</Form.Label>
                <Col xs={9} md={5}>
                  <Form.File
                    id="custom-file"
                    label='Voeg afbeelding toe'
                    name="fileName"
                    data-browse="Afbeelding kiezen"
                    onChange={this.changeFileHandeler.bind(this)}
                    custom
                  />

                </Col>
                <Col xs={{ span: 9, offset: 3 }} md={{ span: 5, offset: 0 }}>
                  <this.ImgPreview />
                </Col>


              </Form.Group>


              <Form.Group as={Row}>
                <Form.Label column xs={3} md={2}>
                  Weergave
                  <OverlayTrigger
                    trigger={['focus', 'hover', 'clicked']}
                    placement="right"
                    overlay={this.popover()}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      className="fa-left-marging-5px"
                      icon={faQuestionCircle}
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Col xs={9} md={10} className="align-switch">
                  <Form.Check
                    checked={this.state.inputs.switchState}
                    name='switchState'
                    type="switch"
                    id="custom-switch"
                    disabled={!this.state.publishable}
                    label={this.state.switchLabel}
                    // onChange={this.toggleSwitch}
                    onClick={() => this.toggleSwitch('switchState')}
                  />
                </Col>
              </Form.Group>{' '}

              <Form.Group as={Row}>
                <Form.Label column xs={3} md={2}>
                  Inschrijven
                  <OverlayTrigger
                    trigger={['focus', 'hover', 'clicked']}
                    placement="right"
                    overlay={this.popoverregister()}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      className="fa-left-marging-5px"
                      icon={faQuestionCircle}
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Col xs={2} md={1} >
                  <Form.Check
                    checked={this.state.inputs.switchStateRegister}
                    name='switchStateRegister'
                    type="switch"
                    id="custom-switch2"
                    disabled={!this.state.publishable}
                    label={this.state.switchLabelRegister}
                    // onChange={this.toggleSwitch}
                    onClick={() => this.toggleSwitch('switchStateRegister')}
                  />
                </Col>
                {this.state.inputs.switchStateRegister && this.state.inputs.switchStateRegister ?
                  <Col xs={7} md={4} >
                    <Form.Group controlId="exampleForm.ControlSelect2">
                      <Form.Label>Selecteer een Inschrijvingsformulier</Form.Label>
                      <Form.Control as="select" name='eventForm'
                        onChange={this.changeHandler}
                        value={this.state.inputs['eventForm']}>
                        <option value='1'>Eten volw./kind, vlees/vegi.</option>

                      </Form.Control>
                    </Form.Group>
                  </Col> : <></>}
              </Form.Group>{' '}
              <Form.Group as={Row} controlId="formLocation">
                <Form.Label column xs={3} md={2}>
                  Locatie
                </Form.Label>
                <Form.Label column xs={2} md={1}>
                  Begin*
                </Form.Label>
                <Col xs={7} md={4}>
                  <Form.Control
                    name="beginLocation"
                    value={this.state.inputs.beginLocation}
                    type="text"
                    placeholder="bv: Lokaal"
                    onChange={this.changeHandler.bind(this)}
                  />
                </Col>
                <Form.Label column xs={{ span: 2, offset: 3 }} md={{ span: 1, offset: 0 }}>
                  Einde <this.ownPopoverEnd />
                </Form.Label>
                <Col xs={7} md={4}>
                  <Form.Control
                    name="endLocation"
                    value={this.state.inputs.endLocation}
                    type="text"
                    placeholder="bv: Lokaal"
                    onChange={this.changeHandler.bind(this)}
                  />
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row} controlId="formTime">
                <Form.Label column xs={3} md={2}>
                  Tijdstip
                </Form.Label>
                <Form.Label column xs={2} md={1}>
                  Begin*
                </Form.Label>
                <Col xs={7} md={4}>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        name="beginHour"
                        value={this.state.inputs.beginHour}
                        type="number"
                        placeholder="uur"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                    :
                    <Col>
                      <Form.Control
                        name="beginMinute"
                        value={this.state.inputs.beginMinute}
                        type="number"
                        placeholder="minuten"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                  </Form.Row>
                </Col>

                <Form.Label column xs={{ span: 2, offset: 3 }} md={{ span: 1, offset: 0 }}>
                  Einde  <this.ownPopoverEnd />
                </Form.Label>

                <Col xs={7} md={4}>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        name="endHour"
                        value={this.state.inputs.endHour}
                        type="number"
                        placeholder="uur"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                    :
                    <Col>
                      <Form.Control
                        name="endMinute"
                        value={this.state.inputs.endMinute}
                        type="number"
                        placeholder="minuten"
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Group>{' '}
              <Form.Group as={Row}>
                <Form.Label column xs={3} md={2}>
                  Datum
                </Form.Label>
                <Form.Label column xs={2} md={1}>
                  Begin*
                </Form.Label>
                <Col xs={7} md={4}>
                  <InputGroup style={{ marginBottom: '5px' }}>
                    <FormControl
                      placeholder="Selecteer een datum"
                      readOnly
                      name="beginDate"
                      onKeyDown={this.clearDate}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={
                        moment.isMoment(this.state.inputs.beginDate)
                          ? this.state.inputs.beginDate.format('DD/MM/YYYY')
                          : this.state.inputs.beginDate
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        name="showBegin"
                        variant="outline-secondary"
                        onClick={() => {
                          this.showCalender('showBegin')
                        }}
                      >
                        <FontAwesomeIcon className="fa" icon={faCalendarDay} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.state.showCalendarBegin ? (
                    <div
                      style={{
                        position: 'relative',
                        right: '0px',
                        float: 'right',
                      }}
                    >
                      {this.showCalendarError()}
                      <Calender
                        classname="ustify-content-md-right"
                        style={{
                          position: 'relative',
                          right: '0px',
                          float: 'right',
                        }}
                        selectDateAllowed={true}
                        showReservations={false}
                        showErrorMesage={false}
                        selectRange={false}
                        width="302px"
                        selectedDates={(begin) => {
                          this.updateDates(begin, 'begin')
                        }}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                </Col>
                <Form.Label column xs={{ span: 2, offset: 3 }} md={{ span: 1, offset: 0 }}>
                  Einde <this.ownPopoverEnd />
                </Form.Label>
                <Col xs={7} md={4}>
                  <InputGroup style={{ marginBottom: '5px' }}>
                    <FormControl
                      placeholder="selecteer een datum"
                      readOnly
                      name="endDate"
                      onKeyDown={this.clearDate}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={
                        moment.isMoment(this.state.inputs.endDate)
                          ? this.state.inputs.endDate.format('DD/MM/YYYY')
                          : this.state.inputs.endDate
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        name="showEnd"
                        variant="outline-secondary"
                        onClick={() => {
                          this.showCalender('showEnd')
                        }}
                      >
                        <FontAwesomeIcon className="fa" icon={faCalendarDay} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.state.showCalendarEnd ? (
                    <div
                      style={{
                        position: 'relative',
                        right: '0px',
                        float: 'right',
                      }}
                    >
                      {this.showCalendarError()}
                      <Calender
                        style={{
                          position: 'relative',
                          right: '0px',
                          float: 'right',
                        }}
                        classname="ustify-content-md-right"
                        selectDateAllowed={true}
                        showReservations={false}
                        showErrorMesage={false}
                        selectRange={false}
                        width="302px"
                        selectedDates={(end) => {
                          this.updateDates(end, 'end')
                        }}
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                </Col>
              </Form.Group>{' '}
              <div className="align-center">
                <Button size="sm" onClick={this.handleSubmit2.bind(this)}>
                  <FontAwesomeIcon className="fa" icon={faCalendarPlus} />
                  {this.props.match.params.id ? "Evenement Bijwerken" :
                    "Activiteit toevoegen"}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )
    }
  }
}
