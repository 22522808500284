import React, { Component } from 'react'
import {Modal,Button, Spinner} from "react-bootstrap"
import "./doubleCheckModal.css"

export default class doubleCheckModal extends Component {
    handleClose = () => {
        this.props.closeAction()
      }
    handleAgree = () => {
        this.props.agreeAction()
      }

    render() {
        return (
            <Modal
            animation={false}
        size={this.props.size?this.props.size:"md"}
        show={this.props.message!==""}
        onHide={this.handleClose}
        backdrop={this.props.backdrop?this.props.backdrop:false}
        keyboard={false}
      >
          {this.props.header?
        <Modal.Header closeButton>
          <Modal.Title>{this.props.header}</Modal.Title>
        </Modal.Header>:<></>}
        <Modal.Body >
          {this.props.message}
        </Modal.Body>
        <Modal.Footer>
         {!this.props.onlyButtonTrue?<Button variant="secondary" onClick={this.handleClose}>
            {this.props.buttonFalse?this.props.buttonFalse:"Close"}
          </Button>:<></>}
          <Button variant="primary" onClick={this.handleAgree}>
          {this.props.waiting?<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />:<></>}
          {this.props.buttonTrue?this.props.buttonTrue:"Understood"}</Button>
        </Modal.Footer>
      </Modal>
        )
    }
}
