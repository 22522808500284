import React, { Component } from 'react'
// import RentingReservationForm from '../../components/RentingReservationForm/RentingReservationForm'
import {  InputGroup, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPhoneAlt,  faAt } from '@fortawesome/free-solid-svg-icons'

export default class reseveerIU extends Component {
  render() {
    return (
      <div >
        {/* <RentingReservationForm props={this.props} /> */}
        {/* <div></div> */}
        <div className="container">
          <div style={{ marginTop: '20px' }}>
            <p>Indien u het gebouw wenst te bezoeken of te reserveren, gelieve contact op te nemen met Katrien Vantournhout</p>


            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon className="fa" icon={faAt} />
                </InputGroup.Text>
              </InputGroup.Prepend>

              <Form.Control
                name="phoneNumber"
                value='katrienvantournhout@msn.com'
                type="text"
                placeholder="number"
                aria-describedby="inputGroupPrepend"
                // onChange={this.changeHandeler}
                disabled
              />
            </InputGroup>
            <p></p>

            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  <FontAwesomeIcon className="fa" icon={faPhoneAlt} />
                </InputGroup.Text>
              </InputGroup.Prepend>

              <Form.Control
                name="phoneNumber"
                value='0475/50.44.04'
                type="text"
                placeholder="number"
                aria-describedby="inputGroupPrepend"
                // onChange={this.changeHandeler}
                disabled
              />
            </InputGroup>
          </div>
        </div>
      </div>
    )
  }
}
