import React, { Component } from 'react'
import {Col, Button} from 'react-bootstrap'

export class features extends Component {
  render() {

    
    return (
      <div id="features" className="text-center">
        <div className="container" style={{padding:0}}>
          <Col md ={{span:10,offset:1}} className="section-title">
            <h2>Activiteiten</h2>
          </Col>
          <div className="row" >
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <Col key={`${d.title}-${i}`} xs={6} sm={6} md={6} lg={3}  style={{paddingBottom: '20'}}>
                    {/* <div key={`${d.title}-${i}`} className="col-xs-6 col-sm-6 col-md-6 col-lg-3  "> */}
                    {' '}
                   
                    <div className="features-image">
                      <img src={d.img} alt="" />
                    </div>
                  
                    <h3>{d.title}</h3>
                    <p style={{textAlign:"justify", textJustify: "inner-word",margin:"0 5px"}}>{d.text}</p>
                  </Col>
                ))
              : 'Loading...'}
          </div>
          <p style={{marginTop:"25px"}}>Benieuwd naar de geplannde activiteiten? Bekijk dan snel de plannening!</p>
          <Button  size="lg" 
                    style={this.props.blue_button_style} 
                    onClick={() => this.props.history.push('/kalender')}> Planning</Button>
        </div>
      </div>
    )
  }
}

export default features
