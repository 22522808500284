import React, { Component } from 'react'
import "./main.css"

export default class policyPage extends Component {
  render() {
    return <div id="container"><h2>Privacyverklaring</h2>
    <p>
    <i>15e Fos 't Kraaienest Privacyverklaring</i><br/>
    <i>Deze privacyverklaring werd het laatst bijgewerkt op 6 juli 2023.</i></p>
    <p>In deze privacyverklaring wordt uitgelegd hoe de persoonsgegevens
         van u en/of uw kind(eren) worden verzameld, gebruikt en gedeeld bij het gebruik van deze website. 
         Indien u verdere vragen heeft omtrent deze 
         privacyverklaring, gelieve de eenheidsleiding te contacteren. 
        </p>

        <h4>Gebruik van uw persoonlijke informarie</h4>
        Uw persoonlijke informatie voor de volgende doeleinden gebruikt:
        <ol>
                <li><p>Algemene ledenadministratie, waaronder het beheren van ons ledenbestand en 
                     lidgelden, en inschrijvingen voor en organiseren van activiteiten,
                    </p></li>
                <li>Verzekeringen,</li>
                <li>Communicatie over onze activiteiten,</li>
                <li>Het beheer van foto's van onze activiteiten,</li>
                <li>Het aanvragen van subsidies.</li>
                We verzenden geen reclame, maar gebruiken intensief e-mail en sociale media om u op de hoogte te stellen van onze activiteiten.
            </ol>



        <h4>Delen van uw persoonlijke informatie</h4>
        Uw gegevens kunnen mogelijks gedeeld worden met volgende parijen:
        <ol>
        <li><p>FOS Open Scouting vzw (<a href="https://fosopenscouting.be/">fosopenscouting.be</a>) , onze koepelorganisatie, 
            met het oog op hun ledenadministratie en de verzekeringen 
            waarvan wij gebruik maken;</p></li>
        <li><p>Onze verzekeringsmaatschappijen, zoals de verzekeringsmakelaar Cornelis en Partners en de verzekeringsmaatschappij Arena</p></li>
        <li><p>Een arts of apotheek, indien u of uw kind op een activiteit, weekend of kamp (dringende) 
            medische bijstand nodig heeft (u wordt steeds geinformeerd wanneer uw gegvens gedeeld worden);</p></li>
        <li><p>De (jeugd)verblijven waar we op kamp gaan, indien dit vereist wordt;</p></li>
        <li><p>Het gemeentebestuur van De Panne, bijvoorbeeld wanneer vereist bij 
            het indienen van een subsidiedossier (bv. tegemoetkoming voor opleidingen);</p></li>
       

        </ol>
        <p>Indien we gegevens met andere partijen moeten delen, beperken we dit tot het hoogstnoodzakelijke.
        Foto's van uw kind(eren) kunnen mogelijks (mids voorafgaande algemene goedkeuring) gepubliceerd worden op onze website, sociale
         media kanalen en publicaties. Deze foto's zijn publiek toegankelijk. 
         Indien u een foto wilt laten verwijderen, gelieve de eenheidsleiding te contacteren.</p>


        <h4>Cookies</h4>
        <p>Cookies zijn bestanden met een kleine hoeveelheid gegevens, die anonieme unieke identificatiecode kunnen bevatten. Cookies worden vanaf een website naar uw browser gestuurd en 
            opgeslagen op de harde schijf van uw computer of ander multimedia apparaat (Smartphone,tablet, etc.)<br/>
            Wij gebruiken uitsluitend noodzakelijke "cookies". Deze cookies zijn nodig voor een goede werking van onze website,
             zodat u de belangrijkste functies kunt gebruiken en vlot kunt navigeren. 
             Dit omvat essentiële beveiligings- en toegankelijkheidsfuncties.
            U kunt uw browser de opdracht geven om alle cookies te weigeren of om aan te geven wanneer een cookie wordt verzonden. 
            Lees ons <a   href="/cookie-policy">cookiebeleid</a> voor meer info.
            {/* Als u echter geen cookies accepteert, is het mogelijk dat u sommige delen van onze service niet kunt gebruiken. */}
            </p>

        <h4>Opslaan van gegeven</h4>
        <p>Wanneer u uw gegevens invult op onze website, 
            bewaren wij uw gegevens voor onze administratie, tenzij en totdat u ons verzoekt deze informatie te verwijderen.</p>


        <h4>Minderjarigen</h4>
        <p>Deze website is bedoeld om door zowel ouders als leden geraadpleegd te worden. Er is dus geen minumum leeftijd gedefiniered voor deze website.</p>

        <h4>Gegevens toegang</h4>
        <p>Niet iedereen kan zomaar alle verstreken gegevens Raadplegen. Autorisatie om gegevens te raadplegen is gebaseerd op rollen.
             Dit houdt in dat de rechten tot toegang worden beperkt de leid(st)ers en comitéleden die die gegevens nodig hebben 
             voor het vervullen van hun taken binnen de eenheid of comité.</p>
        
        
        
        
        <h4>Data Security</h4>
        <p>Wij treffen passende organisatorische, technische en administratieve maatregelen om onder onze toezicht verzamelde persoonsgegevens te beschermen. 
            Helaas kan op het internet nooit worden gegarandeerd dat data verzendingen en opslag ervan 100% veilig is. 
            Indien u redenen hebt om aan te nemen dat de aan ons vertreken infromatie niet meer veilig is 
            (U vindt bijvoorbeeld dat de veiligheid van een account die u bij ons hebt in gevaar is gebracht),
             moet u ons onmiddelijk in kennisstellen door contact met de eenheidsleiding op te nemen.</p>



        <h4>Pagina Wijzigingen</h4>
        <p>Wij kunnen deze privacyverklaring op elk moment wijzigen. 
            Deze wijzigingen kunnen doorgevoerd worden omwille van wijsigingen in onze praktijken of andere operationele, wettelijke of regelgevende redenen
            Wijzigingen in deze privacyverklaring worden van kracht wanneer we de herziene privacyverklaring op de site publiceren.
            </p>

    	<h4>Contacteer ons</h4>
        <p>Indien u bijkomende vragen hebt of een klacht wenst in te dienen, kunt u ons 
            contacteren via het email, telefonisch  of per post:</p>
            <ol>
                <li>scoutsdepanne@gmail.com</li>
                <li>+32 487 37 11 00</li>
                <li>sportlaan 10, 8660 De panne</li>
                
            </ol>
            


    
    </div>
  }
}
