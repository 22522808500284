import React, { Component } from 'react'
import { Row,Button} from 'react-bootstrap'
import  './mainpage.css'

export class verhuur extends Component {
  render() {

    
    return (          
        <div >
        <div className="container">
        <div className="section-title-md-lg-xl text-center">
            <h2>Verhuur</h2>
          </div>
       
          <Row id="verhuur" >
    <div className="col-xs-12 col-lg-6 text-center">  {this.props.data ?<img src={this.props.data.img} className="img-responsive" style={{margin: "auto", marginTop:"15px", marginBottom:"0px"}} alt=""/> :<div/>}</div>
            <div className="col-xs-12 col-lg-6" style={{marginBottom:"0px"}}>
            <div className="about-text">
              <div className="about-text-xs-sm " >
                <h2>Verhuur</h2>
                </div>
                <p>{this.props.data ? this.props.data.paragraph1 : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.paragraph2 : 'loading...'}</p>
                <div style={{float:'right'}}>
                  <Button  size="lg" 
                    style={this.props.blue_button_style} 
                    onClick={() => this.props.history.push('/verhuur')}> Details</Button>
                    </div>
                
      </div>
            </div>
          </Row>
        </div>
      </div>
    )
  }
}

export default verhuur
