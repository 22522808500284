import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt} from '@fortawesome/free-solid-svg-icons'

const GenText = (props) => <p>{props.text}</p>

export default class planningen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createdBy: [],
      headersDutch: [
        '#',
        'Tak',
        'Datum',
        'Activiteit',
        'Locatie',
        'Tijdstip',
        'Prijs',
        'Speciale Benodigdheden',
        'Aangemaakt door',
        'aanmaakDatum',
      ],
      headers: [
        'tak',
        'date',
        'content',
        'location',
        'time',
        'price',
        'benodigdheden',
        'created_by',
      ],
    }
  }

  async componentDidMount() {
    const self = this
    //check if user has admin rights
    await axios
      .get(`${process.env.REACT_APP_authServer}api/members/check-roles`, {
        withCredentials: true,
      })
      .then((response) => {
        self.setState({ authorized: true })
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          self.setState({ authorized: false })
        }
        console.log(error)
      })

    let resData = await axios
      .get(`${process.env.REACT_APP_generalServer}api/activities/all`)
      .then((response) => {
        if (response.data) {
          this.setState({ resData: response.data })
          return response.data
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          this.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
    this.setState({ resData })
    // this.get_CreatedBy(resData)
  }

  getData = () => {
    let self = this
    axios
      .get(`${process.env.REACT_APP_generalServer}api/activities/all`)
      .then((response) => {
        if (response.data) {
          self.setState({ resData: response.data })
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          self.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
  }

  text(text) {
    return text.map(function (text, i) {
      return <GenText text={text} key={i} />
    })
  }

  dateString2Date = (date) => {
    var b = date.split(/\D+/)
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  }

  checkStartEnd = () => {
    let areEqual = true
    if (this.state.resData) {
      for (var i = 0; i < Object.keys(this.state.resData).length; i++) {
        areEqual =
          areEqual === false ||
          (this.state.resData[i].location.begin &&
            this.state.resData[i].location.end &&
            this.state.resData[i].location.begin !==
              this.state.resData[i].location.end)
            ? false
            : true
        // takNamen.push(Object.keys(this.state)[i])
      }
    }
  }

  checkRequiredHeaders = () => {
    let checkitems = [
      'date',
      'content',
      'location',
      'time',
      'price',
      'benodigdheden',
    ]
    let resulst = []

    if (this.state.resData) {
      for (var i = 0; i < Object.keys(this.state.resData).length; i++) {
        for (var j = 0; j < checkitems.length; j++) {
          resulst[j] =
            resulst[j] === true || this.state.resData[i][checkitems[j]]
              ? true
              : false
        }
      }
    }
    var headersDutch = this.state.headersDutch.filter(function (_, i) {
      return resulst[i] === true
    })
    var headers = checkitems.filter(function (_, i) {
      return resulst[i] === true
    })
    this.setState({ headers: headers, headersDutch: headersDutch })
  }
  createHeaders = () => {
    return this.state.headersDutch.map(function (item, i) {
      return <th key={i}>{item}</th>
    })
  }

  createRow = () => {
    let self = this

    if (this.state.resData) {
      return this.state.resData.map(function (data, i) {

        return (
          <tr key={i}>
            <td>{i}</td> <td>{data.tak}</td>
            <td>
              Begin: {self.dateString2Date(data.date.begin).getDate()}/
              {self.dateString2Date(data.date.begin).getMonth()}/
              {self.dateString2Date(data.date.begin).getFullYear()} Einde:{' '}
              {self.dateString2Date(data.date.end).getDate()}/
              {self.dateString2Date(data.date.end).getMonth()}/
              {self.dateString2Date(data.date.end).getFullYear()}
            </td>{' '}
            <td>{data.content.join(' ')}</td>
            <td>
              Begin: {data.location.begin} Einde: {data.location.end}
            </td>{' '}
            <td>
              Begin: {self.dateString2Date(data.date.begin).getHours()}:
              {self.dateString2Date(data.date.begin).getMinutes()} Einde:{' '}
              {self.dateString2Date(data.date.end).getHours()}:
              {self.dateString2Date(data.date.end).getMinutes()}
            </td>{' '}
            <td>{data.price}</td>
            <td>{data.Required_items && data.Required_items.join(', ')}</td>
            {/* {self.get_CreatedBy(created_by)} */}
            {/* <td>{self.state.createdBy[i]}</td> */}
            <td>{data.date.begin}</td>
            <td>{""}</td>
            <td><Button
                  variant="danger"
                  onClick={() => self.deleteCalenderItem(data._id && data._id)}
                >
                  <FontAwesomeIcon className="fa" icon={faTrashAlt} />
                  delete
                </Button></td>
          </tr>
        )
      })
    }
  }

  deleteCalenderItem = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_generalServer}api/activities/${id}`
      )
      .then((response) => {
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  createRowItem = (i) => {
    if (this.state.headers) {
      return this.state.headers.map(function (element, i) {
        let rtn = null
        // rtn =

        return rtn
      })
    }
  }

  async get_CreatedBy() {
    let resData = await axios
      .get(
        `${process.env.REACT_APP_authServer}api/activities/tak/${this.props.match.params.tak}`
      )
      .then((response) => {
        if (response.data) {
          this.setState({ resData: response.data })
          return response.data
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          this.setState({ errorResponse: error.response.data })
        } else {
          console.log(error)
        }
      })
    this.checkRequiredHeaders(resData)
  }

  render() {
    if (!this.state.authorized) {
      return <div />
    } else {
      return (
        <div>
          <Table responsive="sm">
            <thead>
              <tr>{this.createHeaders()}</tr>
            </thead>
            <tbody>{this.createRow()}</tbody>
          </Table>
        </div>
      )
    }
  }
}
