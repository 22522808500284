import React, { Component } from 'react'
import { Row,Col} from 'react-bootstrap'

export class about extends Component {

  render() {
    return (
      <div id= "takken">
        <div className="container">
          <div className="section-title text-center">
            <h2>Takken</h2>
            <p>
              kinderen vanaf 6 jaar zijn bij ons van harte welkom.
              Afhankelijk van de leeftijd kom je in een bepaalde tak terecht
            </p>
          </div>
          <div>
            {this.props.data
              ? this.props.data.map((data, i) => (
      
                 <Row key={`${data.tak}-${i}`} style={{paddingTop:35, paddingBottom:35}}>
                  
                    {/* <div className='takImg-xs-sm'> */}
                        <Col xs={12} lg={6} className="takImg-xs-sm   text-center">
                          {' '}
                          <img
                            src={data.tak_image}
                            className="img-responsive"
                            alt=""
                          />{' '}
                        </Col>
                     
                   {/* </div> */}
                   
                    {/* <div className='takImg-md-lg-xl'> */}
                    {i % 2 === 0 ? (
                        <Col xs={12} lg={6} className="takImg-md-lg-xl   text-center">
                          {' '}
                          <img
                            src={data.tak_image}
                            className="img-responsive"
                            alt=""
                          />{' '}
                        </Col>
                    ) : (
                      <div/>
                    )}
                    {/* </div> */}
                    <Col xs={12} lg={6} >
                      <div className="about-text">
                      
                        <h2>{data.tak} </h2>
                        <div>{data.tak_titel?<b>{data.tak_titel} </b>:<></>} </div>
                        <div>{data.key_words?<p style={{ paddingTop:"10px"}}><i>{data.key_words}</i> </p>:<></>} </div>
                        <p style={{  whiteSpace:"pre-line"}}> 
                          {data.paragraph}
                        </p>
                        {/* <h3>Why Choose Us?</h3>
                        <Row className="list-style">
                          <div className="col-lg-6 col-sm-6 col-xs-12">
                            <ul>
                              {data.Why.map((d, i) => (
                                <li key={`${d}-${i}`}>{d}</li>
                              ))}
                            </ul>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-xs-12">
                            <ul>
                              {data.Why2.map((d, i) => (
                                <li key={`${d}-${i}`}> {d}</li>
                              ))}
                            </ul>
                          </div>
                        </Row> */}
                      </div>
                    </Col>
                   
                    {/* <div className='takImg-md-lg-xl'> */}
                    {i % 2 !== 0 ? (
                     
                        <Col xs={12} lg={6} className="takImg-md-lg-xl   text-center">
                          {' '}
                          <img
                            src={data.tak_image}
                            className="img-responsive"
                            alt=""
                          />{' '}
                        </Col>
                      
                    ) : (
                      <div />
                    )}
                    {/* </div> */}
                    
                  </Row >
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }
}

export default about


