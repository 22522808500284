import React, { Component } from 'react'
import './singleDateCalendar.css'
import moment from "moment"

export default class singleDateCalendar extends Component {
    render() {
        let { from, till } = this.props
        let months = moment.months()
        let weekdays = moment.weekdays(true)

        let from_time = moment.unix(from).minutes() === 0 ? moment.unix(from).format('HH') : moment.unix(from).format('HH:mm')
        let till_time = moment.unix(till).minutes() === 0 ? moment.unix(till).format('HH') : moment.unix(till).format('HH:mm')

        return (
            from ?
                // <div className="col-lg-4 col-sm-4 col-xs-5" style={{ marginTop:"auto"}}>
                <div style={{ marginTop: "auto" }}>
                    <p style={{ fontSize: 'small', fontWeight: 'normal', marginBottom: 5 }}>{this.props.header ? this.props.header : ""}</p>
                    <div id="SDCalendar" style={{ width: 160, margin: "auto" }}>

                        <div className="calendar-container">
                            <div className="calendar-header">
                                {this.props.from ? months[moment.unix(from).month()] : ''}
                            </div>
                            <p style={{ margin: "0px", lineHeight: "30px", color: "black" }}>  {from ? moment.unix(from).date() : ''}</p>
                            <p style={{ fontSize: 'small', fontWeight: 'normal', lineHeight: "18px", margin: "0px", color: "black" }}> {from ? weekdays[moment.unix(from).weekday()] : ""}</p>

                            {from && till ?
                                <p style={{ fontSize: 'small', fontWeight: 'normal', lineHeight: "18px", margin: "0px", color: "black", marginBottom: "10px" }}>{`van ${from_time}u tot ${till_time}u`} </p> :
                                from ?
                                    <p style={{ fontSize: 'small', fontWeight: 'normal', lineHeight: "18px", margin: "0px", color: "black", marginBottom: "10px" }}>{`vanaf ${from_time}u`} </p> : <></>}

                        </div>
                    </div>
                </div> : <></>
        )
    }
}
