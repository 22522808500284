import React, { Component } from 'react'
import Mainpage from '../Pages/mainPage/mainpage'

export default class mainPage extends Component {
    render() {
        return (
            <div>
                <Mainpage history={this.props.history}/>
            </div>
        )
    }
}
