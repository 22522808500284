import React, { Component } from 'react'
import { Form, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './verhuur.css'
import {
    faTrashAlt,
    faChevronUp,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons'

export default class troeven extends Component {
    constructor() {
        super();
        this.state = {
            elementFocus: null,
            newItem: "",

        }
    }



    changeHandler = (e) => {
        this.props.updateAction({ name: e.target.name, value: e.target.value })
    }
    changeNewItem = (e) => {
        this.setState({ newItem: e.target.value, elementFocus: null })
    }
    addNewItwm = (e) => {
        this.props.addAction({ value: e.target.value })
        this.setState({ elementFocus: null, newItem: "" })
    }
    onEnterClickNewItem = (e) => {
        if (e.keyCode === 13) {
            this.addNewItwm(e)
        }
        if (e.keyCode === 27) {
            this.setState({ elementFocus: null, newItem: "" })
        }
    }
    onEnterClick = (e) => {

        if (e.keyCode === 13 || e.keyCode === 27) {
            this.setState({ elementFocus: null, newItem: "" })
        }
    }

    removeItem = (e) => {
        this.props.removeAction({ value: e })
    }
    swapItems = (e, direction) => {
        this.props.swapAction({ item: e, direction })
        // [arr[0], arr[1]] = [arr[1], arr[0]];
    }

    render() {
        let self = this
        return (
            <div >
                <div onClick={() => { if (self.props.editMode) { self.setState({ elementFocus: `troef-title` }) } }}>
                    {this.state.elementFocus === `troef-title` ? <Form.Control
                        autoFocus type="text"
                        value={this.props.itemTitle}
                        name={`itemTitle`}
                        onChange={self.changeHandler}
                        onKeyDown={self.onEnterClick.bind(this)}
                        placeholder="Enter titls" />
                        : <b>{this.props.itemTitle}</b>}
                </div>
                <div id="container" style={{paddingTop:0}}>
           
                <ol>
                    {this.props.items && this.props.items.map(function (item, i) {
                        return (
                            self.state.elementFocus === `troef-${i}` && self.props.editMode ? <Form.Control
                                autoFocus type="email"
                                value={item}
                                name={`itemList-${i}`}
                                onChange={self.changeHandler}
                                onBlur={() => self.setState({ elementFocus: null, newItem: "" })}

                                onKeyDown={self.onEnterClick.bind(this)}
                                placeholder="Enter email" />
                                :
                                

                                <li key={i} >
                                    <p
                                    onClick={() => { if (self.props.editMode) { self.setState({ elementFocus: `troef-${i}` }) } }}>
                                        {item}
                                    </p>
                                    {self.props.editMode ? (
                                        <>
                                            {i > 0 ?
                                                <FontAwesomeIcon
                                                    size="sm"
                                                    className="fa-left-marging-5px"
                                                    icon={faChevronUp}
                                                    onClick={() => {
                                                        self.swapItems(i, -1)
                                                    }}
                                                /> : <></>}
                                            {i < self.props.items.length - 1 ?
                                                <FontAwesomeIcon
                                                    size="sm"
                                                    className="fa-left-marging-5px"
                                                    icon={faChevronDown}
                                                    onClick={() => {
                                                        self.swapItems(i, +1)
                                                    }}
                                                /> : <></>}
                                            <FontAwesomeIcon
                                                size="sm"
                                                className="fa-left-marging-5px"
                                                icon={faTrashAlt}
                                                onClick={() => {
                                                    self.removeItem(i)
                                                }}
                                            /></>
                                    ) : (
                                        <></>
                                    )}
                                </li>
                        )
                    })}

                    {self.props.editMode ?
                        <div>  <Row>
                            <Col md="6"><Form.Control
                                name="newItwm"
                                value={this.state.newItem}
                                autoFocus={this.state.elementFocus === "newItem"}
                                onChange={this.changeNewItem}
                                onBlur={() => this.setState({ elementFocus: null, newItem: "" })}
                                onKeyDown={self.onEnterClickNewItem.bind(this)}
                                onClick={() => this.setState({ elementFocus: "newItem" })}
                                type="text"
                                placeholder="Voeg extra items toe" /> </Col></Row>
                        </div> :
                        <></>
                    }

                </ol>
                </div>
            </div>
        )
    }
}
